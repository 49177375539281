import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getItem, setToken } from "../../shared/local-storage-handler/local-storage-handler";
import { setSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";
import jwt from 'jwt-decode'
import { useASContext } from "../context-api/as-context";
import { CircularProgress, Grid, Typography } from '@mui/material';
import { isNullOrEmpty } from "../../services/data-source-validation";


const ExternalSignIn = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const token = decodeURIComponent(urlParams.get('token') || "");
    const refresh_token = decodeURIComponent(urlParams.get('refresh_token') || "");
    const isSignUp = decodeURIComponent(urlParams.get('isSignUp') || "");
    const [loading, setLoading] = useState(true);
    const asContext = useASContext();

    useEffect(() => {
        if (token && refresh_token) {
            setSignInData(token, refresh_token);
        }
    }, [token]);

    const setSignInData = (token, refresh_token) => {
        setLoading(true);
        setToken("token", token);
        setToken("refresh_token", refresh_token);

        let decodedToken = jwt(token);
        if (decodedToken?.ASTenantUId) {
            setToken("ASTenantId", decodedToken?.ASTenantUId)
            setSessionStorageItem("ASTenantId", decodedToken?.ASTenantUId)
        }
        const inviteHash = getItem("invite-hash");
        if(!isNullOrEmpty(inviteHash)){
            navigate(`/acceptinvitation?inviteHash=${inviteHash?.toString()}`);
        }
        else {
            if (isSignUp && isSignUp?.toLowerCase() == "true") {
                navigate("/checkout");
            }
            else {
                navigate("/");
            }
        }
        setLoading(false);
    };

    return (
        <>
            {loading && (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Grid item>
                        <CircularProgress size={100} sx={{ color: '#16365F', marginLeft: '50px' }}/>
                        <Typography variant="h6" color="textSecondary" align="center">
                            Loading, please wait ...
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ExternalSignIn;
