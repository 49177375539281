import React, { useEffect, useState } from 'react'
import SelectCollections from '../../../../components/data-collections/merge-collection/select-collections';
import { useParams } from 'react-router-dom';
import * as collectionService from '../../../../services/data-collection.service';

const SelectCollectionController = (props) => {

    const { prevStepRef, setState, setMergeCollectionState, state, mergeCollectionState,
        collectionType, getCollectionDetails, handleCollectionTypeChange, collectionTypeTemplate } = props;

    const [dataCollections, setDataCollections] = useState([]);
    const [mergeDataCollections, setMergeDataCollections] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const { collectionUId } = useParams();    

    useEffect(() => {
        getUserDataCollection();
        getUserDataCollection(true);
    }, [])

    useEffect(() => {
        if (!state.collectionUIdsToMerge?.length || !collectionUId) return;
      
        setMergeCollectionState(prev => {
          const existingKeys = new Set(prev.selectedObjectCollections.map(item => item.key));
          
          const newCollections = state.collectionUIdsToMerge
            .map(uId => dataCollections.find(c => c.uId === uId))
            .filter(Boolean)
            .filter(c => !existingKeys.has(c.uId))
            .map(c => ({ key: c.uId, label: c.collectionName }));
      
          return {
            ...prev,
            selectedObjectCollections: [...prev.selectedObjectCollections, ...newCollections]
          };
        });
    }, [dataCollections, state.collectionUIdsToMerge, collectionUId]);
    
    const getUserDataCollection = (templateCollection = false) => {
    
        setIsDataLoading(true);

        collectionService.getAllCollections(templateCollection)
            .then((response) => {
                if (response?.hasError) {
                    setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });
                }
                else if (response?.data) {
                    if(templateCollection){
                        setMergeDataCollections(response?.data);
                    }
                else{
                    setDataCollections(response?.data);
                }
            }
        }).finally(() => {
            setIsDataLoading(false);
        })
    }
    const onChangeObjectSelectHandler = (selectedCollections) => {
        if(collectionTypeTemplate){
            setState((prevState) => { return { ...prevState, childCollectionUId: selectedCollections, } });
            getCollectionDetails(selectedCollections);
        }
        else{
            let collectionUIds = selectedCollections.map(c => c.key);
            setState((prevState) => { return { ...prevState, collectionUIdsToMerge: collectionUIds, } });
            setMergeCollectionState((prevState) => { return { ...prevState, selectedObjectCollections: selectedCollections, } });
        }
    }    

    const onChangeCollectionForTemplate = (selectedCollections, item) =>{
        if(selectedCollections !== undefined){
            setState((prevState) => {
                const updatedCollections = prevState?.collectionsNameToMerge?.map((collection) => 
                    collection?.name === item?.name 
                        ? { ...collection, uId: selectedCollections }
                        : collection
                );
                return {
                    ...prevState,
                    collectionsNameToMerge: updatedCollections,
                };
            });
        }       
    }    

    return (
        <SelectCollections
            prevStepRef={prevStepRef}
            dataCollections={dataCollections}
            mergeDataCollections={mergeDataCollections}
            collectionsCount={state?.collectionsNameToMerge}
            onChangeObjectSelectHandler={onChangeObjectSelectHandler}
            selectedObjectCollections={mergeCollectionState?.selectedObjectCollections}
            childCollectionUId={state?.childCollectionUId}
            isDataLoading={isDataLoading}
            collectionTypeTemplate={collectionTypeTemplate}
            onChangeCollectionForTemplate={onChangeCollectionForTemplate}
            collectionType={collectionType}
            handleCollectionTypeChange={handleCollectionTypeChange}
        />
    )
}

export default SelectCollectionController;