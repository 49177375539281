import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, CircularProgress, Paper, Box, ButtonGroup, DialogTitle, IconButton } from '@mui/material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { ASButton } from '../../../shared/as-button/as-button';
import ReactJson from 'react-json-view';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './prompt-popup.scss';
import { toast } from 'react-toastify';

const PromptPopup = ({ open, handleClose, gptPrompt, setPrompt, handleGenerateMergeScript, isResponseGettingGenerated, selectedCollectionsData, gptResponse, handleApplyMergeScript,
  activeTab, setActiveTab, handleGptResponse, handleCopyPrompt }) => {

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (!gptResponse) {
      setActiveTab(3);
    }
  }, [gptResponse]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '80vh',
          minWidth: '80vw',
          backgroundColor: 'white',
          padding: '20px',
        }
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <img src={`/assets/magic-icon.png`} />
        Generate Merge Script using GEN AI
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <div className="prompt-popup">
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <ButtonGroup orientation="horizontal">
              <Button
                className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabClick(1)}
              >
                Collection Properties
              </Button>
              {/* <Button 
                className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
                onClick={() => handleTabClick(2)}
              >
                Sample Data
              </Button> */}
              <Button
                className={`tab-button ${activeTab === 3 ? 'active' : ''}`}
                onClick={() => handleTabClick(3)}
              >
                Enter Prompt
              </Button>

              {(gptResponse || isResponseGettingGenerated) && <Button
                className={`tab-button ${activeTab === 4 ? 'active' : ''}`}
                onClick={() => handleTabClick(4)}
              >
                Generated Script
              </Button>}
            </ButtonGroup>
          </Box>
        </div>

        <Paper
          sx={{
            height: '64.1vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div style={{ flex: 1, overflowY: 'auto' }}>
            {/* ReactJson view */}
            {activeTab === 1 && (
              <ReactJson
                src={selectedCollectionsData}
                theme="monokai"
                name={false}
                style={{
                  height: "100%",
                  width: "auto",
                  overflowY: "auto",
                  overflowX: "auto"
                }}
                displayDataTypes={false}
              />
            )}

            {/* TextField for Enter Prompt */}
            {activeTab === 3 && (
              <TextField
                fullWidth
                multiline
                rows={18}
                label="Describe Your Merge Requirement"
                variant="outlined"
                value={gptPrompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="custom-textfield"
                InputLabelProps={{
                  sx: {
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -4px) scale(0.75) !important',
                    },
                  },
                }}
                sx={{
                  height: "100%",
                  overflowY: 'auto',
                  resize: 'vertical',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Removes the border
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      border: 'none', // Ensures no border appears on focus
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#757575',
                  },
                }}
              />
            )}

            {activeTab === 4 && (
              <div className="response-container" style={{ height: "100%" }}>
                {isResponseGettingGenerated ? (
                  <div className="loader-animation">Generating...</div>
                ) : gptResponse && (
                  <TextField
                    fullWidth
                    multiline
                    rows={18}
                    label="Generated Script"
                    variant="outlined"
                    value={gptResponse}
                    onChange={(e) => handleGptResponse(e.target.value)}
                    InputLabelProps={{
                      sx: {
                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(14px, -4px) scale(0.75) !important',
                        },
                      },
                    }}
                    sx={{
                      height: "100%",
                      overflowY: 'auto',
                      resize: 'vertical',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#757575',
                      },
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {(activeTab === 3 || (activeTab == 4 && gptResponse)) && (
            <IconButton
              onClick={handleCopyPrompt}
              sx={{
                position: 'absolute',
                bottom: 55,
                left: 40,
                color: '#757575',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' }
              }}
              size="small"
            >
              <ContentCopyIcon />
            </IconButton>
          )}
        </Paper>

      </DialogContent>

      <DialogActions>
        <ASButton
          variant="contained"
          disabled={false}
          // sx={{ mr: 4 }}
          primary={false}
          onClick={() => handleClose()}
        >
          Cancel
        </ASButton>
        {activeTab === 4 ? (
          <ASButton primary={true} variant="contained" onClick={handleApplyMergeScript} disabled={!gptResponse}>
            Apply Script
          </ASButton>
        ) : (
          <ASButton primary={true} variant="contained" onClick={handleGenerateMergeScript} disabled={!gptPrompt}>
            <img
              src={`/assets/gen-ai.svg`}
              style={{ marginRight: "8px" }}
              alt="Magic Icon"
            />
            Generate Script
          </ASButton>
        )}

      </DialogActions>
    </Dialog>
  );
};

export default PromptPopup;
