import { AppEnum } from "../constants/app-enum";
import { RestServiceConstants } from "../constants/rest-service-constant";
export const RestApiNavData = [
    {
        label: AppEnum.RestConnnectionTypes.No_Auth,
        for: "No_Auth",
        tabId: 0,
        key: null
    },
    {
        label: AppEnum.RestConnnectionTypes.API_Key,
        for: "API_Key",
        tabId: 1,
        key: RestServiceConstants.API_KEY
    },
    {
        label: AppEnum.RestConnnectionTypes.Bearer_Token,
        for: "Bearer_Token",
        tabId: 2,
        key: RestServiceConstants.BEARER
    },
    {
        label: AppEnum.RestConnnectionTypes.OAuth_2,
        for: "OAuth_2.0",
        tabId: 3,
        key: RestServiceConstants.OAUTH2
    },
    {
        label: AppEnum.RestConnnectionTypes.Basic_Auth,
        for: "Basic_Auth",
        tabId: 4,
        key: RestServiceConstants.BASIC_AUTH
    },
    // {
    //     label: "Cookie Auth",
    //     for: "Cookie_Auth",
    //     tabId: 5,
    //     key: RestServiceConstants.AWS_SIGN
    // },
    // {
    //     label: "AWS Signature",
    //     for: "AWS_Signature",
    //     tabId: 6,
    //     key: null
    // },
];

export const refineFormData = (formData, selectedStep, restOrCloudApplicationsId, isTally = false, isTestingData = false) => {
    const formDataC = { ...formData };

    delete formDataC.name;
    delete formDataC.uID;

    const formResponse = {
        name: formData?.name?.value,
        uId: formData?.uID && formData?.uID?.value,
        sysConnectionTypeId: selectedStep?.id, 
        isSaasAuthSource: false,
        description: formDataC?.description || "",
        baseConnectionTypeId : selectedStep?.baseConnectionTypeId ?? null,
        // datasourceParameters: []
        parameters: [],
        extraParameters: [],
        templateConnectionId : formData?.templateConnectionId?.value ?? null
    };

    let connectionTypeId = 0;
    if(restOrCloudApplicationsId.has(selectedStep?.id)){
        connectionTypeId = 99;
    }else{
        connectionTypeId = selectedStep?.id;
    }

    switch (connectionTypeId) {
        case 1:
            formResponse.parameters = msSql(formDataC);
            break;
        case 99:
            formResponse.parameters = restService(formDataC,isTally);
            break;
        case 6:
            formResponse.parameters = postgreSql(formDataC);
            break;
        case 4:
            formResponse.parameters = mySql(formDataC);
            break;
        default:
            formResponse.parameters = [];
    }

    return formResponse;
};

const _setDataSource = (formDataC, key) => ({
    // datasourceParameterTypeId: formDataC[key].datasourceParameterTypeId,
    // datasourceParameterValue: formDataC[key].value,
    // datasourceParameterName: key,
    configConnectionParameterTypeId: formDataC[key]?.datasourceParameterTypeId,
    parameterValue: formDataC[key]?.value,
    parameterName: key,
});

const msSql = (formDataC) => {

    const res = [];

    for (let key in formDataC) {
        res.push(_setDataSource(formDataC, key));
    }

    return res;

}

const restService = (formDataC,isTally) => {
    const res = [];
    const _formDataC = { ...formDataC };

    const authTYpe = RestApiNavData.find(ele => ele.label === _formDataC[RestServiceConstants.AUTH_TYPE].value)

    const parameters = _formDataC[RestServiceConstants.BEARER_TOKEN.PARAMETERS]?.value;   

    if (_formDataC[RestServiceConstants.BEARER_TOKEN.SEND_AS]?.value !== RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0]?.value){
        delete _formDataC[RestServiceConstants.BEARER_TOKEN.JSON_BODY];
        delete _formDataC[RestServiceConstants.BEARER_TOKEN.Body_Parameters];
        _formDataC[RestServiceConstants.BEARER_TOKEN.Form_Key_Values].value = parameters;
    }
    if (_formDataC[RestServiceConstants.BEARER_TOKEN.SEND_AS]?.value === RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0]?.value){
        delete _formDataC[RestServiceConstants.BEARER_TOKEN.Form_Key_Values];
        _formDataC[RestServiceConstants.BEARER_TOKEN.Body_Parameters].value = parameters;
    }

    if(authTYpe?.label !== AppEnum.RestConnnectionTypes.OAuth_2){
        res.push(_setDataSource(_formDataC, RestServiceConstants.API_ENDPOINT));    
    }

    if(authTYpe?.label == AppEnum.RestConnnectionTypes.No_Auth){
        res.push(_setDataSource(_formDataC, RestServiceConstants.AUTH_TYPE));
        return res;
    }

    if(authTYpe?.label == AppEnum.RestConnnectionTypes.Basic_Auth){
        res.push(_setDataSource(_formDataC, RestServiceConstants.REST_TALLY_CONNECTOR_URL));
    }

    if(isTally && authTYpe == null){
        res.push(_setDataSource(_formDataC, RestServiceConstants.REST_TALLY_CONNECTOR_URL));
    }

    if(authTYpe?.label == AppEnum.RestConnnectionTypes.Bearer_Token){                           
        res.push(_setDataSource(_formDataC, RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)); //changes for GST connection
    }


    res.push(_setDataSource(_formDataC, RestServiceConstants.AUTH_TYPE));
    res.push(_setDataSource(_formDataC, RestServiceConstants.JWT_JSON_PATH));
    res.push(_setDataSource(_formDataC, RestServiceConstants.REQUIRE_PROXY_CONNECTOR));
    res.push(_setDataSource(_formDataC, RestServiceConstants.LOGIN_URL));

   
    for (let key in _formDataC) {
        if (authTYpe != null && key.startsWith(authTYpe.key) || key.startsWith(RestServiceConstants.CLOUD)) // adding cloud input params for cloud connections
            res.push(_setDataSource(_formDataC, key));
    }

    return res;
}

const postgreSql = (formDataC) =>{
    const res = [];

    for(let key in formDataC){
        res.push(_setDataSource(formDataC,key));
    }

    return res;
}

const mySql = (formDataC) =>{
    const res = [];

    for(let key in formDataC){
        res.push(_setDataSource(formDataC,key));
    }

    return res;
}