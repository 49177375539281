import { FormControlLabel, MenuItem, Paper, Dialog, Backdrop, CircularProgress } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import Checkbox from '@mui/material/Checkbox';
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import "./basic-information.scss";
import { useParams } from "react-router-dom";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { ASButton } from "../../../shared/as-button/as-button";

const BasicInformationPopup = (props) => {


    const { connectionList, categoryList, childGroupName, groupName, name,
        connectionUId, categoryUId, isCollectionAsDataSource, basicInfoError } = props.state;

    const filterOptions = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.groupName, });

    const filterOptionsSubGroup = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.subGroupName, });

    return (
        <div ref={props.prevStepRef}>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={props?.isCloudLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog open={props.open} handleCloseDialog={props.handleCloseDialog} BackdropComponent={Backdrop}
                BackdropProps={{
                    style: { backdropFilter: "blur(3px)" },
                    onClick: props.handleCloseDialog,
                }}
                PaperProps={{ sx: { borderRadius: "10px", width: "520px",  overflow: "unset" } }}
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                <Paper
                    sx={{
                        minHeight: "100px",
                        width: "100%",
                        padding: "20px",
                    }}
                >
                    <div className="basic-info-header">Save Collection</div>

                    {
                        basicInfoError &&
                        <div className="collection-error-div">
                            <MessageToaster errorMessage={basicInfoError} style={{margin:"none important"}}/>
                        </div>
                    }

                    <div className="basic-info-container" style={{width:"100%"}}>

                        <ASTextField
                            className="text-field"
                            label="Data Collection Name"
                            variant="outlined"
                            onChange={props.onChangeDataCollectionNameHandler}
                            value={name}
                        />

                        <Stack spacing={2}>
                            <Autocomplete
                                id="groupname"
                                freeSolo
                                filterOptions={filterOptions}
                                className="text-field"
                                options={props.groups.map((option, index) => ({
                                    groupName: option.groupName,
                                    index: index
                                }))}
                                getOptionLabel={(option) => option['groupName'] || option}
                                renderOption={(props, option) => {
                                    return (
                                        <MenuItem
                                            {...props}
                                            key={`option ${option.index}`}
                                            className={
                                                groupName === option.groupName
                                                    ? "selected-opt"
                                                    : "autocomplete-option"
                                            }
                                        >
                                            {option.groupName}
                                        </MenuItem>
                                    );
                                }}
                                onChange={(event, group) => {
                                    props.onSelectGroupNameHandler(group);
                                }}
                                value={groupName}
                                // PopperComponent={StyledAutocompletePopper}
                                // noOptionsText={null}
                                renderInput={(params) => (
                                    <ASTextField
                                        {...params}
                                        onChange={(event) => {
                                            props.onChangeGroupNameHandler(event);
                                        }}
                                        label="Group Name"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: groupName && params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Stack spacing={2}>
                            <Autocomplete
                                id="subgroupname"
                                freeSolo
                                filterOptions={filterOptionsSubGroup}
                                className="text-field"
                                options={props.subGroups.map((option, index) => ({
                                    subGroupName: option.name,
                                    index: index
                                }))}
                                getOptionLabel={(option) => option['subGroupName'] === "" ? "No Options" : (option['subGroupName'] || option)}
                                renderOption={(props, option) => {
                                    return (
                                        <MenuItem
                                            {...props}
                                            key={`option ${option.index}`}
                                            className={
                                                childGroupName === option.name
                                                    ? "selected-opt"
                                                    : "autocomplete-option"
                                            }
                                        >
                                            {option.subGroupName}
                                        </MenuItem>
                                    );
                                }}
                                onChange={(event, subGroup) => {
                                    props.onSelectSubGroupNameHandler(subGroup);
                                }}
                                value={childGroupName}
                                renderInput={(params) => (
                                    <ASTextField
                                        {...params}
                                        onChange={(event) => {
                                            props.onChangeSubGroupNameHandler(event);
                                        }}
                                        label="Sub Group Name"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: childGroupName && params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                    </div>

                    {/* <FormControlLabel control={
                        <Checkbox
                            checked={isCollectionAsDataSource}
                            onChange={(event) => props.onCheckCollectionAsConnection(event)}
                            sx={muiCheckboxStyle}
                        />
                    }
                        className='collection-as-connection'
                        label="Use Collection as Dynamic List Source"
                    /> */}

                    <div className="button-flex">
                        <ASButton onClick={props.handleCloseDialog} primary={false}>
                            Cancel
                        </ASButton>
                        {(props.isBasicInfoSubmitted ? false : true) ? (
                            <ASButton variant="contained"  primary={false}>Save</ASButton>
                        ) : ((<ASButton variant="contained" onClick={() => props.saveCollection()} primary={true}>
                            {props.isUpdatingCollection ? "Update" : "Save"}
                        </ASButton>)
                        )}
                    </div>
                </Paper>
            </Dialog>
        </div >
    );
};

export default BasicInformationPopup;