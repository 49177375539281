import { Paper } from '@mui/material';
import React from 'react'
import "./misc.scss";

const ASPaper = (props) => {

    const { children, ref, isDataLoading, className } = props;


    return (
        <Paper
            sx={{
                width: "unset",
                padding: "30px 20px",
                position: 'relative'
            }}
            ref={ref}
            className={className}
        >
            {
                isDataLoading &&
                <div className="as-spinner paper-spinner"></div>
            }
            {children}
        </Paper>
    )
}

export default ASPaper