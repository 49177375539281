import {
    Button, ButtonGroup, Checkbox, Dialog, FormControl,
    FormControlLabel, FormLabel, Paper, Radio, RadioGroup
} from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import { ASButton } from "../../../shared/as-button/as-button";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import { ReQuartzCron, Tab } from '@sbzen/re-cron';
import "./refresh-schedule.scss";
import { ASSwitch } from "../../../shared/as-switch/as-switch";

const RefreshSchedulePopUp = (props) => {

    const { onFieldChange, state, isOpenDialog, handleClosePopup, addUpdateRefreshScheduleDetails, checkGoogleSheet,
        handleButtonOptionsClick, selectedScheduleOption, onChangeSyncingMethod, onToggleIsScheduleActive,
        onToggleIsSyncNotification, onScheduleTimingChange, isButtonDisabled, onTabChange } = props;

    return (
        <div ref={props.prevStepRef}>

            <Dialog open={isOpenDialog} onClose={handleClosePopup}
                BackdropProps={{
                    style: { backdropFilter: "blur(3px)" },
                    onClick: props.handleClosePopup,
                }}
                PaperProps={{ sx: { borderRadius: "10px", width: "700px", maxWidth: "700px", maxHeight: "500px", overflow: "unset" } }}
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
            >
                <Paper
                    sx={{
                        width: "100%",
                        maxHeight: "500px",
                        padding: "20px",
                    }}
                >
                    <div className="schedule-header">Set Refresh Schedule</div>

                    <div className='refresh-schedule-group-container'>
                        <ButtonGroup variant="outlined" aria-label="Basic button group">
                            <Button onClick={() => handleButtonOptionsClick(0)} className={selectedScheduleOption === 0 ? 'selected' : ''}>
                                Basic Configuration
                            </Button>
                            <Button onClick={() => handleButtonOptionsClick(1)} className={selectedScheduleOption === 1 ? 'selected' : ''}>
                                Schedule
                            </Button>
                        </ButtonGroup>
                    </div>

                    {selectedScheduleOption === 0 ?
                        <div className={!state?.isActive ? "disabled-div" : ""}>
                            <div className="file-url-div">
                                <ASTextField
                                    className="text-field file-url"
                                    label="File url"
                                    variant="outlined"
                                    onChange={(event) => onFieldChange("fileURL", event?.target?.value)}
                                    value={state?.fileURL}
                                />
                                <div className='check-connection-button'>
                                    <ASButton variant="contained"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            checkGoogleSheet()
                                        }}
                                        primary={true}
                                        size="small"
                                        disabled={!state?.fileURL}
                                        sx={{ padding: "6px 10px" }}>
                                        Check Connection
                                    </ASButton>
                                </div>
                            </div>

                            <ASTextField
                                className="text-field"
                                label="Sheet Name"
                                variant="outlined"
                                onChange={(event) => onFieldChange("sheetName", event?.target?.value)}
                                value={state?.sheetName}
                            />

                            <FormControl
                                className="syncing-radio-button"
                                sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label"
                                    sx={{ color: "inherit", "&.Mui-focused": { color: "inherit" } }} >Syncing Method</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={state?.syncingType}
                                    onChange={(event) => onChangeSyncingMethod(event)}
                                    sx={{ flexDirection: "row", alignItems: "center" }}
                                >
                                    <FormControlLabel value="append" control={<Radio sx={muiCheckboxStyle} />} label="Append" />
                                    <FormControlLabel value="update" control={<Radio sx={muiCheckboxStyle} />} label="Update" />
                                </RadioGroup>
                            </FormControl>

                            <div className="boolean-box">
                                <Checkbox className="send-sync-checkbox" checked={state?.isSyncNotification}
                                    onChange={(event) => onToggleIsSyncNotification(event)}
                                    sx={muiCheckboxStyle}
                                />
                                Send Sync Notification
                            </div>

                            {state?.isSyncNotification &&
                                <ASTextField
                                    className="text-field"
                                    label="Email"
                                    variant="outlined"
                                    onChange={(event) => onFieldChange("email", event?.target?.value)}
                                    value={state?.email}
                                />
                            }
                        </div>
                        :
                        <div className="schedule-timing">
                            <ReQuartzCron
                                disabled={!state?.isActive}
                                onTabChange={onTabChange}
                                tabs={[Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH]}
                                value={state?.scheduleTiming || "* * * * *"}
                                onChange={onScheduleTimingChange} />
                        </div>
                    }

                    <div className="buttons-flex">
                        <div className='schedule-active-button'>
                            <ASSwitch
                                checked={state?.isActive}
                                onChange={(event) => onToggleIsScheduleActive(event)}
                            />
                            <span className="switch-btn-text">Active</span>
                        </div>
                        <Button className="column-rename-cancel-button" onClick={handleClosePopup}>
                            Cancel
                        </Button>
                        <ASButton variant="contained"
                            primary={!isButtonDisabled}
                            onClick={isButtonDisabled ? () => { } : addUpdateRefreshScheduleDetails}
                        >
                            Save
                        </ASButton>
                    </div>
                </Paper>
            </Dialog>
        </div >
    );
};

export default RefreshSchedulePopUp;