import React, { useEffect, useMemo, useState } from "react";
import RefreshSchedulePopUp from "../../../components/data-collections/refresh-schedule/refresh-schedule";
import { RefreshScheduleModel } from "../data-editing-script-state";
import { toast } from "react-toastify";
import jwt from 'jwt-decode';
import { addUpdateRefreshSchedule, checkGoogleSheetFileExists, getRefreshScheduleDetails } from "../../../services/refresh-schedule-service";

export default function RefreshScheduleController(props) {
    const { collectionUId, isOpenDialog, setIsOpenDialog } = props;
    const [state, setState] = useState(new RefreshScheduleModel());
    const [selectedScheduleOption, setSelectedScheduleOption] = useState(0);
    const [googleSheetConnection, setGoogleSheetConnection] = useState(true);
    const [tabName, setTabName] = useState("");

    useEffect(() => {
        const fetchScheduleDetails = async () => {
            await getScheduleDetails();
        };
        fetchScheduleDetails();
    }, [collectionUId])

    useEffect(() => {
        document.querySelectorAll('.c-and-item').forEach(item => {
            const value = parseInt(item.getAttribute('item-value'), 10);
            if (value >= 0 && value <= 11) {
                item.classList.add('value-range-1-10');
            }
        });
    }, [selectedScheduleOption, tabName])

    const onTabChange = (tabName) => {
        setTabName(tabName);
    }

    const getScheduleDetails = async () => {
        try {
            const response = await getRefreshScheduleDetails(collectionUId);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else if (!response?.data) {
                setState(new RefreshScheduleModel());
            }
            else {
                const decodedData = decodeURIComponent(response?.data);
                const result = JSON.parse(decodedData);
                setState(result);
                setGoogleSheetConnection(false);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const addUpdateRefreshScheduleDetails = async () => {
        try {
            let refreshSchedule = JSON.stringify(state);
            refreshSchedule = encodeURIComponent(refreshSchedule);

            const response = await addUpdateRefreshSchedule(collectionUId, refreshSchedule);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {
                toast.success("Refresh Schedule updated successfully.");
                handleClosePopup();
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const handleButtonOptionsClick = (selectedStep) => {
        setSelectedScheduleOption(selectedStep);
    };

    const handleClosePopup = () => {
        setIsOpenDialog(false);
        setState(new RefreshScheduleModel);
        setSelectedScheduleOption(0);
    }

    const onFieldChange = (field, value) => {
        setState((prevState) => { return { ...prevState, [field]: value } });
    }

    const onToggleIsSyncNotification = (event) => {
        setState((prevState) => { return { ...prevState, isSyncNotification: event.target.checked } });
        if (event.target.checked && state?.email == "") {
            let token = localStorage.getItem("token");
            let decodedToken = jwt(token);
            setState((prevState) => { return { ...prevState, email: decodedToken?.username } });
        }
    }

    const onToggleIsScheduleActive = (event) => {
        setState((prevState) => { return { ...prevState, isActive: event.target.checked } });
    }

    const onChangeSyncingMethod = (event) => {
        setState((prevState) => { return { ...prevState, syncingType: event.target.value } });
    }

    const onScheduleTimingChange = (value) => {
        setState((prevState) => { return { ...prevState, scheduleTiming: value } });
    }

    const checkGoogleSheet = async () => {
        let fileURL = state?.fileURL;
        const match = fileURL?.match(/\/d\/([a-zA-Z0-9-_]+)/);
        if (!match) {
            toast.error("Invalid URL format");
        }
        try {
            const response = await checkGoogleSheetFileExists(fileURL);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {
                let data = JSON.parse(response?.data);
                if (data?.error) {
                    toast.error(data?.error?.message);
                }
                else {
                    setGoogleSheetConnection(false);
                    toast.success(`Google Sheet is valid and accessible! Title: ${data.properties.title}`);
                }
            }
        } catch (error) {
            toast.error(`Error checking Google Sheet: ${error.message}`);
        }
    };

    const isButtonDisabled = useMemo(() => {
        const isFileURL = !state?.fileURL?.trim();
        const isSheetName = !state?.sheetName?.trim();
        const isSyncingType = !state?.syncingType?.trim();
        const isEmail = state?.isSyncNotification ? !state?.email?.trim() : false;
        const isScheduleTiming = !state?.scheduleTiming?.trim();

        return isFileURL || isSheetName || isSyncingType || isScheduleTiming || isEmail || googleSheetConnection;
    }, [googleSheetConnection, state?.fileURL, state?.sheetName, state?.syncingType, state?.email, state?.scheduleTiming, state?.isSyncNotification]);


    return (
        <>
            {state &&
                <RefreshSchedulePopUp
                    state={state}
                    isOpenDialog={isOpenDialog}
                    isButtonDisabled={isButtonDisabled}
                    onToggleIsScheduleActive={onToggleIsScheduleActive}
                    handleClosePopup={handleClosePopup}
                    onFieldChange={onFieldChange}
                    onTabChange={onTabChange}
                    checkGoogleSheet={checkGoogleSheet}
                    onScheduleTimingChange={onScheduleTimingChange}
                    handleButtonOptionsClick={handleButtonOptionsClick}
                    onToggleIsSyncNotification={onToggleIsSyncNotification}
                    onChangeSyncingMethod={onChangeSyncingMethod}
                    addUpdateRefreshScheduleDetails={addUpdateRefreshScheduleDetails}
                    selectedScheduleOption={selectedScheduleOption} />
            }
        </>
    )
}
