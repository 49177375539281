import { Menu, MenuItem } from "@mui/material";
import React, { Fragment } from "react";
import { useASContext } from "../../controllers/context-api/as-context";
import './tenants-menu.scss';
import AddNewOrganization from "./add-new-org/add-new-org";
import jwtDecode from "jwt-decode";

const TenantsMenu = (props) => {
    const asContext = useASContext();

    const { isOwner, tenants, isMenuOpen, closeTenantMenu,
        tenantAnchorEl,isIndividualUser,setSavedOrganization, refresh_token } = props;
    
    let accessToken = localStorage.getItem('token');
    const decodedToken = jwtDecode(accessToken);
    const ownerTenantUId = decodedToken?.ASTenantUId;
    const isAgencyClient = ((asContext?.tenants?.selectedTenant?.AgencyUId) && (asContext?.tenants?.selectedTenant?.ASTenantId != ownerTenantUId));
    

    return (
        <Fragment>

            <Menu
                open={isMenuOpen}
                anchorEl={tenantAnchorEl}
                className="tenants-menu"
                area-aria-haspopup='false'
                onMouseLeave={closeTenantMenu}
                keepMounted={false}
                onClose={closeTenantMenu}
                MenuListProps={{
                    onMouseLeave: closeTenantMenu,
                }}
            >
                {
                    tenants?.map((tenant, index) =>

                        <MenuItem onClick={() => props.selectTenant(tenant)}
                            className={tenant?.ASTenantId === asContext?.tenants?.selectedTenant?.ASTenantId
                                ? 'selected-tenant' : ''} key={index}
                        >
                            {tenant?.Name}
                        </MenuItem>

                    )
                    
                    }

                { (props.remainingTenantFeatureBalance > 0 && !isIndividualUser && isOwner && !isAgencyClient) ? (
                  <MenuItem onClick={(event) => props.routeToAddNewOrganization(event)}>
                      Add New Organization
                  </MenuItem>
                ):(
                    null
                )}
            </Menu>

            <AddNewOrganization open={props.isDialogOpen} onClose={props.handleCloseDialog} setSavedOrganization={setSavedOrganization} refresh_token={refresh_token}/>

        </Fragment>
    )
}

export default TenantsMenu;