import { axios } from "../core/axios.interceptor";

const baseURL = `data-script`;

export async function getAllScript(getByTenant = false) {
    const response = await axios.get(`${baseURL}?getByTenant=${getByTenant}`);
    return response;
}

export async function getScriptByUId(uId) {
    const response = await axios.get(`${baseURL}/${uId}`);
    return response;
}

export async function getSysConfig(name) {
    const response = await axios.get(`${baseURL}/${name}`);
    return response;
}

export async function addDataScript(model) {
    const response = await axios.post(`${baseURL}`, model);
    return response;
}

export async function updateDataScript(uId, model) {
    const response = await axios.put(`${baseURL}/${uId}`, model);
    return response;
}

export async function deleteDataScript(uId) {
    const response = await axios.delete(`${baseURL}/${uId}`);
    return response;
}