import { Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AppEnum } from "../../../constants/app-enum";
import { ASButton } from "../../../shared/as-button/as-button";
import './data-script.scss'
import styles from "../../../styles/_exports.module.scss";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";

const DataScriptComponent = (props) => {
    const { isScriptMenuOpen, selectedScriptIndex, scriptEnchorEl, dataScriptsAsSource, dataScriptHeader } = props.state;
    const navigate = useNavigate();

    return (
        <>
            <div className="collections-header-div">

                <div className="data-name-div">
                    <div>
                        <span>Total <span>{dataScriptsAsSource?.length}</span></span>
                        <label>Data Scripts</label>
                    </div>
                </div>

                <div className="search-btn-div">

                    <div className="add-new-btn-data">

                        <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                            onClick={() => { navigate("/data-script/add-new-data-script") }}
                            rounded={true} primary={true}
                            variant="contained"
                        >
                            <i className="plus-svg">+</i> ADD NEW
                        </ASButton>

                    </div>

                </div>

            </div>
            <div className="script-paper-group">
                <Paper className="script-paper" sx={{ height: "75vh" }}>
                    <div>
                        <TableContainer className="scriptTableHeight">

                            <Table
                                aria-labelledby="tableTitle"
                                size='medium'
                                className="script-table"
                            >
                                <TableHead>

                                    <TableRow sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white"}}>
                                        {
                                            dataScriptHeader?.map((dataHead, index) => {

                                                return (
                                                    <TableCell
                                                        key={`data-coll-header ${index}`}
                                                        align='left'
                                                        sx={{ color: styles.secondaryColor, fontWeight: styles.semiBold }}
                                                        scope="row"
                                                    >
                                                        {dataHead?.headerTitle}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {
                                        (dataScriptsAsSource)?.map((data, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => props?.onScriptEdit(null, data)}
                                                    key={`filtered-coll ${index}`}
                                                    className='collection-row'
                                                >

                                                    <TableCell
                                                        align="left"
                                                        sx={{ color: styles.mutedColor }}
                                                    >
                                                        <div className="flex-div-cell">
                                                            {data?.scriptTypeCD === AppEnum.DataScriptApplicable.RESPONSE_STYLING ?
                                                                <img alt="type-icon" src={`./assets/response-transformation.svg`} /> :
                                                                <img alt="type-icon" src={`./assets/response-styling.svg`} />
                                                            }
                                                            {data?.name}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{ color: styles.mutedColor }}
                                                    >
                                                        {data?.scriptTypeCD}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{ color: styles.mutedColor }}
                                                    >
                                                        {data?.applicableToCD}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{ color: styles.mutedColor }}
                                                    >
                                                        {data?.collections}
                                                    </TableCell>

                                                    <TableCell align="left" sx={{ color: styles.mutedColor }}>
                                                        <MoreVertIcon
                                                            id={`${String(index)}+vertIcon`}
                                                            aria-controls={isScriptMenuOpen ? `${String(index)}+script` : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={isScriptMenuOpen ? 'true' : undefined}
                                                            onClick={(event) => props.openScriptMenuOption(event, true, index)}
                                                            className='edit-user-icon'
                                                        >
                                                            Dashboard
                                                        </MoreVertIcon>

                                                        <Menu
                                                            id={`${String(index)}+menu`}
                                                            aria-labelledby={index}
                                                            anchorEl={selectedScriptIndex === index ? scriptEnchorEl : null}
                                                            open={selectedScriptIndex === index ? isScriptMenuOpen : false}
                                                            onClose={(event) => props.closeScriptMenuOption(event)}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                            className='edit-user-menu'
                                                        >
                                                            <MenuItem onClick={(event) => { props?.onScriptEdit(event, data) }}>Edit Script </MenuItem>
                                                            <MenuItem onClick={(event) => { props?.onScriptDelete(event, data) }}>Delete Script </MenuItem>
                                                        </Menu>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Paper>
            </div>
        </>
    )
}
export default DataScriptComponent;