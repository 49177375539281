import { useState, useEffect } from "react";
import { DataEditingScriptsState } from "../data-editing-script-state";
import DataScriptComponent from "../../../components/data-collections/data-script-component/data-script";
import { toast } from "react-toastify";
import { deleteDataScript, getAllScript } from "../../../services/data-script.service";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { useNavigate } from "react-router-dom";

const DataScriptController = (props) => {
    const [state, setState] = useState(new DataEditingScriptsState());
    const navigate = useNavigate();

    useEffect(async () => {
        await getAllScripts();
    }, [])

    const getAllScripts = async () => {
        try {
            const response = await getAllScript();
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                setState((prevState) => { return { ...prevState, dataScriptsAsSource: response?.data } });
            }
        } catch (error) {

        }
    }

    const openScriptMenuOption = (event, isOpen, index) => {

        resetErrorMessages();
        if (event)
            event.stopPropagation();

        setState((prevState) => {
            return {
                ...prevState, isScriptMenuOpen: isOpen,
                scriptEnchorEl: event.target,
                selectedScriptIndex: index
            }
        });
    }

    const closeScriptMenuOption = (event) => {

        resetErrorMessages();
        if (event)
            event.stopPropagation();

        setState((prevState) => {
            return {
                ...prevState, isScriptMenuOpen: false,
                scriptEnchorEl: null,
                selectedScriptIndex: null
            }
        });
    }

    const handlePopUpState = () => {
        setState((prevState) => {
            return {
                ...prevState, isShowPopUp: false,
                scriptUId: ""
            }
        });
    }

    const resetErrorMessages = () => {
        if (state.errorMessage)
            setState((prevState) => { return { ...prevState, errorMessage: null, } });
    }

    const closeDialog = () => {
        resetErrorMessages();
        setState((prevState) => {
            return {
                ...prevState, isShowPopUp: false
            }
        });
        closeScriptMenuOption();
    }

    const onScriptEdit = (event, script) => {
        if (event)
            event.stopPropagation();

        navigate(`/data-script/update-data-script/${script.uId}`, {
            state: script
        })
    }   

    const onScriptDelete = async (event, script) => {
        resetErrorMessages();
        if (event)
            event?.stopPropagation();
        closeScriptMenuOption();

        if (script) {
            setState((prevState) => {
                return {
                    ...prevState, isShowPopUp: true,
                    scriptUId: script?.uId
                }
            });
        }

        if (state?.isShowPopUp) {
            handlePopUpState();
            const response = await deleteDataScript(state?.scriptUId);
            if (!response?.hasError) {
                let dataScriptsAsSource = state?.dataScriptsAsSource;
                dataScriptsAsSource.splice(dataScriptsAsSource.findIndex(a => a?.uId === state?.scriptUId), 1);

                setState((prevState) => {
                    return {
                        ...prevState, dataScriptsAsSource: dataScriptsAsSource,
                    }
                });
                toast.success("Data Script deleted successfully.");
            }
            else {
                setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });
                toast.error(response?.errorMessage);
            }
        }
    }

    return (
        <>
            <MuiDialog
                isShowPopUp={state?.isShowPopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={onScriptDelete}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="Are you sure you want to delete this Data Script?"
            />
            <DataScriptComponent
                state={state}
                setState={setState}
                closeScriptMenuOption={closeScriptMenuOption}
                openScriptMenuOption={openScriptMenuOption}
                onScriptDelete={onScriptDelete}
                onScriptEdit={onScriptEdit}
            />
        </>
    )
}
export default DataScriptController;