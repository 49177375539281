import { CircularProgress } from "@mui/material";
import "./test-collection-popup.scss";
import { useEffect, useMemo, useRef } from "react";
import { ASButton } from "../../../shared/as-button/as-button";

const MergeCollectionPopup = ({isVisible, messages, onCancel, handleMergePopupClose}) => {
   
    const messageListRef = useRef(null);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    const isCancelDisabled = useMemo(() => {
        return messages?.some((msg) => msg.includes("Starting the merge query execution..."));
    }, [messages]);

    const isErrorMessage = useMemo(() => {
        return messages?.some((msg) => msg.includes("Some error occurred while executing merge script"));
    }, [messages]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                {isErrorMessage && (
                    <div className="popup-close-container">
                        <div
                            className="close-popup-icon"
                            onClick={handleMergePopupClose}
                        >
                            <img
                                src="/assets/close.svg"
                                alt="Close"
                                className="close-icon"
                            />
                        </div>
                    </div>
                )}

                <div className="popup-message-list" ref={messageListRef}>
                    {messages?.map((message, index) => {
                        return (
                            <p 
                                key={index}
                                style={isErrorMessage ? index !== 0 ? { color: "red"} : {fontWeight: "bold"}: {}}
                            >
                                {message}
                            </p>
                        )
                    })}
                </div>

                {!isErrorMessage && (
                    <div className="popup-footer">
                        <CircularProgress size={40} color="primary" style={{ marginRight: 'auto' }}/>
                        <ASButton
                            variant="contained"
                            primary={true}
                            onClick={onCancel}
                            disabled={isCancelDisabled}
                        >
                            CANCEL PROCESS
                        </ASButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MergeCollectionPopup;