
import { FormControlLabel, MenuItem, Paper } from "@mui/material";
import './collection-type-and-object.scss'
import { ASTextField } from "../../../shared/as-text-fields";
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import { useParams } from "react-router-dom";
import { ASRadioGroup } from "../../../shared/as-radio-group";
import ExpandableView from "../../common/expandableView/expandableView";
import Checkbox from '@mui/material/Checkbox';
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import { InputConstants } from "../../../constants/input-constant";
import { AppEnum } from "../../../constants/app-enum";
import { useSelector } from "react-redux";
import ASSqlQueryEditor from "../../../shared/as-inputs/as-sql-editor";
import { useMemo } from "react";



const CollectionTypeAndObject = (props) => {

  const { collectionUId: collectionUId } = useParams();
  const { collectionTypeList, sysCollectionTypeId, sourceName, uiLabel, sourceNamePart4, sysCollectionSubTypeId, excludedProperties, exexcludedPropertiesDisplay } = props.state;
  const { sourceNameLabel, isRest, handleOnChangeSqlEditor, isCloudConnection, isTemplateCompany } = props;
  const isExpandedView = useSelector((state) => state.asState.isExpandedView);

  var formattedExcludedProperties = useMemo(() => {
    return excludedProperties?.replace(/,/g, ", ");
  }, [excludedProperties]);

  const collectionTypes = useMemo(() => {
    return collectionTypeList.filter(li => li.id !== AppEnum.SysCollectionTypeId.REST)
  }, [collectionTypeList])


  return (
    <Paper
      sx={{
        minHeight: "100px",
        width: "750px",
        padding: "20px",
        paddingBottom: "0px"
      }}
      ref={props.prevStepRef}
    >
      <ExpandableView>
        <div className="collection-type-container">
          {(collectionTypeList?.length > 0 && isRest) ? (
            <div>
              {
                isTemplateCompany && (
                  <div>
                    <ASTextField
                      className="text-field"
                      type={InputConstants.text}
                      fullWidth
                      label="Select a Collection Sub Type"
                      variant="outlined"
                      value={sysCollectionSubTypeId}
                      onChange={(event) => props?.onChangeCollectionSubTypeHandler(event?.target?.value)}
                      select={true}
                      SelectProps={{
                        MenuProps: {
                          className: "menu-role-div",
                          sx: selectedMenuItemBackgroundColor,
                        },
                      }}
                    >
                      {collectionTypes.map((list, index) => {
                        return (
                          <MenuItem value={list?.id} key={`collectionsubType ${index}`} >
                            {list.typeName}
                          </MenuItem>
                        );
                      })}
                    </ASTextField>
                  </div>
                )
              }
              <div>
                REST Data Url
              </div>
            </div>
          ) : (
            <ASTextField
              className="role-text-field"
              label="Select a Collection Type"
              key="Select a Collection Type"
              variant="outlined"
              SelectProps={{
                MenuProps: {
                  className: "basic-info-select",
                  sx: selectedMenuItemBackgroundColor,
                },
              }}
              select={true}
              onChange={(event) =>
                props.onSelectCollectionTypeHandler(event.target.value)
              }
              value={sysCollectionTypeId}
              disabled={collectionUId ? true : false}
              style={{ width: "50%" }}
            >
              {collectionTypeList?.map((collection) => (
                <MenuItem
                  key={collection.id}
                  value={collection.id}
                  name={collection?.typeName}
                >
                  {collection?.typeDesc}
                </MenuItem>
              ))}
            </ASTextField>
          )}

          {isRest && !isCloudConnection ? (
            <div className="collection_label_div">
              <label className="collection_label">{sourceNameLabel}</label>
              <div className="collection_label_div_sec">
                <ASTextField
                  className="text-field"
                  label={uiLabel}
                  variant="outlined"
                  onChange={props.onChangeCollectionObjectHandler}
                  value={sourceName}
                />
              </div>
            </div>
          ) : !isCloudConnection ? (sysCollectionTypeId === 3 ? (

            <ASSqlQueryEditor
              isExpandedView={isExpandedView}
              onChange={handleOnChangeSqlEditor}
              value={sourceName}
            />
          ) : (
            <ASTextField
              className="text-field"
              label={uiLabel}
              variant="outlined"
              onChange={props.onChangeCollectionObjectHandler}
              value={sourceName}
            />
          )) : ""}

          {(isRest && !isCloudConnection) && (
            <div>
              <ASRadioGroup
                radioInputs={props.radioButtons}
                onChange={(_e) => props.onChangeRestRequestHandler(_e)}
              />
              <ASTextField
                className="text-field"
                label="Data Json Path"
                variant="outlined"
                onChange={props.onChangeCollectionDataPathHandler}
                value={sourceNamePart4}
              />
              <FormControlLabel control={
                <Checkbox checked={props?.state?.returnRawData}
                  onChange={(event) => props.onToggleDataFormat(event)}
                  sx={muiCheckboxStyle}
                />
              }
                label="Return Raw Data"
              />
              <FormControlLabel control={
                <Checkbox checked={props?.state?.flattenLikeSql}
                  onChange={(event) => props.onToggleFlattenLikeSql(event)}
                  sx={muiCheckboxStyle}
                />
              }
                label="Flatten Data Like SQL"
              />
              {props?.state?.flattenLikeSql && 
                  <ASTextField
                  className="text-field"
                  label="Excluded Properties for Flatten Data"
                  variant="outlined"
                  onChange={props.onHandlingExcludedPropertiesForFlattening}
                  value={exexcludedPropertiesDisplay ?? formattedExcludedProperties}
                />
              }
            </div>
          )}
        </div>
      </ExpandableView>
    </Paper>
  );
}

export default CollectionTypeAndObject;
