import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSessionStorageItem } from '../../shared/session-storage-handler/session-storage-handler';

export default function OpenDirect() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        storeQueryDetails(urlParams);
    }, [])

    async function sleep(msec) {
        return new Promise(resolve => setTimeout(resolve, msec));
    }

    const storeQueryDetails = async (urlParams) => {
        const messageId = Date.now();    
        const accessToken = urlParams.get('AccessToken');
        const refreshToken = urlParams.get('RefreshToken');
        const aSTenantUId = urlParams.get('ASTenantUId');
        let redirectUrl = urlParams.get('redirectUrl');

        //for excel addinn
        const isMergeCollectionFromExcel = urlParams.get('isMergeCollectionFromExcel');
        const isGoogleDriveFromExcel = urlParams.get('isGoogleDriveFromExcel');

        if (accessToken && refreshToken) {

            localStorage.setItem("refresh_token", refreshToken);
            localStorage.setItem("token", accessToken);
            localStorage.setItem("ASTenantId", aSTenantUId);

            setSessionStorageItem("ASTenantId", aSTenantUId);

            urlParams.delete('AccessToken');
            urlParams.delete('RefreshToken');
            urlParams.delete('ASTenantUId');
        } else {
            const messageId = Date.now();
            window.chrome?.webview?.postMessage(`get_identity-messaged-${messageId}`);
        }

        if ((isMergeCollectionFromExcel?.toLowerCase() == "true" || isGoogleDriveFromExcel?.toLowerCase() == "true" ) && urlParams.toString()) {
            redirectUrl += redirectUrl.includes('?') ? '&' : '?';
            redirectUrl += urlParams.toString();
        }

        let checkCount = 0;
        while (checkCount <= 15) {
            const messageFromPlugin = sessionStorage.getItem("messageId");
            if (messageFromPlugin && messageFromPlugin == messageId.toString()) {
                //waiting for token in index.html page to be recevied and set in storage and then navigate
                navigate(redirectUrl, { replace: true });
                break;
            } else {
                await sleep(500);             
            }

            checkCount++;
        }
    }
    return null;
};
