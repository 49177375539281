import React, { useEffect, useState } from 'react'
import MergeQuery from '../../../../components/data-collections/merge-collection/merge-query';
import { AppEnum } from '../../../../constants/app-enum';
import { getAllScript } from '../../../../services/data-script.service';
import * as collectionService from '../../../../services/data-collection.service';
import _ from 'lodash';

const MergeQueryController = (props) => {

    const { setState, prevStepRef, state, showSaveChangesButton, preDefinedScripts, setPreDefinedScripts } = props;
    const [selectedScriptType, setSelectedScriptType] = useState(AppEnum.MergeCollection.SQL);
    const [savedScripts, setSavedScripts] = useState({
        sqlScript: state.restBody,
        mergeScript: state.mergeScript,
        dataTransformationScriptId : state.dataTransformationScriptId,
    });
    const [language, setLanguage] = useState("sql"); 
    const [selectedCollectionsData, setSelectedCollectionsData] = useState([]);

    useEffect(() => {
        if (state.restBody) {
            setSelectedScriptType(AppEnum.MergeCollection.SQL);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.SQL}})
            setLanguage(AppEnum.MergeCollection.SQL);
        } else if (state.mergeScript) {
            setSelectedScriptType(AppEnum.MergeCollection.Custom_Script);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Custom_Script}})
            setLanguage(AppEnum.MergeCollection.JavaScript)
        } else if (state.dataTransformationScriptId) {
            setSelectedScriptType(AppEnum.MergeCollection.Predefined_Script);
            setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Predefined_Script}})
        }
    }, []); 

    useEffect(() => {
        setState((prevState) => { 
            if (selectedScriptType === AppEnum.MergeCollection.SQL) {
                return {
                    ...prevState,
                    mergeScript: null,
                    restBody: savedScripts.sqlScript,
                    dataTransformationScriptId : null,
                }
            }
            else if (selectedScriptType === AppEnum.MergeCollection.Custom_Script) {
                return {
                    ...prevState,
                    mergeScript: savedScripts.mergeScript || AppEnum.MergeCollection.Merge_Script_Default_Value,
                    restBody: "",
                    dataTransformationScriptId : null,
                }
            } else {
                return {
                    ...prevState,
                    mergeScript: null,
                    restBody: "",
                    dataTransformationScriptId: savedScripts.dataTransformationScriptId,
                }
            }
        });
    }, [selectedScriptType]);

    useEffect(async () => {
        if(state.mergeScript == null){
            setState((prevState) => { return { ...prevState, mergeScript :  AppEnum.MergeCollection.Merge_Script_Default_Value } });
        }
        const predDeifnedScriptsData = await getAllScript(true);
        if(predDeifnedScriptsData?.data) {
            setPreDefinedScripts(predDeifnedScriptsData.data);
        }
    },[]);

    useEffect(() => {
        getColumnSchema();
    }, [state?.collectionUIdsToMerge]);

    const handleMergeEditor = (e) => {
        setState((prevState) => { return { ...prevState, mergeScript : e } });
        showSaveChangesButton(false);
        setSavedScripts({
            ...savedScripts,
            mergeScript: e,
        })
    }

    const onChangeSqlEditorHandler = (sqlQuery) => {
        setState((prevState) => { return { ...prevState, restBody: sqlQuery } });
        setSavedScripts({
            ...savedScripts,
            sqlScript: sqlQuery
        })
    }

    const handleRadioChange = (event) => {
        setSelectedScriptType(event.target.value);
        setState((prevState) => { return {...prevState, selectedScriptType: event.target.value}})
        if (event.target.value == AppEnum.MergeCollection.SQL) {
            setLanguage(AppEnum.MergeCollection.SQL);
        } else if (event.target.value == AppEnum.MergeCollection.Custom_Script) {
            setLanguage(AppEnum.MergeCollection.JavaScript);
        }
    };

    const handlePreDefinedScriptChange = (event) => {
        setState((prevState) => { return {...prevState, dataTransformationScriptId: event.target.value}})
        setSavedScripts({
            ...savedScripts,
            dataTransformationScriptId: event.target.value,
        })
        setState((prevState) => { return {...prevState, selectedScriptType: AppEnum.MergeCollection.Predefined_Script}});
    }

    const groupedPreDefinedScrips = () => {
        const asTenantUId = sessionStorage.getItem("ASTenantId") || localStorage.getItem("ASTenantId");
        
        const userScripts = preDefinedScripts?.filter((script) => script.asTenantUId === asTenantUId);
        const templateScripts = preDefinedScripts?.filter((script) => script.asTenantUId !== asTenantUId);
        
        return {userScripts, templateScripts};
    }

    const getColumnSchema = async() => {
        const collectionUIdsToMerge = state?.collectionUIdsToMerge;
        const collectionColumnsMap = [];

        if (collectionUIdsToMerge) {
            for (var collectionUId of collectionUIdsToMerge) {
                try {
                    const response = await collectionService.getCollectionDetails(collectionUId, true);
                    const collection = response?.data;

                    if (collection?.collectionColumns?.length > 0) {
                        collectionColumnsMap[collection?.name] = collection?.collectionColumns;
                    }
            }
            catch (error){
                console.error(`Error fetching collection columns for collectionUId: ${collectionUId}`, error);
            }
        }
        setSelectedCollectionsData(collectionColumnsMap);
    }
}

    return (
        <MergeQuery
            state={state}
            setState={setState}
            onChangeSqlEditorHandler={onChangeSqlEditorHandler}
            sqlEditorValue={state.restBody}
            mergeSriptValue={state.mergeScript}
            dataTransformationScriptIdValue={state.dataTransformationScriptId}
            handleMergeEditor={handleMergeEditor}
            selectedScriptType={selectedScriptType}
            handleRadioChange={handleRadioChange}
            predDeifnedScripts={preDefinedScripts}
            handlePreDefinedScriptChange={handlePreDefinedScriptChange}
            groupedPreDefinedScrips={groupedPreDefinedScrips}
            selectedCollectionsData={selectedCollectionsData}
            language={language}
        />
    )
}

export default MergeQueryController