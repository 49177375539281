import { useEffect, useRef, useState } from 'react';
import MergeCollectionList from '../../../../components/data-collections/column-selection-popup/merge-collection-list/merge-collection-list';
import NativeDataVisualizerController from '../../NativeDataVisualizer/native-data-visualizer-controller';
import DataVisualizerController from '../../DataVisualizer/data-visualizer-controller';
import { authorizeAgain, getToken } from '../../../../services/authorize-again-service';
import { getDataSourceByUID } from '../../../../services/data-source.service';
import { toast } from 'react-toastify';

export default function MergeCollectionListController(props) {

    const { state, setState, mergedCollectionsData } = props;
    const [showNativeDataVisualizer, setShowNativeDataVisualizer] = useState(false);
    const [showDataVisualizer, setShowDataVisualizer] = useState(false);
    const [selectedCollectionUId, setSelectedCollectionUId] = useState('');
    const [openConnectionPopup, setOpenConnectionPopup] = useState(false);
    const [selectedConnection, setSelectedConnection] = useState();

    const windowRef = useRef();
    const randomState = useRef();

    const openDataVisualizer = (e, collection) => {
        setSelectedCollectionUId(collection.uId)

        if (collection?.templateCollectionId) {
            setShowDataVisualizer(true);
        }
        else {
            setShowNativeDataVisualizer(true);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (openConnectionPopup) {
                getToken(selectedConnection, randomState.current, windowRef, setState,
                    openConnectionPopup, setOpenConnectionPopup, null, null);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openConnectionPopup])

    const handleAuthorizeAgain = async (uId) => {
        try {
            const response = await getDataSourceByUID(uId);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {
                setSelectedConnection(response?.data);
                authorizeAgain(setState, response?.data, windowRef, setOpenConnectionPopup, null, randomState);
                return response?.data;
            }
        } catch (error) {
            toast.error(error);
        }
    }

    return (
        <>
            <MergeCollectionList
                state={state}
                mergedCollectionsData={mergedCollectionsData}
                openDataVisualizer={openDataVisualizer}
                handleAuthorizeAgain={handleAuthorizeAgain}
            />
            {showNativeDataVisualizer &&
                <NativeDataVisualizerController
                    collectionUId={selectedCollectionUId}
                    showNativeDataVisualizer={showNativeDataVisualizer}
                    setShowNativeDataVisualizer={setShowNativeDataVisualizer} />}


            {showDataVisualizer && selectedCollectionUId &&
                <DataVisualizerController isForDataCollections={true}
                    closeWindowOnPopupClose={true}
                    collectionUId={selectedCollectionUId}
                    setShowDataVisualizer={setShowDataVisualizer}
                    showDataVisualizer={showDataVisualizer} />
            }
        </>
    )
}
