import React, { useEffect, useState } from "react";
import DataAfterTransformation from "../../../../components/data-collections/data-after-transformation/data-after-transformation";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { AppEnum } from "../../../../constants/app-enum";
import { DataTypePriorityMap, getEquivalentPriorityDataType, updateColumnDataTypes } from "../select-columns/datatypes-equivalent-map";
import { updateDisplayName } from "../../utils/template-collection-parameter";


const DataAfterTransformationController = (props) => {

    const { collectionUId: collectionUId } = useParams();
    const { state, setState, prevStepRef, isShowHeader, showSaveChangesButton } = props;
    const { testedDataList, columnSchemaList, collectionColumns, displayFormatList } = props.state;
    const [collectionColumnsDataType, setCollectionColumnsDataType] = useState([]);

    useEffect(() => {
        dataAfterTransformation(testedDataList);
    }, [])

    useEffect(() => {
        if (collectionColumns.length > 0 && state?.columnSchemaList?.length == 0 && collectionUId && state.testedDataTransformedColumneKeys.length === 0) {
            const updatedColumns = collectionColumns.map(column => ({
                ...column,
                ColumnDataType: displayFormatList?.filter(i => i.sysDataTypeId === column?.dataType)[0]?.dataBaseDataType,
                isSelected: true
            }));

            const updatedDataTypeColumnsList = updateColumnDataTypes(displayFormatList, updatedColumns);
            const sortedList = _.orderBy(
                updatedDataTypeColumnsList,
                [(col) => col.displayOrder === 0 ? Number.MAX_SAFE_INTEGER : col.displayOrder],
                ['asc']
            );
            setColumnSchema(sortedList, sortedList)           
        }

        //removing colunms whic are not present in table after transformation on update.
        if (state?.columnSchemaList && state?.collectionColumns && collectionUId && state?.testedDataTransformedColumneKeys.length > 0) {
            const filteredColumnsList = state?.columnSchemaList.filter(column => (state?.testedDataTransformedColumneKeys.includes(column.columnName) || column?.isManuallyCreated));
            const filteredColumns = state?.collectionColumns.filter(column => (state?.testedDataTransformedColumneKeys.includes(column.columnName) || column?.isManuallyCreated));
            setColumnSchema(filteredColumnsList, filteredColumns);
        }
    }, [state?.testedDataTransformedColumneKeys]);

    const getType = (value) => {
        if (value === null) {
            return AppEnum.DataBaseDataType.String;
        }
        const type = Object.prototype.toString.call(value).slice(8, -1);
        if (type === AppEnum.DataBaseDataType.Number) {
            return Number.isInteger(value) ? AppEnum.DataBaseDataType.Int : AppEnum.DataBaseDataType.Long;
        }

        if (type === AppEnum.DataBaseDataType.String) {
            // Attempt to parse the value for various data types
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                return Number.isInteger(parseFloat(value)) ? AppEnum.DataBaseDataType.Int : AppEnum.DataBaseDataType.Long;
            }
            else if (!isNaN(Date.parse(value))) {
                const parsedDate = new Date(value);
                if (!isNaN(parsedDate.getTime())) {
                    if (value.includes("T")) {
                        return AppEnum.DataBaseDataType.DateTime;
                    } else {
                        return AppEnum.DataBaseDataType.Date;
                    }
                }
            }else if (/^\d+$/.test(value)) {
                const byteValue = parseInt(value);
                if (!isNaN(byteValue) && byteValue >= 0 && byteValue <= 255) {
                    return AppEnum.DataBaseDataType.Byte;
                }
            } else if (/^\$?[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
                return AppEnum.DataBaseDataType.Currency;
            }
        }
        return type;
    };

    const getDataTypeForObject = (data) => {
        const dataTypes = {};
        const keyValues = {};
        if (!data) return;

        if (state.collectionColumnSchema) {
            let obj = data[0];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (state.collectionColumnSchema) {
                        const column = state.collectionColumnSchema.find(item => item.columnName === key);
                        dataTypes[key] = column ? column.columnDataType : getType(value);
                    }
                    else {
                        dataTypes[key] = getType(value);
                    }
                }
            }
        }
        else {
            [...data]?.reverse()?.forEach((row) => {

                for (const key in row) {
                    if (row.hasOwnProperty(key)) {
                        const value = row[key];
                        if (keyValues[key]) {
                            continue;
                        }
                        keyValues[key] = value;
                    }
                }
            });

            if (keyValues){
                for (const key in keyValues) {
                    if (keyValues.hasOwnProperty(key)) {
                        const value = keyValues[key];
                       
                        if (dataTypes[key]) {
                            continue;
                        }
                        let baseType = getType(value);
                        if (baseType === AppEnum.DataBaseDataType.Int) {
                            let foundDecimal = data.some(row => 
                                row.hasOwnProperty(key) && row[key] != null && 
                                /^\d+\.\d+$/.test(row[key].toString())
                            );
                            dataTypes[key] = foundDecimal ?  AppEnum.DataBaseDataType.Long : baseType;
                        } else {
                            dataTypes[key] = baseType;
                        }
                    }
                }
            }
        }
        return dataTypes;
    };

    const dataAfterTransformation = (testedDataList) => {

        let errorMessage = '';
        let transformedData = null;
        let testedDataTransformedColumnsKeys = null;

        if (testedDataList) {
            try {
                const dataTransformation = eval(`(${state.restDataTransformationScript})`);
                transformedData = dataTransformation(testedDataList);
            }
            catch (error) {
                setErrorMessage(error.message, false);
                return;
            }

            if (typeof transformedData === 'undefined') {
                setErrorMessage('The above Data Tranformation Script function did not return anything.', false);
                return;
            }

            let maxPropsObject = {};
            let maxPropsCount = 0;

            testedDataList.forEach(obj => {
                const numProps = Object.keys(obj).length;
                if (numProps > maxPropsCount) {
                    maxPropsCount = numProps;
                    maxPropsObject = obj;
                }
            });

            let collectionColumnsDataType = getDataTypeForObject(transformedData);
            let updatedEquivalentCollectionColumnsDataType = getEquivalentDataTypes(collectionColumnsDataType);
            setCollectionColumnsDataType(updatedEquivalentCollectionColumnsDataType);

            testedDataTransformedColumnsKeys = transformedData[0];
            testedDataTransformedColumnsKeys = (_.keys(testedDataTransformedColumnsKeys));

            setState((prevState) => {
                return {
                    ...prevState,
                    testedDataTransformedList: transformedData?.length > 100 ? transformedData?.slice(0, 100) : transformedData,
                    testedDataTransformedColumneKeys: testedDataTransformedColumnsKeys
                }
            });
        }

        setErrorMessage(errorMessage, false);
    }


    const onCheckColumnHandler = (isChecked, columnName, isSelectAll) => {
        showSaveChangesButton(true);
        let schemaList = state.columnSchemaList;
        let columns = isShowHeader ? state.collectionColumnSchema : state.testedDataTransformedColumneKeys;
        let selectedcolum = isShowHeader ? columnName : null;

        if(isShowHeader){
            columnName = selectedcolum.columnName;
        }

        const getEquivalentDataType = getEquivalentPriorityDataType(displayFormatList, isShowHeader ? selectedcolum?.columnDataType : collectionColumnsDataType[columnName]);

        var preColumn = schemaList.filter(i => i.columnName === (isShowHeader ? selectedcolum.columnName : columnName))[0]; // for updating the saved columns
        let displayFormate = displayFormatList.filter(i => i.dataBaseDataType === (isShowHeader ? selectedcolum.columnDataType : collectionColumnsDataType[columnName]))[0];

        if (getEquivalentDataType && getEquivalentDataType?.dataBaseDataType != displayFormate?.dataBaseDataType){
            displayFormate = getEquivalentDataType;
            if(isShowHeader){
                selectedcolum.columnDataType = getEquivalentDataType?.dataBaseDataType
            }
        }

        if (isChecked && ((isShowHeader && columns.filter(i => i.columnName == columnName).length > 0) || (!isShowHeader && columns.includes(columnName)))) {
            const TransformedcolumnIndex = isShowHeader ? columns.findIndex(column => column.columnName?.toLowerCase().trim() == columnName?.toLowerCase().trim()) : columns.indexOf(columnName);

            const columnIndex = columnSchemaList.findIndex(column => column.columnName === columnName);
            if (columnIndex !== -1) {
                columnSchemaList[columnIndex] = { ...columnSchemaList[columnIndex], isSelected: true };
            }
            let updatedCollectionColumns;
            if (preColumn && preColumn?.uId) {
                preColumn.isSelected = true;
                if (!collectionColumns.some(col => col.columnName === preColumn.columnName)) {
                    collectionColumns.push(preColumn);
                }
            }
            else {
                let columnSchema = {
                    columnName: columnName,
                    displayName: updateDisplayName(columnName),
                    DisplayFormat: displayFormate?.displayFormat ? displayFormate.displayFormat : AppEnum.DefaultDisplayFormat.General,
                    isSelected: true,
                    ColumnDataType: isShowHeader ? selectedcolum?.columnDataType : displayFormate?.dataBaseDataType,
                    DataType: displayFormate?.sysDataTypeId,
                    isManuallyCreated: false,
                    displayOrder: 0
                };

                let collectionColumn = {
                    columnName: columnName,
                    displayName: updateDisplayName(columnName),
                    DisplayFormat: displayFormate?.displayFormat ? displayFormate.displayFormat : AppEnum.DefaultDisplayFormat.General,
                    IsSelected: true,
                    ColumnDataType: isShowHeader ? selectedcolum?.columnDataType : displayFormate?.dataBaseDataType,
                    DataType: displayFormate?.sysDataTypeId,
                    isManuallyCreated: false,
                    displayOrder: 0
                };
                if (!columnSchemaList.some(col => col.columnName === columnName)) {
                    columnSchemaList.splice(TransformedcolumnIndex, 0, columnSchema);
                }

                updatedCollectionColumns = [
                    ...collectionColumns.slice(0, TransformedcolumnIndex),
                    ...(collectionColumns[TransformedcolumnIndex]?.columnName === collectionColumn.columnName 
                    ? [] 
                    : [collectionColumn]),
                    ...collectionColumns.slice(TransformedcolumnIndex)
                ];
            }

            if(isSelectAll) {
                return { updatedAllColumnSchemaList: columnSchemaList, updatedAllCollectionColumns: columnSchemaList };
            }

            setColumnSchema(columnSchemaList, updatedCollectionColumns ? updatedCollectionColumns : collectionColumns);
        } else {
            let updatedCollectionColumns;
            if (preColumn && preColumn?.uId) {
                const columnIndex = columnSchemaList.findIndex(column => column.columnName === columnName);
                if (columnIndex !== -1) {
                    columnSchemaList[columnIndex] = { ...columnSchemaList[columnIndex], isSelected: false };
                }
                updatedCollectionColumns = collectionColumns?.filter(col => (col?.columnName?.toLowerCase().trim() || col?.ColumnName?.toLowerCase().trim()) != columnName?.toLowerCase().trim());
            }
            else {
                _.remove(columnSchemaList, col => (col?.columnName?.toLowerCase().trim() || col?.ColumnName?.toLowerCase().trim()) === columnName?.toLowerCase().trim());
                _.remove(collectionColumns, col => (col?.columnName?.toLowerCase().trim() || col?.ColumnName?.toLowerCase().trim()) === columnName?.toLowerCase().trim());
            }
            if(isSelectAll) {
                return { updatedAllColumnSchemaList: columnSchemaList, updatedAllCollectionColumns: [] };
            }
            setColumnSchema(columnSchemaList, updatedCollectionColumns ? updatedCollectionColumns : collectionColumns);
        }
    };

    const onCheckAllColumnHandler = (event) => {
        
        setColumnSchema([],[]);

        const isChecked = event?.target?.checked;
        let updatedData;
        if(isShowHeader){
            state?.collectionColumnSchema?.forEach(columnName => {
                updatedData = onCheckColumnHandler(isChecked, columnName, true);
            });
        }
        else{
            state?.testedDataTransformedColumneKeys?.forEach(columnName => {
                updatedData = onCheckColumnHandler(isChecked, columnName, true);
            });
        }
        setColumnSchema(updatedData?.updatedAllColumnSchemaList, updatedData?.updatedAllCollectionColumns)
    }


    // sets the latest column and format schema to the state
    const setColumnSchema = (columnSchemaList, collectionColumns) => {
        setState((prevState) => {
            return {
                ...prevState, collectionColumns: collectionColumns,
                columnSchemaList: columnSchemaList
            }
        });
    }


    // sets error message
    const setErrorMessage = (errorMessage, isTestingConnection) => {
        setState((prevState) => {
            return {
                ...prevState, testCollectionError: errorMessage,
                isTestingConnection: isTestingConnection
            }
        });
    }

    const getEquivalentDataTypes = (originalDataTypes) => {
        return Object.keys(originalDataTypes)?.reduce((acc, key) => {
            const originalType = originalDataTypes[key];
            const highestPriorityType = DataTypePriorityMap[originalType] || originalType;
            acc[key] = highestPriorityType;
            return acc;
        }, {});
    };

    return (
        <DataAfterTransformation state={state}
            prevStepRef={prevStepRef}
            onCheckColumnHandler={onCheckColumnHandler}
            onCheckAllColumnHandler={onCheckAllColumnHandler}
            isShowHeader={isShowHeader}
        />
    );
}

export default DataAfterTransformationController;
