import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddNewDataScriptComponent from "../../../components/data-collections/data-script-component/add-new-data-script";
import { AddNewDataScriptModel } from "../data-editing-script-state";
import { AppEnum } from "../../../constants/app-enum";
import { addDataScript, getScriptByUId, updateDataScript } from "../../../services/data-script.service";
import { toast } from "react-toastify";
import _ from "lodash";

const AddNewDataScriptController = (props) => {
    const [state, setState] = useState(new AddNewDataScriptModel());
    const { scriptUId: scriptUId } = useParams();
    const [aGGridView, setAGGridView] = useState();
    const [selectedViewButton, setSelectedViewButton] = useState(1);
    const [selectedSearchButton, setSelectedSearchButton] = useState(0);
    const [dataScriptView, setDataScriptView] = useState(AppEnum.DataScriptView.TableView);
    const navigate = useNavigate();

    useEffect(async () => {
        if (scriptUId) {
            await getScriptByUid();
        }
    }, [])

    const getScriptByUid = async () => {
        try {
            const response = await getScriptByUId(scriptUId);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {
                setState((prevState) => {
                    return {
                        ...prevState, applicableToCD: response?.data?.applicableToCD,
                        scriptTypeCD: response?.data?.scriptTypeCD,
                        aSTenantUId: response?.data?.asTenantUId,
                        name: response?.data?.name,
                        sampleData: response?.data?.sampleData,
                        script: response?.data?.script,
                        uId: response?.data?.uId,
                    }
                });
                handleTestScript(response?.data);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const handleButtonOptionsClick = (selectedStep) => {
        setSelectedSearchButton(selectedStep);
    };

    const handleButtonViewClick = (selectedStep) => {
        let dataScriptView = selectedStep === 1 ? AppEnum.DataScriptView.TableView : AppEnum.DataScriptView.JSONView;
        setDataScriptView(dataScriptView);
        setSelectedViewButton(selectedStep);
    }

    const onScriptNameChange = (event) => {
        setState((prevState) => { return { ...prevState, name: event.target.value } });
    }

    const onDataScriptSave = async () => {
        try {
            const response = await addDataScript(state);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                toast.success("Data Script added successfully.");
                navigate("/data-script");
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const onDataScriptUpdate = async () => {
        try {
            const response = await updateDataScript(scriptUId, state);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                toast.success("Data Script updated successfully.");
                navigate("/data-script");
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const handleTestScript = (prevScriptData) => {
        try {
            const scriptState = prevScriptData || state;
            if (!scriptState?.script) throw new Error("Script is missing or invalid.");

            let sampleData = scriptState?.sampleData ? JSON.parse(scriptState.sampleData) : null;
            let dataToTest = sampleData ?? [];

            let isValueObject = typeof sampleData === "object" && sampleData !== null && !Array.isArray(sampleData);;
            let args = isValueObject ? Object.values(dataToTest) : dataToTest;
            
            const dataTransformation = eval(`(${scriptState?.script})`);
            let transformedData = isValueObject ? dataTransformation(...args) : dataTransformation(args);

            const testedDataTransformedColumnsKeys = transformedData?.[0]
                    ? Object.keys(transformedData[0]).filter((key) => !key.endsWith("-style"))
                    : [];

            if (!Array.isArray(transformedData)) {
                toast.error("Warning: Transformed data is not an array.", transformedData);
                return;
            }
            else if (Array.isArray(testedDataTransformedColumnsKeys) && transformedData?.length === 0) {
                toast.error("Warning: Columns data is empty.");
                return;
            }            
            setAGGridView((prevState) => { return { ...prevState, transformedData: transformedData, transformedDataColunmsKey: testedDataTransformedColumnsKeys } });

            handleButtonOptionsClick(2)
        } catch (error) {
            console.error("Syntax error in the script:", error.message);
            toast.error(error.message);
            return;
        }
    }

    const onScriptTypeClick = (event) => {
        if (event.target.value === AppEnum.DataScriptApplicable.RESPONSE_STYLING) {
            setState((prevState) => {
                return {
                    ...prevState,
                    scriptTypeCD: event.target.value,
                    applicableToCD: AppEnum.DataScriptApplicable.NORMAL_DATA_COLLECTIONS
                }
            });
        }
        else {
            setState((prevState) => { return { ...prevState, scriptTypeCD: event.target.value } });
        }
    }

    const onApplicableClick = (event) => {
        setState((prevState) => { return { ...prevState, applicableToCD: event.target.value } });
    }

    const handleAceEditor = (event) => {
        setState((prevState) => { return { ...prevState, script: event } });
    }

    const handleSampleDataChange = (event) => {
        setState((prevState) => { return { ...prevState, sampleData: event } });
    }

    const isButtonDisabled = useMemo(() => {
        const isNameEmpty = !state?.name?.trim();
        const isScriptTypeCD = !state?.scriptTypeCD?.trim();
        const isApplicableToCD = !state?.applicableToCD?.trim();
    
        return isNameEmpty || isScriptTypeCD || isApplicableToCD;
    }, [state?.name, state?.scriptTypeCD, state?.applicableToCD]);

    return (
        <>
            <AddNewDataScriptComponent
                scriptUId={scriptUId}
                state={state}
                handleButtonOptionsClick={handleButtonOptionsClick}
                handleButtonViewClick={handleButtonViewClick}
                selectedViewButton={selectedViewButton}
                selectedSearchButton={selectedSearchButton}
                onDataScriptSave={onDataScriptSave}
                onScriptNameChange={onScriptNameChange}
                dataScriptView={dataScriptView}
                onApplicableClick={onApplicableClick}
                onScriptTypeClick={onScriptTypeClick}
                handleAceEditor={handleAceEditor}
                handleSampleDataChange={handleSampleDataChange}
                onDataScriptUpdate={onDataScriptUpdate}
                handleTestScript={handleTestScript}
                aGGridView={aGGridView}
                isButtonDisabled={isButtonDisabled}
            />
        </>
    )
}

export default AddNewDataScriptController;