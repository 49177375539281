import React, { useMemo } from 'react'
import "./column-selection-list.scss"
import { FormControlLabel, TextField } from '@mui/material';
import CheckBox from '@mui/material/Checkbox';
import styles from "../../../styles/_exports.module.scss";

const muiCheckboxStyle = {
    color: styles.secondaryColor,
    '&.Mui-checked': {
        color: styles.checkboxColor,
    },
    '&.Mui-disabled': {
        color: 'grey',
    },
    '&.MuiFormControlLabel-label': {
        fontSize: 16,
    },
    '&.MuiSvgIcon-root':{
        fontSize : 18
    }
}

export default function ColumnSelectionList(props) {

    const { columnsList, handleOnChangeSelectColumn, onSearchHandler, filterColumnsList, cloudDataScriptType } = props;

    const isChecked = useMemo(() => {
       return cloudDataScriptType
            ? !columnsList?.some(i => !i.isSelected && !i.isAutoDeleted)
            : !columnsList?.some(i => !i.isSelected)
    }, [cloudDataScriptType, columnsList]);

  return (
    <div className='column-selection-list-container'>
       <div className='column-selection-list-name'>
            <span className='column-selection-header'>Select Columns</span>
            <span className='column-count-div'>{(columnsList?.filter((i) => i.isSelected && (cloudDataScriptType ? !i?.isAutoDeleted : !i.isAutoPopulated))).length} columns selected</span>
       </div>

       <div className='column-search-input-div'>
            <div className='column-search-input'>
                <TextField id="outlined-basic" label="Search Fields" value={props?.searchValue} inputRef={props.searchInputRef} fullWidth variant="outlined" onChange={(e) => onSearchHandler(e)} />
            </div>
       </div>

            {((props.searchInputRef?.current?.value == '') || (props.searchInputRef?.current?.value == undefined)) && <FormControlLabel control={
                   <CheckBox
                   checked={isChecked}
                        onChange={(event) => handleOnChangeSelectColumn(event,null)}
                        sx={muiCheckboxStyle}
                    />
                }
                className='checkbox-column'
                label="Select All"
            />}
            <div className='column-list-wrapper'>
                <div className='column-list-view'>
                    {
                        (props.searchInputRef?.current?.value ? filterColumnsList : columnsList)
                        ?.filter(item => cloudDataScriptType ? !item?.isAutoDeleted : !item.isAutoPopulated)
                        ?.map((item,index) => {
                            return (
                                <FormControlLabel control={
                                    <CheckBox
                                        checked={item.isSelected}
                                        onChange={(event) => handleOnChangeSelectColumn(event,index, item)}
                                        sx={muiCheckboxStyle}
                                        disabled={item?.isAutoPopulated}  
                                    />
                                }
                                    className='checkbox-column-item'
                                    label={item.displayName}
                                />
                            )
                        })
                    }
                </div>
            </div>
    </div>
  );
}
