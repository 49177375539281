import { AppEnum } from '../../../constants/app-enum';
import { DateParseVariable } from '../../../constants/date-parse-variable';
import * as collectionService from '../../../services/data-collection.service';
import { isNullOrEmpty } from '../../../services/data-source-validation';
import { checkOtherParams, filterCollectionParams } from '../DataVisualizer/data-visualizer-controller';

export const getTemplateCollectionParameters = (templateCollectionUId, setState, setIsSearchOptionsAvailable, setSearchOptionsParameters, 
    setManualColumns, setColumnOverriden, collectionUId = null, state, excelDataState ) => {

    collectionService.getCollectionDetails(templateCollectionUId)
        .then((response) => {
            if (response?.data) {
                let prevCollection = response?.data;
                if (prevCollection) {
                    if(isNullOrEmpty(collectionUId)){
                        setState((prevState) => { return { ...prevState, name: prevCollection?.name, groupName : prevCollection?.groupName } });
                    }                    

                    if(prevCollection?.restDataTransformationScript){
                        setState((prevState) => { return { ...prevState, restDataTransformationScript: prevCollection?.restDataTransformationScript } });
                    }                   
                    const manuallyCreatedColumns = prevCollection.collectionColumns.filter(mc => mc.isManuallyCreated);
                    
                    if(manuallyCreatedColumns.length > 0){
                        setManualColumns(manuallyCreatedColumns);
                        setColumnOverriden(true);
                    }
                    else{
                        setManualColumns([]);
                        setColumnOverriden(false);
                    }

                    if (prevCollection.collectionParameters?.length > 0){
                        const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);

                          if (hasOtherParams){
                            prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                            let updatedParams = updateParameterValues(prevCollection.collectionParameters, state?.savedParameterValue, excelDataState);
                            setIsSearchOptionsAvailable(true);                        
                            setSearchOptionsParameters(updatedParams);
                          }
                          else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                          }
                    }
                    else{
                        setIsSearchOptionsAvailable(false);
                        setSearchOptionsParameters([]);
                    }
                }
            }
        })
}

export const updateParameterValues = (params, savedParameterValue, excelDataState) => {    
    const dateParser = new DateParseVariable();

    return params.map(param => {
        if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList && param?.complexTypeValue) {
            const complexType = JSON.parse(param.complexTypeValue);
            param.parameterValue = complexType?.DefaultValue
        }
        else if (param.sysDataTypeId == AppEnum.DataTypeId.List && param?.complexTypeValue) {
            const complexTypeArray = JSON.parse(param.complexTypeValue);
            const defaultItem = complexTypeArray?.find(item => item.Default === true);
            param.parameterValue = defaultItem?.Value;
        }
        else if(param.parseAtClientSide && param.sysDataTypeId === AppEnum.DataTypeId.Date){
            param.parameterValue = dateParser.parseVariable(param.defaultValue);
        }
        else if ((param.parameterTypeCD == AppEnum.ParameterTypeValue.UserInput || param.parameterTypeCD == AppEnum.ParameterTypeValue.MultiValuedTextBox) && 
            param.sysDataTypeId != AppEnum.DataTypeId.Date &&
            param.sysDataTypeId != AppEnum.DataTypeId.List && !param.variableUId) 
        {
            param.parameterValue = param.defaultValue;
        }
        else {
            //param.parameterValue = param.defaultValue;
        }

        if (param?.enableSavingParameterValue && savedParameterValue) {
            let savedValues = JSON.parse(savedParameterValue);
            let savedParamValue =  savedValues?.find(i => i.Id == param?.id);
            if (savedParamValue) {
                param.parameterValue = savedValues?.find(i => i.Id == param?.id)?.Value;
            }
        }

        if (excelDataState?.isCommandFromExcel && excelDataState?.parameters) {
            let excelParameters = JSON.parse(excelDataState?.parameters);
            if (excelParameters) {
                if (excelDataState?.isMergeCollectionFromExcel) {
                    let paramValue =  excelParameters?.find(i => i.Id == param?.id && i.ChildCollectionUId == param?.childCollectionUId);
                    if (paramValue) {
                        param.parameterValue = excelParameters?.find(i => i.Id == param?.id && i.ChildCollectionUId == param?.childCollectionUId)?.ParameterValue;
                    }
                }
                else {
                    let paramValue =  excelParameters?.find(i => i.Id == param?.id);
                    if (paramValue) {
                        param.parameterValue = excelParameters?.find(i => i.Id == param?.id)?.ParameterValue;
                    }
                }
            }
        }

        return param;
    });
};

export const formatDisplayName = (displayName) => {
    const newDisplayName = displayName?.replace(/[./-]/g, '_');
    return newDisplayName;
}

export const updateDisplayName = (displayName) => {
    const formatted = displayName?.replace(/_/g, ' ') // Replace underscores with spaces
                    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camel case
                    .replace(/\s{2,}/g, ' ') // Handle multiple spaces (edge cases)
                    .trim(); 
    return formatted ? formatted?.charAt(0).toUpperCase() + formatted?.slice(1) : '';
};