import { toast } from "react-toastify";
import * as connectionService from '../services/data-source.service';
import { RestServiceConstants } from "../constants/rest-service-constant";
import { getTokenFromAuthCode } from "./authCallback-service";
import { generateRandomString } from "../shared/code-challanges/code-challanges";
import { Environment } from "../core/environment";
import { AppEnum } from "../constants/app-enum";
import { getSessionStorageItem } from "../shared/session-storage-handler/session-storage-handler";

export const authorizeAgain = (setState, selectedConnection, windowRef,
    setOpenConnectionPopup, setStateConnectionParameter, randomState) => {
    setState((prevState) => {
        return {
            ...prevState, isTestingConnection: true
        }
    });

    const stateConParam = generateRandomString(50);

    var data = {
        client_id: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID)[0].parameterValue,
        redirect_uri: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL)[0].parameterValue,
        auth_url: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL)[0].parameterValue,
        scope: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.SCOPE)[0].parameterValue,
        state: stateConParam
    };
    const queryString = Object.keys(data)
        .map(
            (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    windowRef.current = window.open(
        `/begin-oauth?${queryString}`,
        "_blank",
        "width=750,height=550"
    );

    setOpenConnectionPopup(true);
    if(setStateConnectionParameter){
        setStateConnectionParameter(stateConParam);
    }
    if(randomState){
        randomState.current = stateConParam;
    }

    const checkPopupClosed = setInterval(() => {
        if (windowRef.current.closed) {
            // The popup window is closed
            clearInterval(checkPopupClosed);
            windowRef.current = null;
            setState((prevState) => {
                return {
                    ...prevState, isTestingConnection: false,
                    testCollectionError: "This process has been stopped midway. Please try again."
                }
            });
        }
    }, 3000);
};

export const getToken = async (selectedConnection, stateConnectionParameter, windowRef, setState,
    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, callBackFunc) => {
    try {
        var connection = {
            "name": selectedConnection.connectionName,
            "uId": selectedConnection.uId,
            "sysConnectionTypeId": selectedConnection?.baseConnectionTypeId || selectedConnection?.connectionTypeId,
            "isSaasAuthSource": false,
            "description": selectedConnection.description,
            "parameters": selectedConnection.parameters,
            "reestablishConnection": true
        };

        var exisitingAuthCode = connection.parameters?.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE);
        if (exisitingAuthCode.length == 0) {

            const authCodeParameter = {
                configConnectionParameterTypeId: 2016,
                parameterName: RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE,
                parameterValue: "",
                isValid: true
            };
            connection.parameters?.push(authCodeParameter);
        }

        const response = await getTokenFromAuthCode(stateConnectionParameter, connection);
        if (response?.data?.hasError) {
            if (openConnectionPopup && windowRef.current === null) {
                setOpenConnectionPopup(false);
            }
        }
        else {
            if (response?.data?.data?.access_token) {
                connection.parameters.filter((i) => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)[0].parameterValue = response?.data?.data?.access_token;
                if(setIsAuthorizeAgain){
                    setIsAuthorizeAgain(false);
                }
                setOpenConnectionPopup(false);
                if (selectedConnection?.templateConnectionId) {
                    setState((prevState) => { return { ...prevState, 
                        cloudCollectionError: response,
                        mergeCollectionError: "Connection Establish"
                    } });
                }
                else {
                    connectionService.updateDataSourceType(connection).then((updateRes) => {
                        setState((prevState) => {
                            return {
                                ...prevState, nativeCollectionError: "",
                                testCollectionError: "The connection was established successfully. Now you can test your collection.",
                                testedDataList: [],
                                testedDataColumnKeys: [],
                                isTestingConnection: false
                            }
                        });
                        callBackFunc();
                    }).catch((error) => {

                    });
                }
            }
        }
    } catch (error) {
        toast.error(error);
        setOpenConnectionPopup(false);
    }
};

        
export const openOtpPopupAuthorization = async (setState, selectedConnection, searchOptionsParameters,  setIsAuthorizeAgain, callBackFunc, state) => {
    try {
        let userName = "";
        let stateCode = "";
        const aSTenantUId = getSessionStorageItem("ASTenantId");
        
        let gstParam = searchOptionsParameters?.find(i => i.sysDataTypeId == AppEnum.DataTypeId.List && i.parameterName == "@gstin"); //gst auth param

        if (!gstParam && searchOptionsParameters?.length == 0 && state && state?.templateCollectionId == null) {
            let gstParamVariableUId = state?.collectionParameters?.find(i => i.sysDataTypeId == AppEnum.DataTypeId.List && i.parameterName == "@gstin")?.variableUId;
            if (gstParamVariableUId && state?.variableList?.length > 0) {
                gstParam = state?.variableList?.find(i => i.uId == gstParamVariableUId);
            }
        }

        if (gstParam && gstParam?.complexTypeValue) {
            const complexTypeArray = JSON.parse(gstParam.complexTypeValue);
            const defaultItem = complexTypeArray?.find(item => item.Default === true);
            if (gstParam?.parameterValue) {
                userName = gstParam.parameterValue;
                let gstNumber = complexTypeArray?.find(item => item.Value == gstParam.parameterValue)?.Label;
                stateCode = gstNumber.substring(0, 2);
            }
            else {
                userName = defaultItem?.Value;
                let gstNumber = defaultItem?.Label;
                stateCode = gstNumber.substring(0, 2);
            }
        }

        let baseUrl = Environment.getUrl();
        baseUrl = baseUrl.replace(/\/api\/v1$/, "");

        const otpUrl = `${baseUrl}/GST-OTP?stateCode=${stateCode}&username=${userName}&connectionUId=${selectedConnection.uId}&aSTenantUId=${aSTenantUId}`;
        const access_token = window.localStorage.getItem("token");
        const pageOpenerUrl = `${baseUrl}/page-opener?token=${encodeURIComponent(access_token)}&otpurl=${encodeURIComponent(otpUrl)}`;

        window.open(pageOpenerUrl, "_blank", "width=600,height=500");

        window.addEventListener("message", (event) => {
            if (event.origin !== baseUrl) return; // Security check

            if (event.data.success) {
                console.log("Authentication Successful:", event.data.message);
                if (selectedConnection?.templateConnectionId) {
                    setState((prevState) => ({ ...prevState, cloudCollectionError: { data: { data: { access_token: true }}} }));
                }
                else {
                    setState((prevState) => {
                        return {
                            ...prevState, nativeCollectionError: "",
                            testCollectionError: "The connection was established successfully. Now you can test your collection.",
                            testedDataList: [],
                            testedDataColumnKeys: [],
                            isTestingConnection: false
                        }
                    });
                    if (callBackFunc) {
                        callBackFunc();
                    }
                    setIsAuthorizeAgain(false);
                }
            } else {
                console.log("Authentication Failed:", event.data.message);
            }
        });

    } catch (error) {
        console.log("Error:", error);
    }
};
