import React, { useCallback, useEffect, useState } from 'react'
import MergeCollectionParameters from '../../../../components/data-collections/merge-collection/merge-collection-parameters';
import * as collectionService from '../../../../services/data-collection.service';
import _ from 'lodash';


const MergeCollectionParametersController = (props) => {

    const { setState, prevStepRef, state } = props;

    const [collectionsParameters, setCollectionParameters] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);


    useEffect(() => {
        getCollectionsParameters()
    }, [])


    const getCollectionsParameters = () => {

        setIsDataLoading(true);

        collectionService.getMergeCollectionParameters(state?.collectionUIdsToMerge)
            .then((response) => {
                if (response?.hasError) {
                    setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });
                }
                else if (response?.data) {

                    let uniqueParameters = _.uniqBy(response?.data, item => `${item.parameterTypeCD}_${item.parameterName}_${item.sysDataTypeId}`);
                    setCollectionParameters(uniqueParameters)
                    setState((prevState) => { return { ...prevState, collectionParameters: uniqueParameters } });

                }
            }).finally(() => {
                setIsDataLoading(false);
            })
    }

    const handleCombineParameterCheckbox = useCallback((event) => {
        const isChecked = event.target.checked;
        setState((prevState) => ({
            ...prevState,
            combineMergedParameters: isChecked,
        }));
    }, [setState]);


    return (
        <MergeCollectionParameters
            collectionsParameters={collectionsParameters}
            isDataLoading={isDataLoading}
            prevStepRef={prevStepRef}
            state={state}
            handleCombineParameterCheckbox={handleCombineParameterCheckbox}
        />
    )
}

export default MergeCollectionParametersController