export const DataTypePriorityMap = {
    int16: 'Int64',
    int32: 'Int64',
    int64: 'Int64',
    double: 'Int64',
    single: 'Int64',
    number: 'Int64',
    decimal: 'Decimal',
    currency: 'Decimal',
    percentage: 'Decimal',
    datetime: 'Date',
    date: 'Date',
    time: 'Date',
    timespan: 'Date',
    boolean: 'Boolean',
    datetimeoffset: 'Date',
    string: 'String',
    object: 'String',
    guid: 'String',
    byte: 'String',
    enumeration: "String",
    'scientific notation': 'String',
    'byte[]': 'String',
    undefined: "String",
};


export const getEquivalentPriorityDataType = (displayFormatList, dataType) => {
    if (dataType) {
        const lowerCaseDataType = dataType?.toLowerCase(); 
        const highestType = DataTypePriorityMap[lowerCaseDataType] || lowerCaseDataType;
        return displayFormatList.find(dt => dt.dataBaseDataType === highestType) || null;
    } else {
        const highestDataTypeObjects = displayFormatList.reduce((acc, dt) => {            
            const lowerCaseDataType = dt.dataBaseDataType?.toLowerCase(); 

            const highestType = DataTypePriorityMap[lowerCaseDataType] || lowerCaseDataType;

            if (!acc.some(item => item.dataBaseDataType === highestType)) {
                const dataTypeObject = displayFormatList.find(item => item.dataBaseDataType === highestType);
                if (dataTypeObject) {
                    acc.push(dataTypeObject);
                }
            }

            return acc;
        }, []);

        return highestDataTypeObjects;
    }
};


export const updateColumnDataTypes = (displayFormatList, columns) => {
    return columns?.map(column => {
        const columnDataType = column?.ColumnDataType?.toLowerCase(); 
        const highestPriorityType = DataTypePriorityMap[columnDataType] || columnDataType;
        if (columnDataType !== highestPriorityType?.toLowerCase()) {
            let datatypeDisplayFormate = displayFormatList?.find(i => i.dataBaseDataType == highestPriorityType);
            return {
                    ...column,
                    ColumnDataType: datatypeDisplayFormate?.dataBaseDataType,
                    displayFormat: datatypeDisplayFormate?.displayFormat,
                    dataType: datatypeDisplayFormate?.sysDataTypeId
            };
        }
        return column;
    });
};