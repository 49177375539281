import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { ASButton } from "../../../shared/as-button/as-button";
import serverFunctions from "../utils2/serverFunctions.js";
import _ from "lodash"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ASDataCollections } from "../../../shared/menu-icons/as-data-collections";
import BoltIcon from '@mui/icons-material/Bolt';
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import './plugin-parameters.scss';
import SheetsOverlay from "../SheetsOverlay/sheets-overlay";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CollectionFilter } from "../search-parameters/FilterParameters";
import { SearchParameterController } from "../../../controllers/asheetPlugin/search-parameter.js/search-parameters.js";


const PluginParameter = () => {

    const { collectionUId: collectionUId } = useParams();
    const [state, setState] = useState()
    const [selectedCollection, setSelectedCollection] = useState()
    const [isOpen, setIsOpen] = useState()
    const location = useLocation()
    const navigate = useNavigate()

    const handleBack = () => {
        navigate("/plugin", {
            state: selectedCollection
        })
    }

    const getCollectionDetails = async () => {

        const data = await collectionService.getCollectionDetails(collectionUId)
        setState(data?.data)
    }


    useEffect(() => {
        setSelectedCollection(location.state)
        getCollectionDetails()

    }, [])


    const createSheetAndAddData = (data) => {
        const closePopUp = () => {

            setTimeout(() => {
                setIsOpen(false)
            }, 2000);
        }
        serverFunctions.addSheet(data).then(closePopUp()).catch(closePopUp());
    };

    const testDataCollection = async () => {
        setIsOpen(true)

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restBody: state.restBody,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: state.collectionParameters,
            collectionColumns: state.collectionColumns,
            uId: state.uId,
            returnFlatternData: state.sysCollectionTypeId === 7 ? true : false,
            sourceNamePart4: state.sourceNamePart4,
            hasMergeCollections: state?.hasMergeCollections,
            collectionUIdsToMerge: state?.collectionUIdsToMerge,
            flattenLikeSql: state?.flattenLikeSql || false,
            excludedProperties: state?.excludedProperties || ""
        }

        const response = await collectionService.testDataCollection(testDataCollectionModal)


        if (response?.data?.data) {
            if (response?.data?.statusCode == "Unauthorized") {

            }
            else if (response?.data?.statusCode == "Forbidden") {

            }
            else if (response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0) {
            }
            else {
                let finalData = Array.isArray(response?.data?.data) ? response?.data?.data : [response?.data?.data];
                createSheetAndAddData(finalData)
            }
        }
    }



    return (
        <div className="gsheetContainer">
            <div className="header-flex">
                <div className="flex">
                    <ArrowBackIcon onClick={handleBack}>Back</ArrowBackIcon>
                    <div className="source">
                        <img
                            src={`../../../assets/${selectedCollection?.connection?.connectionImageIcon}`}
                            alt="Xero"
                        />{' '}
                        <div className="parameter-heading">
                            {selectedCollection?.connection?.name}  <ArrowForwardIosIcon /> {selectedCollection?.name}
                        </div>
                    </div>
                </div>

                <div>
                    <ASButton
                    >
                        +
                    </ASButton>
                </div>
            </div>
            <div>
                <div className="flex mt-5">
                    <ASDataCollections className="group-image" flag={false} />
                    Group
                </div>
                <div className="ml-10 parameter-heading">
                    {selectedCollection?.groupName}  <ArrowForwardIosIcon /> {selectedCollection?.name}
                </div>
            </div>

            <div className="collection-params-container">

                <div style={{ marginInline: '5px' }}>

                    {
                        state?.collectionParameters?.map((parameter, index) => {
                            const parameterTypes = Object.values(ParameterTypeValue());

                            if (parameterTypes.includes(parameter.parameterTypeCD)) {
                                return (
                                    <div>
                                        {index === 0 &&
                                            <div className="parameter-heading mt-5 ">
                                                <ArrowForwardIosIcon /> Search Parameters
                                            </div>
                                        }

                                        <SearchParameterController parameter={parameter} index={index} state={state} setState={setState} />
                                    </div>

                                )
                            }
                            else {
                                return (
                                    <CollectionFilter data={parameter} index={index} />
                                )
                            }

                        })
                    }
                </div>
            </div>

            <div className="run-btn">
                <ASButton
                    primary={true}
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={() => testDataCollection()}
                > {<BoltIcon sx={{ color: "yellow" }} />}RUN
                </ASButton>
            </div>

            {
                isOpen &&
                <SheetsOverlay
                    connectionIcon={`${selectedCollection?.connection?.connectionImageIcon}`}
                    sheetsIcon={`/assets/google-sheets-icon.png`}
                    property={'Deals'}
                />
            }
        </div >
    )
}

export default PluginParameter