import { Button, ButtonGroup, MenuItem, Paper } from "@mui/material";
import { ASButton } from "../../../shared/as-button/as-button";
import './data-script.scss'
import { AppEnum } from "../../../constants/app-enum";
import { ASTextField } from "../../../shared/as-text-fields";
import AceEditor from "react-ace";
import { InputConstants } from "../../../constants/input-constant";
import AgGridTable from "../../common/ag-grid/ag-grid";
import ReactJson from "react-json-view";
import { useNavigate } from "react-router-dom";

const AddNewDataScriptComponent = (props) => {
    const { handleAceEditor, state, isShowHeader, dataScriptView,
        selectedSearchButton, selectedViewButton, handleButtonOptionsClick, handleButtonViewClick,
        onScriptNameChange, onDataScriptSave, onDataScriptUpdate, scriptUId, isButtonDisabled,
        onScriptTypeClick, onApplicableClick, handleSampleDataChange, handleTestScript, aGGridView
    } = props;

    const navigate = useNavigate();

    return (
        <>
            <div className="collections-header-div">

                <div className="script-name-div">
                    <ASTextField
                        size="small"
                        required={true}
                        className="text-field"
                        sx={{ width : "600px", backgroundColor: "white"}}
                        type={InputConstants.text}
                        label="Data Script"
                        variant="outlined"
                        value={state?.name}
                        onChange={(event) => onScriptNameChange(event)}
                    />
                </div>

                <div className="search-btn-div">

                    <div className="button-flex">
                        <ASButton
                            onClick={() => { navigate(-1) }}
                            primary={false}
                            variant={'contained'}
                        >
                            Cancel
                        </ASButton>

                        <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                            onClick={() => { scriptUId ? onDataScriptUpdate() : onDataScriptSave() }}
                            disabled ={isButtonDisabled}
                            primary={true}
                            variant="contained"
                        >
                            {scriptUId ? "Update" : "Save"}
                        </ASButton>
                    </div>
                </div>

            </div>


            <div className="main-script-div">
                <div className="left-view-div">
                    <div className="left-button-div">
                        <ASTextField
                            size="small"
                            required={true}
                            className="text-field1 label-text-field"
                            type={"text"}
                            sx={{ fontSize: "12px !important" }}
                            fullWidth
                            value={state?.scriptTypeCD}
                            label={"Script Type"}
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                },
                            }}
                            onChange={(e) => onScriptTypeClick(e)}
                        >                           
                            <MenuItem value={AppEnum.DataScriptApplicable.RESPONSE_TRANSFORMATION}>Response Transformation</MenuItem>
                            <MenuItem value={AppEnum.DataScriptApplicable.RESPONSE_STYLING}>Response Styling</MenuItem>
                        </ASTextField>
                    
                    {( state?.scriptTypeCD === "" || state?.scriptTypeCD === AppEnum.DataScriptApplicable.RESPONSE_TRANSFORMATION) &&
                        <ASTextField
                            size="small"
                            required={true}
                            className="text-field1 label-text-field"
                            type={"text"}
                            sx={{ fontSize: "12px !important" }}
                            fullWidth
                            value={state?.applicableToCD}
                            label={"Applicable To"}
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                },
                            }}
                            onChange={(e) => onApplicableClick(e)}
                        >
                            <MenuItem value={AppEnum.DataScriptApplicable.NORMAL_DATA_COLLECTIONS}>Normal Data Collections</MenuItem>
                            <MenuItem value={AppEnum.DataScriptApplicable.MERGE_DATA_COLLECTIONS}>Merge Data Collections</MenuItem>
                        </ASTextField>
                    }
                    </div>
                </div>

                <div className="right-view-div">
                    <div className="top-view-div">
                        <div className='script-button-group-container'>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button onClick={() => handleButtonOptionsClick(0)} className={selectedSearchButton === 0 ? 'selected' : ''}>
                                    Script
                                </Button>
                                <Button onClick={() => handleButtonOptionsClick(1)} className={selectedSearchButton === 1 ? 'selected' : ''}>
                                    Sample Data
                                </Button>
                                <Button onClick={() => handleButtonOptionsClick(2)} className={selectedSearchButton === 2 ? 'selected' : ''}>
                                    Test Result
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div>
                            <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                                onClick={() => { handleTestScript() }}
                                primary={true}
                                variant="contained"
                            >
                                Test Script
                            </ASButton>
                        </div>
                    </div>
                    <div className="table-view-div">
                        <Paper sx={{ padding: "10px", height: '65vh' }} ref={props.prevStepRef}>
                            {
                                selectedSearchButton === 2 &&
                                <div className="script-view-btn">
                                    <ButtonGroup variant="outlined" sx={{
                                        mb: 1,
                                        "& .MuiButtonGroup-grouped:first-of-type": {
                                            borderRightColor: "grey.400", // Reset the right border color
                                            borderTopRightRadius: "4px", // Restore border radius if needed
                                            borderBottomRightRadius: "4px", // Restore border radius if needed
                                        },
                                    }}>
                                        <Button
                                            sx={{
                                                padding: "5px 20px",
                                                borderColor: "grey.400",
                                                borderRight: "2px solid grey.400 !important",
                                                color: "grey.700",
                                                backgroundColor: selectedViewButton === 1 ? "grey.200" : "transparent",
                                                "&:hover": {
                                                    backgroundColor: "grey.300",
                                                    borderColor: "grey.400",
                                                },
                                                textTransform: "none",
                                                "&.Mui-focusVisible": {
                                                    outline: "none",
                                                },
                                            }}
                                            onClick={() => handleButtonViewClick(1)}
                                            variant="outlined"
                                        >
                                            Table View
                                        </Button>
                                        <Button
                                            sx={{
                                                marginLeft: "20px !important",
                                                padding: "5px 20px",
                                                borderColor: "grey.400",
                                                color: "grey.700",
                                                backgroundColor: selectedViewButton === 2 ? "grey.200" : "transparent",
                                                "&:hover": {
                                                    backgroundColor: "grey.300",
                                                    borderColor: "grey.400",
                                                },
                                                textTransform: "none",
                                                "&.Mui-focusVisible": {
                                                    outline: "none",
                                                },
                                            }}
                                            onClick={() => handleButtonViewClick(2)}
                                            variant="outlined"
                                        >
                                            JSON View
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            }
                            {
                                selectedSearchButton === 0 &&
                                <div style={{ flex: 1, paddingLeft: "16px" }}>
                                    <AceEditor
                                        placeholder="Placeholder Text"
                                        height={"65vh"}
                                        width="100%"
                                        mode="javascript"
                                        name="blah2"
                                        onLoad={() => { }}
                                        onChange={(e) => handleAceEditor(e)}
                                        fontSize={16}
                                        showPrintMargin={true}
                                        showGutter={true}
                                        highlightActiveLine={true}
                                        value={state?.script}
                                        setOptions={{
                                            enableBasicAutocompletion: false,
                                            enableLiveAutocompletion: false,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }} />
                                </div>
                            }                            
                            {
                                selectedSearchButton === 1 &&
                                <div style={{ flex: 1, paddingLeft: "16px" }}>
                                    <AceEditor
                                        placeholder="Placeholder Text"
                                        height={"65vh"}
                                        width="100%"
                                        mode="javascript"
                                        name="blah2"
                                        onLoad={() => { }}
                                        onChange={(e) => handleSampleDataChange(e)}
                                        fontSize={16}
                                        showPrintMargin={true}
                                        showGutter={true}
                                        highlightActiveLine={true}
                                        value={state?.sampleData}
                                        setOptions={{
                                            enableBasicAutocompletion: false,
                                            enableLiveAutocompletion: false,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }} />
                                </div>
                            }
                            {
                                dataScriptView === AppEnum.DataScriptView.TableView && selectedSearchButton === 2 && selectedViewButton === 1 &&
                                <div style={{ flex: 1, paddingLeft: "10px", height: "58vh" }}>
                                    <AgGridTable
                                        rows={aGGridView?.transformedData || []}
                                        columns={aGGridView?.transformedDataColunmsKey || []}
                                        isShowHeader={false}
                                    />
                                </div>
                            }
                            {
                                dataScriptView === AppEnum.DataScriptView.JSONView && selectedSearchButton === 2 && selectedViewButton === 2 &&
                                <div style={{ flex: 1, paddingLeft: "10px" }}>
                                    <ReactJson src={aGGridView?.transformedData}
                                        theme="monokai" name={false}
                                        style={{
                                            height: "58vh", width: "auto",
                                            overflowY: 'auto', overflowX: 'auto'
                                        }}
                                        displayDataTypes={false}
                                    />
                                </div>
                            }
                        </Paper>
                    </div>
                </div>

            </div>
        </>
    )
}
export default AddNewDataScriptComponent;