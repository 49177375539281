import initSqlJs from "sql.js";
import * as collectionService from '../services/data-collection.service';
import _ from 'lodash';
import { AppEnum } from "../constants/app-enum";
import { toast } from "react-toastify";
import { getDateRange } from "../controllers/data-collections/column-selection/date-filter-controller/date-filter-helper";
import { updateParameterValues } from "../controllers/data-collections/utils/template-collection-parameter";

export const MergeTestData = async (testDataCollectionModal, setCollectionState, setIsAuthorizeAgain, setIsMergeDataPopupVisible, setMergedCollectionsData,
    setMergeDataPopupMessage, setIsTestedData, isMergeCancelledRef, setIsLoading, preDefinedScripts, executeWithParameters = false, isExecutingSearchOptionsForMergeCollections = false) => {

    let dataToBeMerged = {}
    let collectionsData = {}

    const finalResultTemplate = {
        data: {
            data: [] // Empty array for now, will be populated later
        }
    };

    let mergeParamsCallingCollectionUId = {};
    let mergeCollectionsSavedParamValues = {};

    if (testDataCollectionModal?.hasMergeCollections && Array.isArray(testDataCollectionModal?.collectionUIdsToMerge)) {

        await updateMergeState("Starting the data merge process...", setMergeDataPopupMessage);
        await updateMergeState(true, setIsMergeDataPopupVisible);
        isMergeCancelledRef.current = false;

        // Iterate through collectionUIdsToMerge and execute test collection for each
        let allCollectionDetailsResults = {}
        let templateCollectionDetails = {}

        let globalExecuteWithParameters = executeWithParameters;

        // Step 1: Fetch collection details
        for (const collectionUId of testDataCollectionModal?.collectionUIdsToMerge) {
            try {
                if (collectionUId) {
                    const response = await collectionService.getCollectionDetails(collectionUId, true);
                    const collection = response?.data;
                    executeWithParameters = globalExecuteWithParameters;
                    
                    let templateCollectionDetails = null;
                
                    if (collection?.templateCollectionId) {
                        templateCollectionDetails = await collectionService.getTemplateCollectionDetails(collection.templateCollectionId);
                    }
                    collection.connectionId = collection?.connection?.id;
                
                    collection.returnFlatternData = collection?.sysCollectionTypeId === AppEnum?.SysCollectionTypeId?.REST
                        ? !(collection?.returnRawData)
                        : collection?.templateCollectionId !== null
                        ? !(templateCollectionDetails?.data?.returnRawData)
                        : false;

                    if (collection?.templateCollectionId) { 
                        executeWithParameters = true

                        if (collection?.savedParameterValue) {
                            mergeCollectionsSavedParamValues[collection?.uId] = collection?.savedParameterValue;
                        }
                    }

                    if (executeWithParameters && !isExecutingSearchOptionsForMergeCollections) {
                        collection.collectionParameters = updateParameterValues(collection?.collectionParameters, collection?.savedParameterValue)
                    }
                
                    if (executeWithParameters) {
                        collection?.collectionParameters.forEach((parameter) => {
                            if (testDataCollectionModal?.combineMergedParameters) {
                                const matchingParam = testDataCollectionModal?.collectionParameters.find(
                                    (param) =>
                                        param.parameterName === parameter.parameterName &&
                                        param.displayName === parameter.displayName &&
                                        param.sysDataTypeId === parameter.sysDataTypeId
                                );
                    
                                if (matchingParam) {
                                    parameter.defaultValue = matchingParam.defaultValue;
                                    parameter.parameterValue = matchingParam.parameterValue;
                                }
                            }
                            else {
                                if (isExecutingSearchOptionsForMergeCollections) {
                                    let paramValue = testDataCollectionModal?.collectionParameters.find(i => i.collectionParameterId == parameter.id && i.childCollectionUId == collectionUId)?.parameterValue;
                                    parameter.parameterValue = paramValue;
                                }
                            }
                        });
                    }
                
                    allCollectionDetailsResults[collection?.name] = collection;

                    collection?.collectionParameters?.forEach((params) => {
                        if (params?.sysDataTypeId == AppEnum.DataTypeId.DynamicList) {
                            let paramName = params.parameterName;
                            let childCollectionUId = collection?.uId;
                            let childConnectionUId = collection?.connectionUId;
                            mergeParamsCallingCollectionUId[paramName + childCollectionUId] = childConnectionUId;
                        }
                    });
                }
            }
                    
            catch (error) {
                handleMergeError(`${error}`, setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
                return;
            }
        }
        setCollectionState((prevState) => {
            return {
                ...prevState, 
                mergeParamsCallingCollectionUId : mergeParamsCallingCollectionUId,
                mergeCollectionsSavedParamValues : mergeCollectionsSavedParamValues
            }
        });

        collectionsData = {
            ...collectionsData,
            allCollectionDetailsResults,
        }

        let testedDataColumnKeys = null;

        if (isMergeCancelledRef.current) {
            setCollectionState((prevState) => {
                return {
                    ...prevState, testCollectionError: "Test",
                    isTestingConnection: false,
                    testedDataList: [],
                    testedDataColumnKeys: [],
                }
            });

            await updateMergeState("clear", setMergeDataPopupMessage);
            await updateMergeState(false, setIsMergeDataPopupVisible);

            isMergeCancelledRef.current = false;

            return;
        }
        // Step 2: Execute test data collection
        const testDataResults = [];
        let totalRowsCount = 0;

        for (const [collectionUId, details] of Object.entries(allCollectionDetailsResults)) {
            executeWithParameters = globalExecuteWithParameters;
            if (isMergeCancelledRef.current) {

                await updateMergeState("clear", setMergeDataPopupMessage);
                await updateMergeState(false, setIsMergeDataPopupVisible);

                setCollectionState((prevState) => {
                    return {
                        ...prevState, testCollectionError: "",
                        isTestingConnection: false,
                        testedDataList: [],
                        testedDataColumnKeys: [],
                    }
                });

                isMergeCancelledRef.current = false;

                return;
            }

            const collectionName = details.name;

            if (details?.templateCollectionId) { executeWithParameters = true };

            if (details?.collectionParameters.length > 0 && details?.templateCollectionId) {
                var filter = "";
                var sort = "";
                var limit = "";

                var filterParameter = details?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.filter);
                var sortParameter = details?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort);
                var limitParameter = details?.collectionParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit);

                if (filterParameter && filterParameter?.length > 0) {
                    filter = JSON.parse(filterParameter[0].defaultValue);
                    filter = filter.filter(group => group.filters.length > 0);

                    filter.forEach((group) => {
                        group.filters.forEach((filter) => {
                            if (filter.dateLabelValue) {
                                const { startDate, endDate } = getDateRange(filter.dateLabelValue, filter?.conditionLabel);
                                if (startDate && endDate) {
                                    filter.value = startDate;
                                    filter.highValue = endDate;
                                }
                            }
                        });
                    });
                }

                if (sortParameter && sortParameter?.length > 0) {
                    sort = JSON.parse(sortParameter[0].defaultValue);
                }

                if (limitParameter && limitParameter?.length > 0) {
                    var limit = JSON.parse(limitParameter[0].defaultValue);
                }

                details.collectionFilters = getFilterData(filter);
                details.collectionSorts = sort;
                details.collectionLimit = limit;
            }

            await updateMergeState(`Fetching details for collection: ${collectionName}...`, setMergeDataPopupMessage);

            try {
                const testResponse = executeWithParameters
                    ? await collectionService.executedDataCollection(details)
                    : await collectionService.testDataCollection(details);

                const errorMessage = handleResponseValidation({
                    response: testResponse,
                    isPopup: null,
                    setCollectionState,
                    collectionName,
                    details,
                    setMergedCollectionsData
                });

                if (errorMessage) {
                    handleMergeError(errorMessage, setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
                    return;
                }

                let finalData = testResponse.data.data || [];

                let testedDataColumnKeys = [];

                if (finalData) {
                    if (!details?.returnRawData) {
                        if (details?.sysCollectionTypeId === AppEnum?.SysCollectionTypeId?.REST || (details.templateCollectionId !== null)) {
                            finalData = Object.keys(finalData).reduce((acc, key) => {
                                finalData[key].forEach((value, index) => {
                                    if (!acc[index]) acc[index] = {};
                                    acc[index][key] = value;
                                });
                                return acc;
                            }, []);

                            testedDataColumnKeys = Object.keys(finalData[0] || {});
                        } else {
                            let maxPropsObject = {};
                            let maxPropsCount = 0;

                            finalData.forEach((obj) => {
                                const numProps = Object.keys(obj).length;
                                if (numProps > maxPropsCount) {
                                    maxPropsCount = numProps;
                                    maxPropsObject = obj;
                                }
                            });

                            testedDataColumnKeys = Object.keys(maxPropsObject);
                        }
                    }
                } else {
                    await updateMergeState(`The collection "${collectionName}" was tested successfully, but no data was found.`, setMergeDataPopupMessage);
                }

                await updateMergeState(`Successfully fetched data for ${collectionName} with ${finalData.length} row(s).`, setMergeDataPopupMessage);

                totalRowsCount += finalData.length;
                finalData = dataAfterTransformation(finalData, details.restDataTransformationScript, setCollectionState, false, details, preDefinedScripts);

                testDataResults.push({
                    collectionName,
                    finalData,
                });

            } catch (error) {
                await updateMergeState(`Error fetching collection: "${collectionName}". Please try again.`, setMergeDataPopupMessage);

                testDataResults.push({
                    collectionName,
                    finalData: [],
                });
            }
        }

        // Step 3: Process test results
        let finalDataToBeMerged = {};

        testDataResults.forEach(({ collectionName, finalData }, index) => {
            // Store data for each collection
            finalDataToBeMerged[collectionName] = finalData || [];
        });

        dataToBeMerged = {
            ...dataToBeMerged,
            finalDataToBeMerged,
        };

        if (Object.keys(dataToBeMerged).length > 0 && Object.keys(collectionsData).length > 0) {
            let testedDataColumnKeys = null;

            if (testDataCollectionModal.selectedScriptType === AppEnum.MergeCollection.Predefined_Script) {
                
                let preDefinedScript = preDefinedScripts?.find((script => script.id === testDataCollectionModal.dataTransformationScriptId));
                if (preDefinedScript) {
                    let script = preDefinedScript.script;
                    const transformedData = await mergeDataTransformation(dataToBeMerged.finalDataToBeMerged, script, setCollectionState, setIsTestedData, setIsMergeDataPopupVisible, setMergeDataPopupMessage, setIsLoading, totalRowsCount);
                    finalResultTemplate.data.data = transformedData;
                }
            } 
            else if (testDataCollectionModal.selectedScriptType === AppEnum.MergeCollection.Custom_Script){
                const transformedData = await mergeDataTransformation(dataToBeMerged.finalDataToBeMerged, testDataCollectionModal.mergeScript, setCollectionState, setIsTestedData, setIsMergeDataPopupVisible, setMergeDataPopupMessage, setIsLoading, totalRowsCount);
                finalResultTemplate.data.data = transformedData;
            }
               
            else {
                const mergeQuery = testDataCollectionModal.restBody;
                const sqlJsOperationResult = await initializeSqlJs(collectionsData, dataToBeMerged, mergeQuery, setMergeDataPopupMessage, totalRowsCount);

                if (sqlJsOperationResult?.error) {
                    handleMergeError(sqlJsOperationResult.error, setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
                    return;
                }
                if (sqlJsOperationResult.length > 0) {

                    const { columns, values } = sqlJsOperationResult[0];

                    const formattedResult = values.map(row => {
                        const rowObject = {};
                        columns.forEach((columnName, index) => {
                            rowObject[columnName] = row[index]; // Map column names to row values
                        });
                        return rowObject;
                    });

                    finalResultTemplate.data.data = formattedResult;

                    await updateMergeState(true, setIsTestedData)

                    if (setCollectionState) {

                        setCollectionState((prevState) => {
                            return {
                                ...prevState,
                                testedDataList: finalResultTemplate.data.data,
                                testedDataColumnKeys: sqlJsOperationResult[0].columns,

                                isTestingConnection: false
                            }
                        });
                        setIsAuthorizeAgain(false);
                        dataAfterTransformation(finalResultTemplate.data.data, testDataCollectionModal.restDataTransformationScript, setCollectionState, true, testDataCollectionModal, preDefinedScripts);
                        await updateMergeState(false, setIsMergeDataPopupVisible);
                        await updateMergeState("clear", setMergeDataPopupMessage);
                    }
                } else {
                    handleMergeError(`No data found during the merging operation.`, setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
                }
            }
        }
        return finalResultTemplate;
    }
}


const initializeSqlJs = async (collectionsData, dataToBeMerged, mergeQuery, setMergeDataPopupMessage, totalRowsCount) => {
    try {
        await updateMergeState("Starting the merge query execution...", setMergeDataPopupMessage);

        const SQL = await initSqlJs({
            locateFile: (file) => {
                return '/sql-wasm.wasm';
            }
        });

        const db = new SQL.Database();

        await updateMergeState(`Executing the merge query... Total records fetched : ${totalRowsCount}.`, setMergeDataPopupMessage);

        for (const [collectionName, data] of Object.entries(dataToBeMerged.finalDataToBeMerged)) {
            const collectionDetails = collectionsData.allCollectionDetailsResults[collectionName];
            const collectionColumns = collectionDetails?.collectionColumns || [];

            const tableName = `[${collectionName}]`;
            createAndPopulateTempTable(db, data, tableName, collectionColumns);
        }

        const mergeResult = db.exec(mergeQuery);

        await updateMergeState("Merge query executed successfully. Preparing results...", setMergeDataPopupMessage);

        db.close();
        return mergeResult;

    } catch (error) {
        return { error: error.message };
    }
}

const createAndPopulateTempTable = (db, data, tableName, collectionColumns, onlyExecuteQuery = false) => {
    try {
        // Ensure there is data to process
        if ((!data || Object.keys(data).length === 0) && !onlyExecuteQuery) {
            throw new Error(`No data provided to create table ${tableName}.`);
        }

        // Define column definitions dynamically
        const columnDefinitions = collectionColumns
            .map((col) => `[${col.displayName}] ${getReactDataType(col.dataTypeName)}`)
            .join(", ");

        // Generate the CREATE TABLE statement dynamically
        const createTableQuery = `CREATE TEMP TABLE ${tableName} (${columnDefinitions});`;
        db.exec(createTableQuery);

        // If onlyExecuteQuery is true, we stop here
        if (onlyExecuteQuery) return;

        // Validate that all columns have the same number of rows
        const columnValues = Object.values(data);

        // Populate data into the table by row
        columnValues.forEach((row, rowIndex) => {
            const columns = collectionColumns.map((col) => `[${col.displayName}]`).join(", "); // Get column names
            const placeholders = collectionColumns.map(() => `?`).join(", "); // Create parameter placeholders
            const insertQuery = `INSERT INTO ${tableName} (${columns}) VALUES (${placeholders});`;

            const stmt = db.prepare(insertQuery);



            // Bind all values for the placeholders at once
            const values = collectionColumns.map((col) => {
                const columnName = col.columnName; // Use the original columnName

                return row[columnName] !== undefined ? row[columnName] : null; // Access value from the row object
            });

            stmt.bind(values); // Bind all values to the query
            stmt.step(); // Execute the insert command
            stmt.free(); // Free the statement
        });

    } catch (error) {
        console.error(`Error creating and populating table ${tableName}:`, error);
    }
};


function getReactDataType(columnType) {
    switch (columnType) {
        case "Number":
        case "Int32":
        case "Int16":
        case "Int64":
        case "Decimal":
        case "Double":
        case "Single":
        case "Scientific notation":
        case "Percentage":
            return "Number"; // React uses JavaScript's number type for most numeric types

        case "Currency":
            return "Number"; // Currency is treated as a number (e.g., float)

        case "Date":
        case "DateTime":
        case "DateTimeOffset":
        case "Time":
        case "TimeSpan":
            return "String"; // Dates are usually stored as ISO 8601 strings in JavaScript

        case "String":
        case "Guid":
        case "Dynamic List":
        case "List":
            return "String"; // String data or GUIDs

        case "Boolean":
            return "Boolean"; // React uses JavaScript's boolean type

        case "Byte":
            return "Number"; // Byte can be treated as a number (0-255)

        case "Byte[]":
        case "Object":
            return "Object"; // Binary data or serialized objects

        default:
            return "String"; // Default to String for unknown types
    }
}

const dataAfterTransformation = (testedDataList, script, setCollectionState, isColumnsKeyRequired, collectionDetails, preDefinedScripts) => {
    let errorMessage = "";
    let transformedData = null;
    let testedDataTransformedColumnsKeys = null;

    if (testedDataList) {
        try {
            const dataTransformation = eval(`(${script})`);
            transformedData = dataTransformation(testedDataList);
        } catch (error) {
            return;
        }

        //cloud tranformation Script
        if (collectionDetails?.cloudDataTransformationScript || collectionDetails?.dataTransformationScriptId) {
            let cloudScript = collectionDetails?.dataTransformationScriptId ? getPredefinedScript(preDefinedScripts, collectionDetails?.dataTransformationScriptId) : collectionDetails?.cloudDataTransformationScript
            try {
                const dataCloudTransformation = eval(`(${cloudScript})`);
                const dataToTest = _.cloneDeep(transformedData);
                transformedData = dataCloudTransformation(dataToTest);
            } catch (error) {
                return;
            }
        }

        if (typeof transformedData === "undefined") {
            return;
        }

        let maxPropsObject = {};
        let maxPropsCount = 0;

        testedDataList.forEach((obj) => {
            const numProps = Object.keys(obj).length;
            if (numProps > maxPropsCount) {
                maxPropsCount = numProps;
                maxPropsObject = obj;
            }
        });

        if (setCollectionState) {
            testedDataTransformedColumnsKeys = transformedData[0];
            testedDataTransformedColumnsKeys = (_.keys(testedDataTransformedColumnsKeys));

            if (isColumnsKeyRequired){
                setCollectionState((prevState) => {
                    return {
                        ...prevState,
                        testedDataTransformedList: transformedData,
                        testedDataTransformedColumneKeys: testedDataTransformedColumnsKeys
                    }
                });
            }
            else {
                setCollectionState((prevState) => {
                    return {
                        ...prevState,
                        testedDataTransformedList: transformedData,
                        // testedDataTransformedColumneKeys: testedDataTransformedColumnsKeys
                    }
                });
            }

        }

        return transformedData;
    }
};

const updateMergeState = async (value, setMergeState) => {
    if (setMergeState) {
        setMergeState((prevState) => {
            if (value === "clear") {
                return [];
            }
            else if (Array.isArray(prevState)) {
                return [...prevState, value];
            } else {
                return value;
            }
        });
    }
    await new Promise((resolve) => setTimeout(resolve, 0));
}

const handleResponseValidation = ({
    response, isPopup, setCollectionState, collectionName, details, setMergedCollectionsData}) => {

    let errorMessage = '';

    if (response?.hasError) {
        errorMessage = response?.errorMessage;
        return errorMessage;
    }
    else if (response?.data?.errorMessage && response?.data?.data == null) {
        errorMessage = response?.data?.errorMessage;
        return errorMessage;
    }
    else if (response?.data?.data == null && !response?.hasError && !response?.data?.errorMessage) {
        errorMessage = `The collection [${collectionName}] was tested successfully but data is not available for the given parameters.`;
        return errorMessage;
    }
    if (response?.data?.statusCode === "Unauthorized" || response?.data?.httpStatus === 401) {
        setMergedCollectionsData(prevData =>
            prevData?.map(i =>
                i.uId === details.uId
                    ? { ...i, connectionUId: details?.connection?.uId, isConnectionAuthorize: false }
                    : i
            )
        );
        toast.error(`Your connection has been expired for collection [${collectionName}]. Please establish the connection again`,
            {
                // onClick: handleToastClick,
                closeOnClick: true
            }
        );

        errorMessage = "Couldn't establish a secure connection!";
        return errorMessage;
    }
    if ((response?.data?.statusCode === "Forbidden" || response?.data?.httpStatus === 403) && !isPopup) {
        errorMessage = response?.data?.errorMessage;
        return errorMessage;
    }
    if (!isPopup && response?.data?.statusCode !== "OK" && response?.data?.httpStatus !== 200 && (response?.data?.data?.errorMessage || response?.data?.errorMessage)) {
        errorMessage = response?.data?.data?.errorMessage || response?.data?.errorMessage;
        setCollectionState((prevState) => {
            return { ...prevState, testCollectionError: errorMessage };
        });
        return errorMessage;
    }   
    if (response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0) {
        errorMessage = `The collection [${collectionName}] was tested successfully but data is not available for the given parameters.`;
        return errorMessage;
    }
}

const getFilterData = (filterData) => {
    if (!Array.isArray(filterData)) {
        return [];
    }
    const filterGroups = filterData?.map(group => {
            const filters = group?.filters.map(item => ({
                field: item.field,
                value: item.value,
                operator: item.operator,
                condition: item.condition,
                displayName: item.displayName,
                highValue: item.highValue,
                values: item.values,
                dateLabel: item.dateLabel,
                dataTypeName: item.dataTypeName,
                valueLabel: item.valueLabel,
                dateLabelValue: item.dateLabelValue,
                conditionLabel: item.conditionLabel,
                isMultiSelect: item.isMultiSelect,
            }));
            return { filters: filters, operator: group?.operator };
        });
    
        if (!filterGroups || filterGroups.length === 0) {
            return [];
        }
    
        return [{ filterGroups: filterGroups }];

};

const mergeDataTransformation = async (finalDataToBeMerged, script, setCollectionState, setIsTestedData, setIsMergeDataPopupVisible, setMergeDataPopupMessage, setIsLoading, totalRowsCount) => {

    await updateMergeState("Starting the merge query execution...", setMergeDataPopupMessage);
    await updateMergeState(`Executing the merge query... Total records fetched : ${totalRowsCount}.`, setMergeDataPopupMessage);
 
    let transformedData = null;
    let transformedColumnKeys = null;

    if (finalDataToBeMerged) {
        try {
            const dataTransformation = eval(`(${script})`);
            transformedData = dataTransformation(...Object.values(finalDataToBeMerged));
        } catch (error) {
            handleMergeError(error.message, setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
            return;
        }

        if (typeof transformedData === "undefined") {
            handleMergeError('The above Data Transformation Script function did not return anything.', setMergeDataPopupMessage, setIsMergeDataPopupVisible, setCollectionState, setIsLoading);
            return;
        }
        
        await updateMergeState(true, setIsTestedData);
        
        if (Array.isArray(transformedData) && transformedData.length > 0) {
            transformedColumnKeys = Object.keys(transformedData[0]);
        }

        if (setCollectionState) {
            setCollectionState((prevState) => ({
                ...prevState,
                testedDataList: transformedData,
                testedDataColumnKeys: transformedColumnKeys,

                isTestingConnection: false,
            }));
        }
        await updateMergeState("clear", setMergeDataPopupMessage);
        await updateMergeState(false, setIsMergeDataPopupVisible);

        return transformedData;
    }
}

const handleMergeError = async (
    errorMessage,
    setMergeDataPopupMessage,
    setIsMergeDataPopupVisible,
    setCollectionState,
    setIsLoading
) => {
    await updateMergeState("clear", setMergeDataPopupMessage);
    await updateMergeState(false, setIsMergeDataPopupVisible);
    await updateMergeState(true, setIsMergeDataPopupVisible);
    await updateMergeState("Some error occurred while executing merge script", setMergeDataPopupMessage);
    await updateMergeState(errorMessage, setMergeDataPopupMessage);
    await updateMergeState(false, setIsLoading);

    if (setCollectionState) {
        setCollectionState((prevState) => ({
            ...prevState,
            testedDataList: [],
            testedDataColumnKeys: [],
            isTestingConnection: false,
        }));
    }
};

const getPredefinedScript = (preDefinedScripts, id) => {
    let preDefinedScript = preDefinedScripts?.find((script => script.id === id));
    if (preDefinedScript) {
        return preDefinedScript.script;
    }
}