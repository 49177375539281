import React from "react";
import { ASButton } from "../../../../shared/as-button/as-button";
import "../filterPopup/filterPopup.scss";
import { ASTextField } from "../../../../shared/as-text-fields";
import InputLabel from "@mui/material/InputLabel";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
    Autocomplete,
    MenuItem,
    Tooltip,
    styled,
} from "@mui/material";
import {
    conditionarr,
    sortArr,
} from "../../../../controllers/data-collections/column-selection/filter-popup/filter-sort-array";
import "./sortPopup.scss"
import AddSortForm from "./add-sort-form/addSortForm";

export default function SortPopup(props) {
    const {
        openAddCondition,
        filterObject,
        filterData,
        handleOnChnageFilterInput,
        addCondition,
        deleteCondition,
        addNewCondition,
        setAddNewCondition,
        closeFilterModal,
        columnsList,
        executeData,
        isSort,
        sortData,
        setOpenConditional,
        openConditional,
        isDisabled,
        handleAddCondtionCancel,
        currentGroupIndex,
        filterConfig,
        state,
        handleSortEditClick,
        editSortIndex,
        handleEditSortCondition,
        handleCancelEditFilter
    } = props;

    var filterFields = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state.templateCollectionId)?.areAllFieldsSortable
        ? columnsList?.filter((i) => i.isSelected)
        : columnsList?.filter((i) => i.isSortable && i.isSelected);

    var data = isSort ? sortData : filterData;
    var conditionOrArr = isSort ? sortArr : conditionarr;

    const flattenedData = data?.flat();
    const totalCount = flattenedData?.length;


    const customMenuItem = styled(MenuItem)({
        fontSize: '13px',
    });

    return (
        <>
            {(openConditional || data?.length != 0) && (
                <div className="add-filter-modal">
                    <div
                        className="filter-item-container"
                        style={{ overflowY: totalCount >= 3 ? "scroll" : "unset" }}
                    >
                        {openAddCondition && (
                            <div className="filter-paper-div">
                                {data?.map((item, index) => {
                                    if (editSortIndex === index) {
                                        return (
                                            <AddSortForm
                                                filterObject={filterObject}
                                                filterFields={filterFields}
                                                handleOnChnageFilterInput={handleOnChnageFilterInput}
                                                isSort={isSort}
                                                conditionOrArr={conditionOrArr}
                                                handleAddCondtionCancel={handleAddCondtionCancel}
                                                addCondition={handleEditSortCondition}
                                                isDisabled={isDisabled}
                                            />
                                        );
                                    }

                                    return (
                                        <div>
                                            <div className="fields-group-label" onClick={() => handleSortEditClick(index)}>
                                                <div className="sort-field-label-div">
                                                    <InputLabel>Field</InputLabel>
                                                    <label className="fields-group-label-value">
                                                        {item?.displayName}
                                                    </label>
                                                </div>
                                                <div className="sort-field-label-div">
                                                    <InputLabel>Order By</InputLabel>
                                                    <label className="fields-group-label-value">
                                                        {isSort
                                                            ? sortArr.filter(
                                                                (i) => i?.value == item?.value
                                                            )[0].name
                                                            : conditionarr.filter(
                                                                (i) => i?.value == item?.condition
                                                            )[0].name}
                                                    </label>
                                                </div>
                                                <div className="delete-icon-div">
                                                    <DeleteForeverOutlinedIcon
                                                        onClick={(e) => {e.stopPropagation(); deleteCondition(index)}}
                                                    />
                                                </div>
                                            </div>

                                            {index === data?.length - 1 && (
                                                <Tooltip
                                                    title={data?.length >= filterConfig?.numberOfSortAllowed
                                                        ? filterConfig?.SortExceeded
                                                            ? filterConfig?.SortExceeded
                                                            : `You cannot add more than ${filterConfig.numberOfSortAllowed} sort filters`
                                                        : ""}
                                                    disableHoverListener={data?.length < filterConfig.numberOfSortAllowed}
                                                    arrow
                                                >
                                                    <span>
                                                        <button
                                                            className="cancel-link-button"
                                                            onClick={() => {
                                                                setAddNewCondition(true);
                                                                handleCancelEditFilter();
                                                            }}
                                                            disabled={data?.length >= filterConfig?.numberOfSortAllowed}
                                                        >
                                                            + Add another sorting
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                })}

                                {(addNewCondition || data?.length == 0) &&
                                    currentGroupIndex == null && (
                                        <AddSortForm
                                            filterObject={filterObject}
                                            filterFields={filterFields}
                                            handleOnChnageFilterInput={handleOnChnageFilterInput}
                                            isSort={isSort}
                                            conditionOrArr={conditionOrArr}
                                            handleAddCondtionCancel={handleAddCondtionCancel}
                                            addCondition={addCondition}
                                            isDisabled={isDisabled}
                                        />
                                    )}
                            </div>
                        )}

                        {/* add form */}

                    </div>

                    {data?.length > 0 && !addNewCondition && (
                        <div className="apply-changes-div" style={{ justifyContent: "flex-end" }}>
                            <div className="cancel-add-button-div">
                                <div className="cancel-button-div">
                                    <button
                                        className="cancel-link-button"
                                        onClick={() => closeFilterModal()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <ASButton
                                        variant="contained"
                                        sx={{
                                            mr: 1,
                                            marginRight: "0px",
                                            textTransform: "none",
                                            borderRadius: "7px !important",
                                        }}
                                        primary={true}
                                        onClick={() => {
                                            closeFilterModal();
                                            executeData();
                                        }}
                                    >
                                        Apply Changes
                                    </ASButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
