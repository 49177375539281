import { axios } from "../core/axios.interceptor";

const baseURL = "collections";

export async function getRefreshScheduleDetails(uId) {
    const response = await axios.get(`${baseURL}/refresh-schedule/${uId}`);
    return response;
}

export async function addUpdateRefreshSchedule(uId, refreshSchedule) {
    const response = await axios.post(`${baseURL}/refresh-schedule?collectionUId=${uId}&refreshSchedule=${refreshSchedule}`);
    return response;
}

export async function checkGoogleSheetFileExists(fileURL) {
    const response = await axios.post(`${baseURL}/refresh-schedule/check-connection?fileURL=${fileURL}`);
    return response;
}
