import React from 'react';
import "./merge-collection-list.scss";
import { ASButton } from '../../../../shared/as-button/as-button';

export default function MergeCollectionList(props) {
    const { state, mergedCollectionsData, openDataVisualizer, handleAuthorizeAgain } = props;
    return (
        <div className='column-selection-list-container'>
            <div className='column-selection-list-name'>
                <span className='column-selection-header'>Select Collection to Edit</span>
            </div>

            <div className='column-list-wrapper'>
                <div className='column-list-view'>
                    {
                        mergedCollectionsData?.map((item, index) => (
                            <div
                                key={index}
                                className='column-list-item'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    openDataVisualizer(event, item)
                                }}
                            >
                                <img
                                    src={`/assets/${item?.connectionIconImage}`}
                                    alt={`${item?.collectionName} icon`}
                                    className="connection-icon"
                                />
                                {item?.collectionName}
                                {item?.isConnectionAuthorize === false ?
                                    <div className='authorize-button'>
                                        <ASButton variant="contained"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleAuthorizeAgain(item?.connectionUId)
                                            }}
                                            primary={true}
                                            size="small"
                                            disabled={state?.isTestingConnection}
                                            sx={{ padding: "2px 10px" }}>
                                            Authorize
                                        </ASButton>
                                    </div> : ""}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
