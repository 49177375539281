import { CollectionStepLabelConstant } from '../../../../constants/as-stepper-step-label'
import SelectConnectionController from '../select-connection/select-connection-controller'
import MergeCollectionParametersController from './merge-collection-parameters-controller'
import MergeQueryController from './merge-query-controller'
import { AppEnum } from '../../../../constants/app-enum'
import SelectCollectionController from './select-collections-controller'
import BasicInformationController from '../basic-information/basic-information-controller'
import { useEffect, useMemo } from 'react'

const MergeCollectionSteps = (props) => {

    const { resetErrorMessages, state, setState, prevStepRef, getCollectionTypeList, setSelectedConnection, setParametersState, selectedConnectionSubmitted,
        selectedConnection, isTestingConnection, mergeCollectionState, commonRestSteps, setMergeCollectionState, handleStepDataChange, preDefinedScripts, setPreDefinedScripts,
        collectionType, setCollectionType, getCollectionDetails, submittedBasicInfo, initialCollectionName, isSavingCollection,
        setIsDialogOpen, isDialogOpen, isBasicInfoSubmitted, addNewDataCollection, setActiveStep, activeStep, collectionTypeTemplate } = props;    

    
    const isDisabled = useMemo(() => {
        if (!collectionTypeTemplate) return false;
        if(state?.collectionsNameToMerge?.length){
            const uIdsToMerge = state?.collectionsNameToMerge?.map(i => i.uId)?.filter(uId => uId != null);
            return (uIdsToMerge?.length !== state?.collectionUIdsToMerge?.length) || (state?.childCollectionUId === "");
        }
        return true;
    }, [collectionTypeTemplate, state?.collectionsNameToMerge, state?.childCollectionUId]);

    useEffect(()=>{
        if(collectionTypeTemplate){
            setIsDialogOpen(true);
        }
    },[activeStep])

    const handleCollectionTypeChange = (event) => {
        let collectionTypeTemplate = event.target.value;
        setCollectionType(collectionTypeTemplate);
        if(collectionTypeTemplate === AppEnum.CollectionType.FromScratch && state?.hasMergeCollections){
            setMergeCollectionState((prevState) => { return { ...prevState, selectedObjectCollections: [], } });
            setState((prevState) => { return { ...prevState, collectionUIdsToMerge: [], childCollectionUId: "" } });            
        }
        else{
            setState((prevState) => { return { ...prevState,
                childCollectionUId: "", 
                collectionsNameToMerge: {},
                collectionParameters : [],
                collectionColumns: [],
                combineMergedParameters : false } });
        }
    };
    
    if (!state.hasMergeCollections) {
        return []
    }   

    return [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            isTestingConnection: isTestingConnection,
            optionalComponent: { labels: selectedConnectionSubmitted, },
            isCollectionType: true,
            disabled: !state.hasMergeCollections
        },
        {
            label: CollectionStepLabelConstant.Select_Objects,
            description: (
                <SelectCollectionController
                    prevStepRef={prevStepRef}
                    state={state}
                    collectionType={collectionType}
                    collectionTypeTemplate={collectionTypeTemplate}
                    handleCollectionTypeChange={handleCollectionTypeChange}
                    getCollectionDetails={getCollectionDetails}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    setMergeCollectionState={setMergeCollectionState}
                    mergeCollectionState={mergeCollectionState}
                />
            ),
            isTestingConnection: isTestingConnection,
            disabledBack: false,
            disabled: collectionTypeTemplate ? isDisabled : state?.collectionUIdsToMerge?.length === 0,
        },
        ...(
            collectionTypeTemplate
                ? [
                    {
                        label: CollectionStepLabelConstant.Basic_Information,
                        description: (
                            <BasicInformationController
                                saveCollection={addNewDataCollection}
                                isDialogOpen={isDialogOpen}
                                setIsDialogOpen={setIsDialogOpen}
                                setActiveStep={setActiveStep}
                                collectionType={collectionType}
                                activeStep={activeStep}
                                resetErrorMessages={resetErrorMessages}
                                state={state}
                                isSavingCollection={isSavingCollection}
                                setState={setState}
                                prevStepRef={prevStepRef}
                                getCollectionTypeList={getCollectionTypeList}
                                setSelectedConnection={setSelectedConnection}
                                initialCollectionName={initialCollectionName}
                                isCloudStep={true}
                                selectedConnection={selectedConnection}
                                isBasicInfoSubmitted={isBasicInfoSubmitted}
                            />
                        ),
                        isBasicInfo: true,
                        optionalComponent: { labels: state?.name ? submittedBasicInfo : null },
                        isCollectionType: state?.name ? false : true,
                        isCollectionAsDataSource: state.isCollectionAsDataSource,
                        disabled: false,
                    }
                ]
                : [
                    {
                        label: CollectionStepLabelConstant.Merge_Query,
                        description: (
                            <MergeQueryController
                                state={state}
                                setState={setState}
                                prevStepRef={prevStepRef}
                                showSaveChangesButton={handleStepDataChange}
                                preDefinedScripts={preDefinedScripts}
                                setPreDefinedScripts={setPreDefinedScripts}
                            />
                        ),
                        isTestingConnection: isTestingConnection,
                        optionalComponent: { labels: state?.restBody, isMergeQueryStep: true },
                        disabledBack: false,
                        disabled: !state?.restBody && !state?.mergeScript && !state.dataTransformationScriptId
                    },
                    {
                        label: CollectionStepLabelConstant.Parameters,
                        description: (
                            <MergeCollectionParametersController
                                state={state}
                                setState={setState}
                                prevStepRef={prevStepRef}
                            />
                        ),
                        isTestingConnection: isTestingConnection,
                        isParameterSubmitted: !!state.collectionParameters.length,
                        optionalComponent: { collectionParameters: state.collectionParameters, showSendAs: state.sysCollectionTypeId == AppEnum.SysCollectionTypeId.REST },
                        disabledBack: false,
                        disabled: false
                    },
                    ...commonRestSteps
                ]
        ),        
    ]

}

export default MergeCollectionSteps