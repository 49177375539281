import React, { useCallback, useMemo } from 'react'
import ASPaper from '../../../shared/misc/as-mui-paper';
import { Checkbox, FormControlLabel, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { parameterTableHead } from '../collection-parameters/collection-parameters';
import ParametersTableRow from '../collection-parameters/parameters-table-row';
import { muiCheckboxStyle } from '../../user-permission/user-permission';

const MergeCollectionParameters = (props) => {

    const { collectionsParameters, isDataLoading, prevStepRef, state, handleCombineParameterCheckbox } = props;
    
    const hasSendAs = useMemo(() => {
        return collectionsParameters.some(p => p.sendAs);
    }, [collectionsParameters])


    const tableHeadColumns = useCallback(() => {
        return parameterTableHead(true, hasSendAs)
    }, [hasSendAs]);


    return (
        <ASPaper
            ref={prevStepRef}
            isDataLoading={isDataLoading}
            className={'min-h-auto'}
        >

            <TableContainer>
                <Table
                    sx={{ minWidth: 750, }} 
                    className='coll-parameter-table parameter-td'
                >
                    {tableHeadColumns()}

                    <TableBody   >
                        {
                            collectionsParameters.map((parameter, index) => {
                                return <TableRow >
                                    <ParametersTableRow
                                        index={index}
                                        isAddNewParameter={false}
                                        isEditMode={false}
                                        collectionParametersCount={collectionsParameters?.length}
                                        ele={parameter}
                                        isReadOnly={true}
                                        showSendAs={hasSendAs}
                                    />
                                </TableRow>

                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={state?.combineMergedParameters}
                        onChange={handleCombineParameterCheckbox}
                        color="primary"
                        sx={muiCheckboxStyle}
                    />
                }
                label="Combine Merged Parameters"
            />

        </ASPaper >
    )
}

export default MergeCollectionParameters