import { FormControl, FormControlLabel, FormLabel, ListSubheader, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import ASSqlQueryEditor from '../../../shared/as-inputs/as-sql-editor';
import ASPaper from '../../../shared/misc/as-mui-paper';
import { useMemo, useState } from 'react';
import { muiCheckboxStyle } from '../../user-permission/user-permission';
import ExpandableView from '../../common/expandableView/expandableView';
import themeColor from "../../../styles/_exports.module.scss";
import AceEditor from "react-ace";
import { useSelector } from 'react-redux';
import { ASTextField } from '../../../shared/as-text-fields';
import { AppEnum } from '../../../constants/app-enum';
import './merge-collections.scss';
import { truncateString } from '../../../shared/code-challanges/code-challanges';
import { ASButton } from '../../../shared/as-button/as-button';
import GptPromptController from '../../common/gpt-prompt-popup/gpt-prompt-controlller';

const MergeQuery = (props) => {
    const { onChangeSqlEditorHandler, sqlEditorValue, mergeSriptValue, dataTransformationScriptIdValue, handleMergeEditor, handleRadioChange, selectedScriptType, 
            predDeifnedScripts, handlePreDefinedScriptChange, groupedPreDefinedScrips, prevStepRef, selectedCollectionsData, state, setState, language } = props;

    const isExpandedView = useSelector((state) => state.asState.isExpandedView);
    const [isGptPopupOpen, setIsGptPopupOpen] = useState(false);

    const groupedScripts = useMemo(() => {

        const asTenantUId = sessionStorage.getItem("ASTenantId") || localStorage.getItem("ASTenantId");
        
        return predDeifnedScripts?.reduce(
            (acc, script) => {
                if (script.applicableToCD === AppEnum.DataScriptApplicable.MERGE_DATA_COLLECTIONS && script.scriptTypeCD === AppEnum.DataScriptApplicable.RESPONSE_TRANSFORMATION) {
                    if (script.asTenantUId != asTenantUId) {
                        acc.templateScripts.push(script);
                    } else {
                        acc.ownedScripts.push(script);
                    }
                }
                return acc;
            },
            { templateScripts: [], ownedScripts: [] }
        );
    }, [predDeifnedScripts]);

    return (
        <ASPaper
            ref={prevStepRef}
            isDataLoading={false}
            className={'merge-collection-paper'}
        >
            {/* Radio Buttons to Select Query Type */}
            <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
                <FormLabel component="legend" style={{ color: `#707070` }}>Select Script Type</FormLabel>
                <RadioGroup
                    row
                    value={selectedScriptType}
                    onChange={handleRadioChange}
                    sx={{
                        "&.MuiFormGroup-root": {
                            width: "200%"
                        }
                    }}
                >
                    <FormControlLabel
                        value={AppEnum.MergeCollection.SQL}
                        control={<Radio sx={muiCheckboxStyle} />}
                        label="SQL"
                    />
                    <FormControlLabel
                        value={AppEnum.MergeCollection.Predefined_Script}
                        control={<Radio sx={muiCheckboxStyle} />}
                        sx={{
                            "&.MuiFormControlLabel-root": {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              maxWidth: "350px",
                            },
                            "& .MuiTypography-root": {
                              flexGrow: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            },
                          }}
                        
                        label={
                            <ASTextField
                                size="small"
                                className="text-field1 label-text-field"
                                type={"text"}
                                sx={{ fontSize: "12px" }}
                                fullWidth
                                label={"Select from predefined scripts"}
                                variant="outlined"
                                select={true}
                                disabled={selectedScriptType !== "preDefine"}
                                value={dataTransformationScriptIdValue}
                                onChange={handlePreDefinedScriptChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: "menu-role-div",
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 250,
                                                overflowY: "auto"
                                            }
                                        },
                                        renderValue: (selected) => {
                                            const selectedScript = groupedScripts?.templateScripts.find(script => script.id === selected) ||
                                                                   groupedScripts?.ownedScripts.find(script => script.id === selected);
                                            return selectedScript ? truncateString(selectedScript.name, 28) : "";
                                        }
                                    },
                                }}
                            >
                               <ListSubheader className='merge-predefine-title'>Template Scripts</ListSubheader>
                                {groupedScripts?.templateScripts.map((script) => (
                                    <MenuItem className='merge-predefine-menu' key={script.id} value={script.id}>
                                        {truncateString(script.name, 40)}
                                    </MenuItem>
                                ))}
                       
                                <ListSubheader className='merge-predefine-title'>Owned Scripts</ListSubheader>
                                {groupedScripts?.ownedScripts.map((script) => (
                                    <MenuItem className='merge-predefine-menu' key={script.id} value={script.id}>
                                        {truncateString(script.name, 40)}
                                    </MenuItem>
                                ))}
                            </ASTextField>
                        }

                    />
                    <FormControlLabel
                        value={AppEnum.MergeCollection.Custom_Script}
                        control={<Radio sx={muiCheckboxStyle} />}
                        label="Custom Transformation Script"
                    />
                </RadioGroup>
            </FormControl>

            {selectedScriptType === AppEnum.MergeCollection.SQL ? (
                <ASSqlQueryEditor
                isExpandedView={isExpandedView}
                onChange={onChangeSqlEditorHandler}
                value={sqlEditorValue}
            />
            ) : (
                <ExpandableView>
                    <AceEditor
                        placeholder="Enter your script here"
                        height={isExpandedView ? "calc(-140px + 100vh)" : "calc(100vh - 520px)"}
                        width="100%"
                        mode="javascript"
                        name="scriptEditor"
                        onLoad={() => {}}
                        onChange={(e) => handleMergeEditor(e)}
                        fontSize={16}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={selectedScriptType === "preDefine" ? "//Read Only..." : mergeSriptValue}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        readOnly={selectedScriptType === "preDefine"}
                    />
                </ExpandableView>
            )}

            {selectedScriptType !== AppEnum.MergeCollection.Predefined_Script && (
                <ASButton
                    primary={true}
                    variant="contained"
                    onClick={() => setIsGptPopupOpen(true)}
                >
                   <img 
                        src={`/assets/gen-ai.svg`} 
                        style={{ marginRight: "8px" }} 
                        alt="Magic Icon"
                    />
                    Generate Merge Script Using Gen AI
                </ASButton>
             )}

            {isGptPopupOpen  && (
                <GptPromptController
                    state={state}
                    setState={setState}
                    selectedCollectionsData={selectedCollectionsData}
                    handleMergeEditor={handleMergeEditor}
                    onChangeSqlEditorHandler={onChangeSqlEditorHandler}
                    isGptPopupOpen={isGptPopupOpen}
                    setIsGptPopupOpen={setIsGptPopupOpen}
                    language={language}
                />
             )}
        </ASPaper>
    );
};

export default MergeQuery;
