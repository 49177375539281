import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material";
import "./as-tooltip.scss";
import { styled } from "@mui/material/styles";
import styles from "../../styles/_exports.module.scss";

const ASToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: styles.bodyBackground,
        color: styles.avatarBorderBlue,
        fontSize: theme.typography.pxToRem(14),
        border: `1px solid ${styles.text_2}`,
    },

}));


export const ASTooltip = ({ text, className }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <ASToolTip title={text} placement="top" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <svg
                className={ className || "tootip-img" }
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 256 256"
                width= {className ? "16px" : "21px"}
                height={className ? "16px" : "21px"}
            >
                <g fill={isHovered ? "#000000" : "#CBCACA"}>
                    <g transform="scale(2)">
                        <path d="M64,6c-32,0 -58,26 -58,58c0,32 26,58 58,58c32,0 58,-26 58,-58c0,-32 -26,-58 -58,-58zM64,12c28.7,0 52,23.3 52,52c0,28.7 -23.3,52 -52,52c-28.7,0 -52,-23.3 -52,-52c0,-28.7 23.3,-52 52,-52zM64,30c-4.97056,0 -9,4.02944 -9,9c0,4.97056 4.02944,9 9,9c4.97056,0 9,-4.02944 9,-9c0,-4.97056 -4.02944,-9 -9,-9zM64,59c-5,0 -9,4 -9,9v24c0,5 4,9 9,9c5,0 9,-4 9,-9v-24c0,-5 -4,-9 -9,-9z"></path>
                    </g>
                </g>
            </svg>
        </ASToolTip>
    );
};