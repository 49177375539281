import { useMemo, useState } from "react";
import PromptPopup from "./prompt-popup";
import { AppEnum } from "../../../constants/app-enum";
import { getOpenApiResponse } from "../../../services/openApi.service";
import { toast } from "react-toastify";


const GptPromptController = (props) => {
    const {selectedCollectionsData, state, setState, language, handleMergeEditor, onChangeSqlEditorHandler, isGptPopupOpen, setIsGptPopupOpen} = props;
    const [activeView, setActiveView] = useState("prompt");
    const [isResponseGettingGenerated, setIsResponseGettingGenerated] = useState(false);
    const [activeTab, setActiveTab] = useState(3);

    const systemRequirement = useMemo(() => {
        return `You are a highly specialized ${language.toUpperCase()} script generator for merging data. 
                Your primary objective is to generate **accurate, efficient, and reliable merge scripts** strictly based on user input.
                1. Use **SELECT** statements with consistent formatting.
                2. Utilize **JOIN** clauses to merge data from multiple tables with appropriate aliases.
                4. Do not include code block annotations or language tags.
                5. Do not add any explanations, comments, or extra text.
                9.**DO NOT** use **RIGHT JOIN** or **FULL OUTER JOIN** unless explicitly requested.
                This instruction is **MANDATORY** — do not explain or add comments or text under any circumstances.`;
      }, [language]);


    const modifiedSelectedCollectionsData = useMemo(() => {
        if (!selectedCollectionsData) return {};
        
        return Object.keys(selectedCollectionsData).reduce((acc, collectionKey) => {
          const columns = selectedCollectionsData[collectionKey];
          acc[collectionKey] = columns.map(({ columnName, displayName, displayFormat, dataTypeName }) => ({
            columnName,
            displayName,
            displayFormat,
            dataTypeName
          }));
          return acc;
        }, {});
      }, [selectedCollectionsData]);
      

    const toggleGptPopup = () => {
        setIsGptPopupOpen(!isGptPopupOpen);
    }

    const handlePrompt = (value) => {
        setState((prevState) => { return { ...prevState, gptPrompt: value } });
    }

    const handleGptResponse = (value) => {
        setState((prevState) => { return { ...prevState, gptResponse: value}})
    }

    const formatName = (name) => name?.includes(" ") ? `[${name}]` : name;

    const handleGenerateMergeScript = async () => {
        if (!state?.gptPrompt?.trim()) return;

        setIsResponseGettingGenerated(true);
        setActiveTab(4);

        const requestContent = buildRequestContent(modifiedSelectedCollectionsData, language, state?.gptPrompt);
            
        try {
            const requestPayload = {
                requestContent: requestContent,
                language: language,
                systemInstructions: systemRequirement
            };
        
            const response = await getOpenApiResponse(requestPayload);
        
            if (response && response.status === 200) {
                let data = response.data;
        
                if (typeof data === "string") {
                    try {
                        data = JSON.parse(data);
                    } catch (parseError) {
                        toast.error("Invalid API response format.");
                    }
                }
        
                if (data?.choices?.length > 0 && data.choices[0]?.message?.content) {
                    handleGptResponse(data.choices[0].message.content);
                } else {
                    toast.warning("Unexpected API response structure.");
                }
            } else {
                toast.error("Failed to generate merge script. Please try again later.");
            }
        } catch (error) {
            toast.error("An error occurred while generating the merge script. Please try again later.");
        } finally {
            setIsResponseGettingGenerated(false);
        }
        
    };

    const buildRequestContent = (collectionsData, language, prompt) => {
        var jsonCollectionsData = JSON.stringify({ ...collectionsData }, null, 2);
        
        const collectionsString = Object.entries(collectionsData)
        .map(([table, columns]) => {
            const tableName = formatName(table);
            const columnKey = language?.toLowerCase() === "javascript" ? "columnNames" : "displayName";
            const columnNames = columns
                .map(col => `${tableName}.${formatName(col[columnKey])}`)
                .join(", ");
            return `${tableName} (${columnNames})`;
        })
        .join(", ");

        if (language.toLowerCase() === "javascript") {
            var collectionKeys = Object.keys(collectionsData);
            var numCollections = collectionKeys.length;
    
            let paramDescription = "";
            if (numCollections === 1) {
                paramDescription += `collectionData1 represents ${collectionKeys[0]}`;
            }
            if (numCollections > 1) {
                paramDescription += `collectionData1 represents ${collectionKeys[0]}`; // Add first collection
                for (let i = 2; i <= numCollections && i <= 10; i++) {
                    paramDescription += `, collectionData${i} represents ${collectionKeys[i - 1]}`;
                }
            }
            paramDescription += ".";
    
            return `Generate a JavaScript script to merge the following collections: ${collectionsString}. ${prompt}. Use the following function signature and implement the merging logic inside it, using only the relevant collectionData parameters based on the number of collections provided (${numCollections} collection(s)): \n\n` +
                    `${AppEnum.MergeCollection.Merge_Script_Default_Value}` +
                    `  Implement the merge logic here based on the collections and prompt. ${paramDescription}
                        The collections schema is in ${jsonCollectionsData}\n` +
                    `  return mergedData;\n}`;
        } else {
            return `Generate a ${language.toUpperCase()} query to merge the following collections: ${collectionsString}. ${prompt} \n The collections schema is in ${jsonCollectionsData}\n`;
        }
    };

    const handleApplyMergeScript = () => {
        if (language === AppEnum.MergeCollection.SQL) {
          onChangeSqlEditorHandler(state?.gptResponse);
        } else {
          handleMergeEditor(state?.gptResponse);
        }
        setIsGptPopupOpen(false);
      };
      
    const handleCopyPrompt = () => {
        var textToCopy = activeTab === 3 ? state?.gptPrompt : activeTab === 4 ? state?.gptResponse : '';
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
        })
        .catch((error) => {
        });
    };

    return (
         <PromptPopup
            open={isGptPopupOpen}
            handleClose={toggleGptPopup}
            gptPrompt={state?.gptPrompt}
            setPrompt={handlePrompt}
            handleGenerateMergeScript={handleGenerateMergeScript}
            isResponseGettingGenerated={isResponseGettingGenerated}
            selectedCollectionsData={modifiedSelectedCollectionsData}
            activeView={activeView}
            setActiveView={setActiveView}
            handleApplyMergeScript={handleApplyMergeScript}
            gptResponse={state?.gptResponse}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleGptResponse={handleGptResponse}
            handleCopyPrompt={handleCopyPrompt}
         />
    )
}

export default GptPromptController;