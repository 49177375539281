import React, { useEffect, useState } from 'react'
import FilterPopup from '../../../../components/data-collections/column-selection-popup/filterPopup/filterPopup'
import themeColor from "./../../../../styles/_exports.module.scss";
import { isNullOrEmpty } from '../../../../services/data-source-validation';
import SortPopup from '../../../../components/data-collections/column-selection-popup/sortPopup/sortPopup';
import * as UserService from "../../../../services/variable-service";
import { getCollectionDetails } from '../../../../services/data-collection.service';
import { toast } from 'react-toastify';
import LimitPopup from '../../../../components/data-collections/column-selection-popup/limitPopup/limitPopup';
import { testData } from '../../../../services/test-data.service';
import { DateDataTypes } from '../../../../components/data-collections/column-selection-popup/filterPopup/date-filter/date-filter';
import { AppEnum } from '../../../../constants/app-enum';
import { FilterObjectState } from './filter-state';

export default function FilterPopupController(props) {

    const { open, closeFilterModal, filterData, setFilterData, columnsList, executeData, isSort = false, sortData, setSortData, state, setState, filterConfig, collectionFilterColumns, isLimit = false, limitData, setLimitData, closeLimitModal } = props;

    const [openAddCondition, setOpenAddCondition] = useState(true);
    const [filterObject, setFilterObject] = useState(new FilterObjectState());
    const [addNewCondition, setAddNewCondition] = useState(false);
    const [radioButtons, setRadioButtons] = useState([]);
    const [operator, setOperator] = useState("");
    const [openConditional, setOpenConditional] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(null);
    const [filterCondtions, setFilterCondtions] = useState([]);
    const [multiSelectedFields, setMultiSelectedFields] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const [limitObject, setLimitObject] = useState({
        limit: 0,
        after: 0
    });
    const [isDataEmptied, setIsDataEmptied] = useState(false);
    const [isLimitDataEmptied, setIsLimitDataEmptied] = useState(false);
    const [editGroupIndex, setEditGroupIndex] = useState(null);
    const [editConditionIndex, setEditConditionIndex] = useState(null);
    const [editSortIndex, setEditSortIndex] = useState(null);

    useEffect(() => {
        if (filterData && filterData.length > 0) {
            const newRadioButtons = filterData.map(group => {
                const groupRadioButtons = group.filters.map(filter => ({
                    name: filter.field,
                    defaultValue: filter.operator,
                    radioButtons: [
                        { label: "AND", value: "and" },
                        { label: "OR", value: "or" }
                    ],
                    labelColor: themeColor.text_2,
                    checkedLabelColor: themeColor.primaryColor,
                }));
                return groupRadioButtons;
            });

            setRadioButtons(newRadioButtons);
        }
    }, [])

    useEffect(() => {
        if (open) {
            if (filterData?.length > 0) {
                const filteredArray = filterData?.filter(group => group?.filters?.length > 0);
                setFilterData(filteredArray);
            }
            setOpenConditional(true);
        }
    }, [open]);

    useEffect(() => {
        if (limitData === null && isLimitDataEmptied) {
            closeLimitModal();
            executeData();
            setLimitObject({
                limit: 0,
                after: 0,
            });

            setAddNewCondition(false);
            setOpenConditional(false);
        }
    }, [isLimitDataEmptied]);

    useEffect(() => {
        if (isDataEmptied) {
            setFilterObject(new FilterObjectState());
            setAddNewCondition(false);
            setOpenConditional(false);
            closeFilterModal();
            setCurrentGroupIndex(null);
            executeData();
            setIsDataEmptied(false);
        }
    }, [isDataEmptied]);

    // useEffect(() => {
    //     setIsDisabled(!validateConditionForm(filterObject));
    // }, [filterObject]);

    const openConditionModal = () => {
        setOpenAddCondition(true);
    }

    const addCondition = () => {

        if (multiSelectedFields?.length > 0) {
            let multiSelectedFieldsData = [...multiSelectedFields];
            const values = multiSelectedFieldsData.map(item => item.value);
            const labels = multiSelectedFieldsData.map(item => item.label);
            // let selectedValues = JSON.stringify(values);

            let commaSeparatedLabels = labels.map(item => `${item}`).join(", ");
            filterObject.values = values;
            filterObject.valueLabel = commaSeparatedLabels;
        }

        if ((filterObject?.condition === AppEnum.FilterConditions.HasProperty || filterObject?.condition === AppEnum.FilterConditions.NotHasProperty)) {
            filterObject.value = null;
        }

        if(filterObject?.condition === AppEnum.FilterConditions.BETWEEN){

            if(filterObject?.value.length > 0 && filterObject?.highValue.length > 0){
                
                if(DateDataTypes.includes(filterObject?.dataTypeName)){
                    let startDate = getDateTimeFormate(filterObject.value);
                    let endDate = getDateTimeFormate(filterObject.highValue);

                    filterObject.valueLabel = `${startDate} to ${endDate}`;
                }else{
                    filterObject.valueLabel = `${filterObject.value} to ${filterObject.highValue}`;
                }

            }
        }


        if (!validateConditionForm(filterObject)) {
            return;
        }
        var data = isSort ? [...sortData] : [...filterData];
        if (isNullOrEmpty(filterObject?.operator)) {
            filterObject.operator = filterConfig?.operatorAllowedInFilter?.length === 1 ? filterConfig?.operatorAllowedInFilter[0] : "or";
        }
        if (isSort) {
            delete filterObject.condition;
            delete filterObject.operator;
            delete filterObject.highValue;
            delete filterObject.isMultiSelect;
        }
        // delete filterObject.isMultiSelect;
        data.push(filterObject);
        if (isSort) {
            setSortData(data)
        }
        else {
            let defaultOperator = filterConfig.operatorAllowedInFilterGroup.length === 1 ? filterConfig.operatorAllowedInFilterGroup[0] : "or";
            setFilterData(prevData => {
                const newData = [...prevData];

                if (newData.length === 0) {
                    newData.push({ filters: [], operator: defaultOperator });
                }

                if (currentGroupIndex != null) {
                    newData[currentGroupIndex].filters.push(filterObject);
                } else {
                    newData[newData.length - 1].filters.push(filterObject);
                    newData[newData.length - 1].operator = defaultOperator;
                }

                return newData;
            });
        }

        setFilterObject(new FilterObjectState());

        setAddNewCondition(false);
        setCurrentGroupIndex(null);
        setMultiSelectedFields([]);
        setOptionValues([]);

        if (!isSort) {
            var newRadio = [...radioButtons];
            newRadio.push({
                name: filterObject.field,
                defaultValue: "or",
                radioButtons: [
                    { label: "AND", value: "and" },
                    { label: "OR", value: "or" }
                ],
                labelColor: themeColor.text_2,
                checkedLabelColor: themeColor.primaryColor,
            })

            setRadioButtons(newRadio);
        }
    }

    const validateConditionForm = (filterObject) => {
        if (isSort) {
            return filterObject?.field && filterObject?.value;
            // return true;
        }
        else {
            return filterObject?.field && filterObject?.condition;
            // return true;
        }
    }

    const handleOnChangeFilterValuesInput = (event, input, isFromOptionValues) => {
        let { value } = event.target;
        let valueDisplayLabel = ""
        if (isFromOptionValues && optionValues?.length > 0) {
            valueDisplayLabel = optionValues?.find(i => i.value == value)?.label;
        }
        if (typeof value != 'string'){
            value = String(value);
        }

        setFilterObject(prevState => {
            let updatedValue;
            if (input === "values") {
                updatedValue = value?.split(',').map(item => item.trim());
            } else {
                updatedValue = value;
            }
            return {
                ...prevState,
                [input]: updatedValue,
                valueLabel: valueDisplayLabel ? valueDisplayLabel : updatedValue
            };
        });
    }

    const handleOnChnageFilterInput = (event, input, filterFields) => {
        setFilterObject((prevState) => {
            return {
                ...prevState, [input]: event?.target?.value
            }
        });

        if (!isSort && input == "field") {
            setFilterObject((prevState) => ({
                ...prevState,
                condition: "",
                value: "",
                operator: "",
                highValue: "",
                displayName: "",
                dataTypeName: "",
                dateLabel: "",
                valueLabel: "",
                values: [],
                isMultiSelect: false,
                dateLabelValue:"",
                conditionLabel:""
            }));
            setMultiSelectedFields([]);
            setOptionValues([]);

            let filterTypeList = getFilterConditionsByDataTypeName(event?.target?.value, true);
            setFilterCondtions(filterTypeList);
        }

        if (filterFields && filterFields?.length > 0 && input == "field") {
            let displayNameValue = filterFields?.find(i => i.columnName == event?.target?.value)?.displayName;
            let dataTypeNameValue = filterFields?.find(i => i.columnName == event?.target?.value)?.dataTypeName;
            setFilterObject((prevState) => {
                return {
                    ...prevState, "displayName": displayNameValue, "dataTypeName": dataTypeNameValue
                }
            });
        }

        if (filterCondtions && input == "condition"){
            let conditionLabel = filterCondtions?.find(i => i.value == event?.target?.value)?.name;
            setFilterObject((prevState) => {
                return {
                    ...prevState, "conditionLabel": conditionLabel
                }
            });
        }
    }

    const getFilterConditionsByDataTypeName = (columnName, isSetState) => {
        var columnObject = columnsList?.find(obj => obj?.columnName == columnName);
        var fieldDataType = columnsList?.find(obj => obj?.columnName == columnName)?.dataTypeName;
        let filterTypeList = filterConfig?.filterTypeArray?.find(obj => obj.type?.includes(fieldDataType))?.value;
        let colunmFilter = null;

        if (collectionFilterColumns?.length > 0){
            colunmFilter = collectionFilterColumns?.find(i => i.columnName == columnName && i.collectionId == state?.templateCollectionId);
        }

        if (fieldDataType == "enumeration" || colunmFilter) {
            if (collectionFilterColumns?.length > 0) {
                // let colunmFilter = collectionFilterColumns?.find(i => i.columnName == columnName && i.collectionId == state?.templateCollectionId);
                if (colunmFilter) {
                    if (colunmFilter?.operators) {
                        const objectOperators = colunmFilter?.operators.includes(',') ? colunmFilter?.operators.replace(/ /g, "").split(',') : [colunmFilter?.operators];
                        filterTypeList = filterTypeList?.filter(operator =>
                            objectOperators.includes(operator.value)
                        );

                        if (filterTypeList?.length == 0){
                            let filterTypeArrayList = filterConfig?.filterTypeArray?.find(obj => obj.type?.includes("Array"))?.value;
                            filterTypeList = filterTypeArrayList?.filter(operator =>
                                objectOperators.includes(operator.value)
                            );
                        }
                    }

                    if (columnObject && isSetState) {
                        getValueOptions(columnObject, colunmFilter);
                    }
                }
                else {
                    if (columnObject?.options?.length > 0 && isSetState) {
                        setOptionValues(columnObject?.options);
                        setFilterObject((prevState) => ({
                            ...prevState,
                            isMultiSelect: columnObject?.isMultiSelect,
                        }));
                    }
                }
            }
        }

        return filterTypeList;
    }

    const getValueOptions = (columnObject, colunmFilter) => {
        const variableUId = colunmFilter?.variableUId;
        if (columnObject?.options?.length > 0) {
            setOptionValues(columnObject?.options);
            setFilterObject((prevState) => ({
                ...prevState,
                isMultiSelect: columnObject?.isMultiSelect,
            }));
        }
        else if (variableUId) {
            UserService.getUserDefinedVariableByUId(variableUId, false)
                .then((response) => {
                    if (response?.data) {
                        const parsedData = JSON.parse(response?.data?.complexTypeValue);
                        if (parsedData) {
                            if (response?.data?.dataTypeName == AppEnum.DataTypeName.List) {
                                let options = parsedData.map(item => ({
                                    label: item.Label,
                                    value: item.Value
                                }));
                                setOptionValues(options);
                            }
                            else if (response?.data?.dataTypeName == "Dynamic List") {
                                getCollectionDetail(parsedData);
                            }
                        }
                    }
                });
            setFilterObject((prevState) => ({
                ...prevState,
                isMultiSelect: colunmFilter?.hasMultiSelection,
            }));
        }
    }


    const getCollectionDetail = async (parsedComplexTypeValue) => {
        try {
            const response = await getCollectionDetails(parsedComplexTypeValue?.DataCollectionUId);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                let testDataCollectionModal = {
                    connectionUId: response?.data?.connectionUId,
                    sysCollectionTypeId: response?.data?.sysCollectionTypeId,
                    sourceName: response?.data?.sourceName,
                    restRequestMethod: response?.data?.restRequestMethod,
                    restRequestIsAsync: response?.data?.restRequestIsAsync,
                    restBody: response?.data?.restBody,
                    restSendAsCD: response?.data?.restSendAsCD,
                    collectionParameters: response?.data?.collectionParameters,
                    collectionColumns: response?.data?.collectionColumns,
                    uId: response?.data?.uId,
                    returnFlatternData: response?.data?.sysCollectionTypeId === 7 ? !(response?.data?.returnRawData) : false,
                    sourceNamePart4: response?.data?.sourceNamePart4,
                    IsASTenantUIdApplicable: false,
                    restDataTransformationScript : response?.data?.restDataTransformationScript,
                    callingConnectionUId: response?.data?.useTemplateConnection ? null : state?.connectionUId,
                    flattenLikeSql: response?.flattenLikeSql || false,
                    excludedProperties: response?.excludedProperties || "",
                    gptPrompt: response?.gptPrompt || "",
                }
                return getDynamicList(testDataCollectionModal, parsedComplexTypeValue);
            }
        } catch (error) {

        }
    }

    const getDynamicList = async (model, parsedComplexTypeValue) => {
        try {
            testData(model,null,getDynamicListOptions,parsedComplexTypeValue);
        } catch (error) {
            toast.error(error);
        }
    }

    const getDynamicListOptions = (data, parsedComplexTypeValue) => {
        const displayColumn = parsedComplexTypeValue?.DisplayColumn;
        const valueColumn = parsedComplexTypeValue?.ValueColumn;
        let options = [];

        if (!displayColumn || !valueColumn) {
            return;
        }

        if (data?.length === undefined) {
            var displayColumnList = data[displayColumn]?.filter(value => value !== null)
            var valueColumnList = data[valueColumn]?.filter(value => value !== null)

            options = displayColumnList.map((label, index) => ({
                label,
                value: valueColumnList[index]
            }));
        }
        else if (data?.length > 0) {
            options = data?.map(item => ({
                label: item[displayColumn],
                value: item[valueColumn]
            }));
        }

        setOptionValues(options);
    };



    const deleteCondition = (groupIndex, index) => {
        var data = isSort ? [...sortData] : [...filterData];
        data = (!isSort && !isLimit) ? data?.filter(group => group.filters.length > 0) : data;
        if (isSort) {
            data.splice(index, 1);
            setSortData(data);

            const colParameters = [...state?.collectionParameters];
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort).length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.sort);
                if (prevCollectionParameter.length > 0) {
                    prevCollectionParameter[0].defaultValue = JSON.stringify(data);
                }
                setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
            }
        }
        else {
            const newData = data;
            newData[groupIndex].filters.splice(index, 1);

            if (newData[groupIndex].filters.length === 0) {
                newData.splice(groupIndex, 1);

                const newRadioButtons = [...radioButtons];
                newRadioButtons.splice(groupIndex, 1);
                setRadioButtons(newRadioButtons);
            }

            setFilterData(newData.length === 0 ? [] : newData);

            const colParameters = [...state?.collectionParameters];
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName === AppEnum.ReservedKeywords.filter).length > 0) {
                const prevCollectionParameter = colParameters.find(i => i.parameterName === AppEnum.ReservedKeywords.filter);
                if (prevCollectionParameter) {
                    prevCollectionParameter.defaultValue = JSON.stringify(newData);
                    setState(prevState => ({ ...prevState, collectionParameters: colParameters }));
                }
            }
        }

        if (data?.length == 0) {
            setIsDataEmptied(true);
        }
    }

    const onRadioInputChange = (value, groupIndex, index) => {
        setFilterData(prevData => {
            const newData = [...prevData];
            if (newData[groupIndex] && newData[groupIndex].filters[index]) {
                newData[groupIndex].filters[index].operator = value;
            }
            return newData;
        });

        let radios = [...radioButtons];
        if (radios[groupIndex]) {
            radios[groupIndex][index] = { defaultValue: value };
        } else {
            radios[groupIndex] = [];
            radios[groupIndex][index] = { defaultValue: value };
        }
        setOperator(value);
    };

    const handleAddCondtionCancel = () => {
        var data = isSort ? [...sortData] : [...filterData];
        if (data?.length == 0) {
            setOpenConditional(false);
            closeFilterModal();
        }
        else if (!isSort) {
            const filteredData = data.filter(group => group.filters.length > 0);
            setFilterData(filteredData);
        }
        else {
            setSortData(data);
            setEditSortIndex(null)
        }


        setAddNewCondition(false);
        setFilterObject({
            field: "",
            condition: "",
            value: "",
            operator: "",
            highValue: "",
            displayName: "",
            dataTypeName: "",
            values: [],
            isMultiSelect: false
        });
        setMultiSelectedFields([]);
        setOptionValues([]);

        setEditGroupIndex(null);
        setEditConditionIndex(null);
    }

    const handleAddGroup = () => {
        setCurrentGroupIndex(null);
        handleCancelEditFilter();
        const newGroup = { filters: [] };
        const updatedFilterData = [...filterData, newGroup];
        setFilterData(updatedFilterData);

        setAddNewCondition(true);
    }

    const handleMultiSelectedFields = (e, value) => {
        setMultiSelectedFields(value);
    }

    const handleDeleteChip = (chipToDelete) => () => {
        setMultiSelectedFields((chips) => chips?.filter((chip) => chip.value !== chipToDelete?.value));
    }

    const handleGroupOperatorChange = (value, groupIndex) => {
        setFilterData(prevData => {
            const newData = prevData.map((group, index) => {
                if (index === groupIndex) {
                    return { ...group, operator: value };
                }
                return group;
            });
            return newData;
        });
        return;
    };

    const handleOnChangeLimitInput = (event, input) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setLimitObject((prevState) => {
            return {
                ...prevState, [input]: value
            }
        });
    }

    const addLimitCondition = () => {
        if (limitObject?.limit == 0) {
            return;
        }

        setLimitData(limitObject);
        setIsLimitDataEmptied(false);

        setLimitObject({
            limit: 0,
            after: 0,
        });
    }

    const deleteLimitCondition = () => {
        setLimitData(null);
        setIsLimitDataEmptied(true);
        var colParameters = [...state?.collectionParameters];
        if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == AppEnum.ReservedKeywords.limit).length > 0) {
            colParameters = colParameters?.filter(i => i.parameterName !== AppEnum.ReservedKeywords.limit);
            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }
    }

    const getDateTimeFormate = (dateTime) => {
        const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

        if (!isoFormat.test(dateTime)) {
            return dateTime;
        }
        
        const date = new Date(dateTime);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
        const day = String(date.getUTCDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const handleFilterEditClick = (groupIndex, conditionIndex) => {
        const filterEditableData = filterData[groupIndex].filters[conditionIndex];
        if (!filterEditableData) {
            return;
        }
        setAddNewCondition(false);
        setEditGroupIndex(groupIndex);
        setEditConditionIndex(conditionIndex);
        setOptionValues([]);

        if ((filterEditableData?.condition != AppEnum.FilterConditions.HasProperty && filterEditableData?.condition != AppEnum.FilterConditions.NotHasProperty)){
            filterEditableData.value = filterEditableData.value == null ? "" : filterEditableData.value;
        }

        setFilterObject({
            field: filterEditableData.field,
            condition: filterEditableData.condition,
            value: filterEditableData.value,
            operator: filterEditableData.operator,
            highValue: filterEditableData.highValue,
            values: filterEditableData.values,
            displayName: filterEditableData.displayName,
            dataTypeName: filterEditableData.dataTypeName,
            dateLabel: filterEditableData.dateLabel,
            valueLabel: filterEditableData.valueLabel,
            isMultiSelect: filterEditableData.isMultiSelect,
            dateLabelValue: filterEditableData.dateLabelValue,
            conditionLabel: filterEditableData.conditionLabel
        });

        if (filterEditableData.isMultiSelect && filterEditableData.values) {
            const labels = filterEditableData.valueLabel.split(", ").map(label => label.trim());
            const values = filterEditableData.values;

            const multiSelectedFieldsObject = values.map((value, index) => ({
            label: labels[index],
            value: value
            }));
            handleMultiSelectedFields(null,multiSelectedFieldsObject);
        }

        let filterTypeList = getFilterConditionsByDataTypeName(filterEditableData.field, true);
            setFilterCondtions(filterTypeList);
    }

    const handleEditCondition = () => {

        if (!validateConditionForm(filterObject)) {
            return;
        }

        const updatedData = filterData.map((group, groupIndex) => {
          if (groupIndex === editGroupIndex) {
            const updatedFilters = group.filters.map((filter, conditionIndex) => {
              if (conditionIndex === editConditionIndex) {

                if (multiSelectedFields?.length > 0) {
                    let multiSelectedFieldsData = [...multiSelectedFields];
                    const values = multiSelectedFieldsData.map(item => item.value);
                    const labels = multiSelectedFieldsData.map(item => item.label);
        
                    let commaSeparatedLabels = labels.map(item => `${item}`).join(", ");
                    filterObject.values = values;
                    filterObject.valueLabel = commaSeparatedLabels;
                }
                if ((filterObject?.condition === AppEnum.FilterConditions.HasProperty || filterObject?.condition === AppEnum.FilterConditions.NotHasProperty)) {
                    filterObject.value = null;
                    filterObject.values = [];
                    filterObject.dateLabel = "";
                    filterObject.dateLabelValue = "";
                    filterObject.highValue = "";
                    filterObject.valueLabel = "";
                }
                else{
                    filterObject.value = filterObject.value == null ? "" : filterObject.value;
                }

                if (isNullOrEmpty(filterObject?.operator)) {
                    filterObject.operator = filter?.operator ? filter?.operator : filterConfig?.operatorAllowedInFilter?.length === 1 ? filterConfig?.operatorAllowedInFilter[0] : "or";
                }
                return filterObject;
              }
              return filter;
            });
            return { ...group, filters: updatedFilters };
          }
          return group;
        });

        setFilterData(updatedData);

        handleCancelEditFilter();
      };

      const handleCancelEditFilter = () => {
        setFilterObject(new FilterObjectState());
        setMultiSelectedFields([]);
        setOptionValues([]);
        setEditGroupIndex(null);
        setEditConditionIndex(null);
        setEditSortIndex(null);
      }

      const handleSortEditClick = (index) => {
        const sortEditableData = sortData[index];
        if (!sortEditableData) {
            return;
        }
        setAddNewCondition(false);
        setEditSortIndex(index)

        setFilterObject({
            field: sortEditableData.field,
            condition: sortEditableData.condition,
            value: sortEditableData.value,
            displayName: sortEditableData.displayName,
            dataTypeName: sortEditableData.dataTypeName,
        });
      }

      const handleEditSortCondition = () =>{
        
        if (!validateConditionForm(filterObject)) {
            return;
        }

        const updatedSortConditions = sortData.map((sort, index) => {
            if (index === editSortIndex) {
              return filterObject;
            }
            return sort;
          });

          setSortData(updatedSortConditions);
          handleCancelEditFilter();
      }

      const handleAutoCompleteDropdownInputChange = (event, input, filterFields, isEditingFilter= false) => {
        if (isEditingFilter){
            return;
        }

        const value = event?.target?.value;
        setFilterCondtions([]);

        if (filterFields?.length == 0 || filterFields?.length > 1 || !value){
            return;
        }
        const filteredOptions = filterFields?.filter(option =>
            option.displayName.toLowerCase().includes(value.toLowerCase())
        );

        if (filteredOptions.length === 1) {
            handleOnChnageFilterInput({ target: { value: filteredOptions[0].columnName } }, input, filterFields);
        }
      }

    return (
        open && (isSort ?
            <SortPopup
                openAddCondition={openAddCondition}
                openConditionModal={openConditionModal}
                filterObject={filterObject}
                filterData={filterData}
                handleOnChnageFilterInput={handleOnChnageFilterInput}
                addCondition={addCondition}
                deleteCondition={deleteCondition}
                radioButtons={radioButtons}
                addNewCondition={addNewCondition}
                setAddNewCondition={setAddNewCondition}
                onRadioInputChange={onRadioInputChange}
                closeFilterModal={closeFilterModal}
                columnsList={columnsList}
                executeData={executeData}
                isSort={isSort}
                sortData={sortData}
                setOpenConditional={setOpenConditional}
                openConditional={openConditional}
                isDisabled={isDisabled}
                handleAddCondtionCancel={handleAddCondtionCancel}
                handleAddGroup={handleAddGroup}
                setCurrentGroupIndex={setCurrentGroupIndex}
                currentGroupIndex={currentGroupIndex}
                filterConfig={filterConfig}
                state={state}
                handleSortEditClick={handleSortEditClick}
                editSortIndex={editSortIndex}
                handleEditSortCondition={handleEditSortCondition}
                handleCancelEditFilter={handleCancelEditFilter}
            />
            : isLimit ?
                <LimitPopup
                    limitData={limitData}
                    openAddCondition={openAddCondition}
                    openConditionModal={openConditionModal}
                    limitObject={limitObject}
                    handleOnChangeLimitInput={handleOnChangeLimitInput}
                    addLimitCondition={addLimitCondition}
                    deleteLimitCondition={deleteLimitCondition}
                    addNewCondition={addNewCondition}
                    setAddNewCondition={setAddNewCondition}
                    closeLimitModal={closeLimitModal}
                    columnsList={columnsList}
                    executeData={executeData}
                    setOpenConditional={setOpenConditional}
                    openConditional={openConditional}
                    isDisabled={isDisabled}
                    handleAddCondtionCancel={handleAddCondtionCancel}
                    filterConfig={filterConfig}
                    state={state}
                />
                :
                <FilterPopup openAddCondition={openAddCondition}
                    openConditionModal={openConditionModal}
                    filterObject={filterObject}
                    filterData={filterData}
                    handleOnChnageFilterInput={handleOnChnageFilterInput}
                    addCondition={addCondition}
                    deleteCondition={deleteCondition}
                    radioButtons={radioButtons}
                    addNewCondition={addNewCondition}
                    setAddNewCondition={setAddNewCondition}
                    onRadioInputChange={onRadioInputChange}
                    closeFilterModal={closeFilterModal}
                    columnsList={columnsList}
                    executeData={executeData}
                    isSort={isSort}
                    sortData={sortData}
                    setOpenConditional={setOpenConditional}
                    openConditional={openConditional}
                    isDisabled={isDisabled}
                    handleAddCondtionCancel={handleAddCondtionCancel}
                    handleAddGroup={handleAddGroup}
                    setCurrentGroupIndex={setCurrentGroupIndex}
                    currentGroupIndex={currentGroupIndex}
                    filterConfig={filterConfig}
                    filterCondtions={filterCondtions}
                    getFilterConditionsByDataTypeName={getFilterConditionsByDataTypeName}
                    state={state}
                    setFilterObject={setFilterObject}
                    multiSelectedFields={multiSelectedFields}
                    handleMultiSelectedFields={handleMultiSelectedFields}
                    handleDeleteChip={handleDeleteChip}
                    handleGroupOperatorChange={handleGroupOperatorChange}
                    optionValues={optionValues}
                    handleOnChangeFilterValuesInput={handleOnChangeFilterValuesInput}
                    getDateTimeFormate={getDateTimeFormate}
                    handleFilterEditClick={handleFilterEditClick}
                    editGroupIndex={editGroupIndex}
                    editConditionIndex={editConditionIndex}
                    handleEditCondition={handleEditCondition}
                    handleCancelEditFilter={handleCancelEditFilter}
                    handleAutoCompleteDropdownInputChange={handleAutoCompleteDropdownInputChange}
                />)
    )
}
