import { Backdrop, Button, ButtonGroup, Dialog, FormControlLabel, ListSubheader, MenuItem, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { ASButton } from "../../../../shared/as-button/as-button";
import { ASTextField } from "../../../../shared/as-text-fields";
import "./cloud-data-script.scss";
import { AppEnum } from "../../../../constants/app-enum";
import AgGridTable from "../../../common/ag-grid/ag-grid";
import ReactJson from "react-json-view";
import { useMemo } from "react";
import _ from 'lodash';
import { truncateString } from "../../../../shared/code-challanges/code-challanges";

const CloudDataScript = (props) => {
    const { handleEditor, scriptType, handleTestScript, setEditorTabView, activeEditorTabView, customOrPreDefineScript, handleRadioOptionChange,
        handleCancel, tableOrJsonView, testedData, setTableOrJsonView, dataScripts, handleApplyScript, handlePreDefineDropdownChange,
        preDefinedScripts, state, dataForStyleScript, handleOpenDialog, cloudDataScriptType } = props;

    const transformedDataResult = scriptType === AppEnum.DataScript.dataTransformationScript ? testedData?.transformedData : testedData?.styleTransformedData;
    const transformedDataColumnsKeyResult = scriptType === AppEnum.DataScript.dataTransformationScript ? testedData?.transformedDataColunmsKey : testedData?.styleTransformedDataColunmsKey;
    const rawData = useMemo(() => {
        return _.cloneDeep(state?.testedDataList);
    }, [state?.testedDataList]);

    const groupedScripts = useMemo(() => {
        return preDefinedScripts.reduce(
            (acc, script) => {
                const asTenantUId = sessionStorage.getItem("ASTenantId") || localStorage.getItem("ASTenantId");
                if (script.applicableToCD === AppEnum.DataScriptApplicable.NORMAL_DATA_COLLECTIONS ) {
                    if (script.asTenantUId != asTenantUId) {
                        acc.templateScripts.push(script);
                    } else {
                        acc.ownedScripts.push(script);
                    }
                }
                return acc;
            },
            { templateScripts: [], ownedScripts: [] }
        );
    }, [preDefinedScripts]);

    return (
        <Dialog
            open={props?.isOpenDialog}
            onClose={props?.handleClosePopup}
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: { backdropFilter: "blur(3px)" },
                onClick: props.handleClosePopup,
            }}
            fullWidth={true}
            maxWidth="xl"
            PaperProps={{ sx: { borderRadius: "10px", height: "100%", overflowY: "unset" } }}
        >
            <Paper sx={{ padding: "20px" }} ref={props.prevStepRef}>
                <div className="cloud-data-script-header">
                    <Typography
                        sx={{
                            color: "#16365f",
                            fontWeight: "700",
                            fontSize: "1.5rem"
                        }}
                        variant="body1"
                        display="block"
                    >
                        {scriptType === AppEnum.DataScript.dataTransformationScript ? "Apply Data Transformation Script" : "Apply Data Styling Script"}
                    </Typography>
                    <div>
                        <ASButton
                            variant="contained"
                            disabled={false}
                            // sx={{ mr: 4 }}
                            primary={false}
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </ASButton>
                        <ASButton
                            variant="contained"
                            disabled={false}
                            sx={{ ml: 3 }}
                            primary={true}
                            onClick={(e) => handleApplyScript()}
                        >
                            Apply Script
                        </ASButton>
                    </div>
                </div>

                <div className="cloud-data-script-content">

                    <div className="cloud-data-script-radio-section">
                        <RadioGroup
                            row
                            value={customOrPreDefineScript}
                            onChange={handleRadioOptionChange}
                            sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                            <FormControlLabel
                                value="preDefine"
                                control={<Radio
                                    sx={{
                                        color: "#16365f",
                                        "&.Mui-checked": {
                                            color: "#16365f",
                                        },
                                    }} />}
                                sx={{
                                    "&.MuiFormControlLabel-root": {
                                        width: "26%",
                                    },
                                    "& .MuiTypography-root": {
                                        width: "100%"
                                    }
                                }}
                                label={
                                    <ASTextField
                                        size="small"
                                        className="text-field1 label-text-field"
                                        type={"text"}
                                        sx={{ fontSize: "12px" }}
                                        fullWidth
                                        label={"Select from predefined scripts"}
                                        variant="outlined"
                                        select={true}
                                        disabled={customOrPreDefineScript !== "preDefine"}
                                        value={scriptType === AppEnum.DataScript.dataTransformationScript ? dataScripts?.transformationScriptId : dataScripts?.stylingScriptId}
                                        onChange={handlePreDefineDropdownChange}
                                        SelectProps={{
                                            MenuProps: {
                                                className: "predefine-script-menu-role-div",
                                            },
                                            renderValue: (selected) => {
                                                const selectedScript = groupedScripts?.templateScripts.find(script => script.id === selected) ||
                                                                       groupedScripts?.ownedScripts.find(script => script.id === selected);
                                                return selectedScript ? truncateString(selectedScript.name, 35) : "";
                                            }
                                        }}
                                    >
                                        {/* {preDefinedScripts?.map((script) => (
                                            <MenuItem key={script.id} value={script.id}>
                                                {script.name}
                                            </MenuItem>
                                        ))} */}
                                        <ListSubheader className='merge-predefine-title'>Template Scripts</ListSubheader>
                                        {groupedScripts.templateScripts.map((script) => (
                                            <MenuItem className='merge-predefine-menu' key={script.id} value={script.id}>
                                                {truncateString(script.name, 45)}
                                            </MenuItem>
                                        ))}

                                        <ListSubheader className='merge-predefine-title'>Owned Scripts</ListSubheader>
                                        {groupedScripts.ownedScripts.map((script) => (
                                            <MenuItem className='merge-predefine-menu' key={script.id} value={script.id}>
                                                {truncateString(script.name, 45)}
                                            </MenuItem>
                                        ))}
                                    </ASTextField>
                                }

                            />
                            <FormControlLabel
                                value="customScript"
                                control={<Radio
                                    sx={{
                                        color: "#16365f",
                                        "&.Mui-checked": {
                                            color: "#16365f",
                                        },
                                    }} />}
                                label="Custom Script"
                            />
                        </RadioGroup>
                    </div>

                    <div className="tab-div">
                        <div className="tab-btn-group">
                            <ButtonGroup variant="outlined" sx={{ mb: 1 }}>
                                <Button
                                    sx={{
                                        padding: "5px 30px",
                                        borderColor: "grey.400",
                                        color: "grey.700",
                                        backgroundColor: activeEditorTabView === "raw_data" ? "grey.200" : "transparent",
                                        "&:hover": {
                                            backgroundColor: "grey.300",
                                            borderColor: "grey.400",
                                        },
                                        textTransform: "none",
                                        "&.Mui-focusVisible": {
                                            outline: "none",
                                        },
                                    }}
                                    onClick={() => setEditorTabView("raw_data")}
                                    variant="outlined"
                                >
                                    Raw Data
                                </Button>
                                <Button
                                    sx={{
                                        padding: "5px 30px",
                                        borderColor: "grey.400",
                                        color: "grey.700",
                                        backgroundColor: activeEditorTabView === "script" ? "grey.200" : "transparent",
                                        "&:hover": {
                                            backgroundColor: "grey.300",
                                            borderColor: "grey.400",
                                        },
                                        textTransform: "none",
                                        "&.Mui-focusVisible": {
                                            outline: "none",
                                        },
                                    }}
                                    onClick={() => setEditorTabView("script")}
                                    variant="outlined"
                                    disabled={customOrPreDefineScript == "preDefine"}
                                >
                                    Script
                                </Button>
                                <Button
                                    sx={{
                                        padding: "5px 30px",
                                        borderColor: "grey.400",
                                        color: "grey.700",
                                        backgroundColor: activeEditorTabView === "result" ? "grey.200" : "transparent",
                                        "&:hover": {
                                            backgroundColor: "grey.300",
                                            borderColor: "grey.400",
                                        },
                                        textTransform: "none",
                                        "&.Mui-focusVisible": {
                                            outline: "none",
                                        },
                                    }}
                                    onClick={() => setEditorTabView("result")}
                                    variant="outlined"
                                >
                                    Test Result
                                </Button>
                            </ButtonGroup>
                            <div>
                                {activeEditorTabView === "result" &&
                                    <ButtonGroup variant="outlined" sx={{
                                        mb: 1,
                                        "& .MuiButtonGroup-grouped:first-of-type": {
                                            borderRightColor: "grey.400",
                                            borderTopRightRadius: "4px",
                                            borderBottomRightRadius: "4px",
                                        }
                                    }}>
                                        <Button
                                            sx={{
                                                padding: "5px 30px",
                                                borderColor: "grey.400",
                                                color: "grey.700",
                                                backgroundColor: tableOrJsonView === "table" ? "grey.200" : "transparent",
                                                "&:hover": {
                                                    backgroundColor: "grey.300",
                                                    borderColor: "grey.400",
                                                },
                                                textTransform: "none",
                                                "&.Mui-focusVisible": {
                                                    outline: "none",
                                                },
                                            }}
                                            onClick={() => setTableOrJsonView("table")}
                                            variant="outlined"
                                        >
                                            Table View
                                        </Button>
                                        <Button
                                            sx={{
                                                marginLeft: "20px !important",
                                                padding: "5px 30px",
                                                borderColor: "grey.400",
                                                color: "grey.700",
                                                backgroundColor: tableOrJsonView === "json" ? "grey.200" : "transparent",
                                                "&:hover": {
                                                    backgroundColor: "grey.300",
                                                    borderColor: "grey.400",
                                                },
                                                textTransform: "none",
                                                "&.Mui-focusVisible": {
                                                    outline: "none",
                                                },
                                            }}
                                            onClick={() => setTableOrJsonView("json")}
                                            variant="outlined"
                                        >
                                            Json View
                                        </Button>
                                    </ButtonGroup>}

                                <ASButton
                                    variant="contained"
                                    disabled={false}
                                    sx={{ ml: 4 }}
                                    primary={true}
                                    onClick={(e) => handleTestScript(e)}
                                >
                                    Test Script
                                </ASButton>
                            </div>
                        </div>
                        {activeEditorTabView === "script" ? (

                            <div style={{ flex: 1 }}>
                                <AceEditor
                                    placeholder="Placeholder Text"
                                    height={"70vh"}
                                    width="100%"
                                    mode="javascript"
                                    name="blah2"
                                    onLoad={() => { }}
                                    onChange={(e) => handleEditor(e)}
                                    fontSize={16}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    value={scriptType === AppEnum.DataScript.dataTransformationScript ? dataScripts?.transformationScript : dataScripts?.stylingScript}
                                    setOptions={{
                                        enableBasicAutocompletion: false,
                                        enableLiveAutocompletion: false,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </div>
                        ) :
                            activeEditorTabView == "raw_data" ? (
                                <ReactJson src={(scriptType === AppEnum.DataScript.dataTransformationScript || state?.hasMergeCollections) ? rawData : dataForStyleScript} theme="monokai" name={false} 
                                    style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'auto', marginTop: '5px', minHeight: '70vh' }} displayDataTypes={false} 
                                    enableClipboard={(copy) => {
                                        navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2));
                                    }}
                                />
                            ) :
                                (
                                    tableOrJsonView == "json" ? (
                                        <ReactJson src={transformedDataResult} theme="monokai" name={false} style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'auto', marginTop: '5px', minHeight: '70vh' }} displayDataTypes={false} 
                                        enableClipboard={(copy) => {
                                            navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2));
                                        }}/>
                                    )
                                        : (tableOrJsonView == "table") && (
                                            <div className="test-transform-data-table">
                                                <AgGridTable
                                                    rows={transformedDataResult}
                                                    columns={transformedDataColumnsKeyResult}
                                                    isAfterTransformation={true}
                                                    handleOpenDialog={handleOpenDialog}
                                                    cloudDataScriptType={cloudDataScriptType}
                                                />
                                            </div>
                                        )
                                )}
                    </div>
                </div>
            </Paper>
        </Dialog>
    );
};

export default CloudDataScript;
