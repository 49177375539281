import React, { useMemo } from 'react'
import { Dialog, Backdrop, ButtonGroup, Button, Box, Typography } from "@mui/material";
import "./column-selection-popup.scss"
import ColumnSelectionHeader from './column-selection-header';
import ColumnSelectionList from './column-selection-list';
import AgGridTable from '../../common/ag-grid/ag-grid';
import { ASButton } from '../../../shared/as-button/as-button';
import CircularProgress from '@mui/material/CircularProgress';
import { AppEnum, ParameterTypeValue } from '../../../constants/app-enum';
import { SearchParameterController } from '../../../controllers/asheetPlugin/search-parameter.js/search-parameters';
import ColumnRenameFormatPopup from '../column-format-rename/column-format-rename';
import { DateParseVariable } from "../../../constants/date-parse-variable";
import MergeCollectionListController from '../../../controllers/data-collections/column-selection/merge-collection-list-controller/merge-collection-list-controller';
import MergeCollectionPopup from '../../common/test-collection-popup/test-collection-popup';
import CloudDataScriptController, { defaultJavaScriptEditorValue } from '../../../controllers/data-collections/column-selection/cloud-data-script/cloud-data-script-controller';
import { Link } from 'react-router-dom';
import MuiDialog from '../../../shared/mui-dialog/mui-dialog';

export default function ColumnSelectionPopup(props) {

    const { columnsList, rowData, handleOnChangeSelectColumn, sortModal, filterModal, openSortModal, openFilterModal, closeSortModal, closeFilterModal,
        onChangeSortInputs, sortInput, addSort, sortData, setSortData, handleDeleteSort, handleClickSort, handleSave, executeData, filterData, setFilterData,
        preview, handleChangeObject, isLoading, isDialogOpen, state, setState, selectedConnection, handleObjectChange, filterConfig, onSearchHandler, filterColumnsList, collectionFilterColumns, 
        closeLimitModal, openLimitModal, limitModal,limitData, setLimitData, searchInputRef,searchValue, setColumnList, setIsColumnOrderChanged, 
        handleSearchOptionsButtonClick, selectedSearchButton, isSearchOptionsAvailable, searchOptionsParameters, setSearchOptionsParameters, executedData, isOpenInDialog, handleAuthorizeAgain, isAuthorizeAgain, showNativeDataVisualizer,
        handleOpenDialog, openPopup, handleClosePopup, onListClick, onSaveClick, customColumn, onDisplayNameChange,
        datatypeDisplayFormate, prioritizeEquivalentDataTypes, mergedCollectionsData, handleAddCloudDataScriptPopup, openDataScriptPopup, setCloudDataScriptType, cloudDataScriptType, handleTestScript,
        testedData, confirmRemoveTransformation, preDefinedScriptsData, isMergeDataPopupVisible, mergeDataPopupMessage, isMergeCancelledRef, handleMergePopupClose, excelDataState, dataForStyleScript, 
        isParameterPopulated,setIsParameterPopulated, muiDialog, setMuiDialog, handleRemoveTransformation, afterTransformationColumnKeys} = props;

        const parameters= () => {
            let currentCollectionName = null;

            return searchOptionsParameters?.map((parameter, index) => {
                const parameterTypes = Object.values(ParameterTypeValue());

                // if(parameter.parseAtClientSide && parameter.sysDataTypeId === AppEnum.DataTypeId.Date){
                //     parameter.parameterValue = dateParser.parseVariable(parameter.defaultValue);
                // }
                var lastUDVIndex = searchOptionsParameters.indexOf(searchOptionsParameters.findLast(i => i.sysDataTypeId == 8))
                if (parameterTypes.includes(parameter.parameterTypeCD)) {

                // Grouping logic
                let collectionLabel = null;
                if (!state.combineMergedParameters && state.hasMergeCollections && mergedCollectionsData) {
                    let collection = mergedCollectionsData.find(col => col.uId === parameter.childCollectionUId);

                    if (!collection) {
                        collection = mergedCollectionsData.find(col => col.uId === parameter.collectionUId);
                    }
                    const collectionName = collection?.collectionName || "";
        
                    // Render collection label if it's a new group
                    if (collectionName !== currentCollectionName) {
                        currentCollectionName = collectionName;
                        collectionLabel = (
                            <Typography variant="h6" className="collection-label" key={`collection-${collectionName}`}>
                                {collectionName}
                            </Typography>
                        );
                    }
                }

                return (
                    <div>
                        {collectionLabel}
                        <SearchParameterController parameter={parameter} 
                                                    index={index} 
                                                    state={state} 
                                                    setSearchOptionsParameters={setSearchOptionsParameters} 
                                                    searchOptionsParameters={searchOptionsParameters} isPopup={true}
                                                    mergedCollectionsData={mergedCollectionsData}
                                                    setState={setState}
                                                    setIsParameterPopulated={index === lastUDVIndex && !isParameterPopulated ? setIsParameterPopulated : null}
                                                    excelDataState={excelDataState}/>
                    </div>
                )
            }
        })}

        const isTransformationScriptApplied = useMemo(() => {
            return (
            (state?.cloudDataTransformationScript &&
                state?.cloudDataTransformationScript !== defaultJavaScriptEditorValue) ||
            state?.dataTransformationScriptId
            );
        }, [state?.cloudDataTransformationScript, state?.dataTransformationScriptId]);

        const isStylingScriptApplied = useMemo(() => {
            return (
            (state?.dataStylingScript &&
                state?.dataStylingScript !== defaultJavaScriptEditorValue) ||
            state?.dataStylingScriptId
            );
        }, [state?.dataStylingScript, state?.dataStylingScriptId]);

        const transformedDataResult = cloudDataScriptType === AppEnum.DataScript.dataTransformationScript ? testedData?.transformedData : testedData?.styleTransformedData;
        const transformedDataColumnsKeyResult = cloudDataScriptType === AppEnum.DataScript.dataTransformationScript ? testedData?.transformedDataColunmsKey : testedData?.styleTransformedDataColunmsKey;

        const returnPopupView = () => {
            return (
                !isLoading &&
                    <div className='columns-selection-container'>

                <ColumnSelectionHeader sortModal={sortModal}
                                       filterModal={filterModal}
                                       limitModal={limitModal}
                                       openSortModal={openSortModal}
                                       openFilterModal={openFilterModal}
                                       openLimitModal={openLimitModal}
                                       closeSortModal={closeSortModal}
                                       closeFilterModal={closeFilterModal}
                                       closeLimitModal={closeLimitModal}
                                       columnsList={columnsList}
                                       onChangeSortInputs={onChangeSortInputs}
                                       sortInput={sortInput}
                                       addSort={addSort}
                                       sortData={sortData}
                                       limitData={limitData}
                                       setLimitData={setLimitData}
                                       setSortData={setSortData}
                                       handleDeleteSort={handleDeleteSort}
                                       handleClickSort={handleClickSort}
                                       handleSave={handleSave}
                                       filterData={filterData}
                                       setFilterData={setFilterData}
                                       handleChangeObject={handleChangeObject}
                                       executeData={executeData}
                                       state={state}
                                       setState={setState}
                                       selectedConnection={selectedConnection}
                                       handleObjectChange={handleObjectChange}
                                       filterConfig={filterConfig}
                                       collectionFilterColumns={collectionFilterColumns}
                                       handleAuthorizeAgain={handleAuthorizeAgain}
                                       isAuthorizeAgain={isAuthorizeAgain}
                                       isOpenInDialog={isOpenInDialog}
                                       excelDataState={excelDataState}
                                       showNativeDataVisualizer={showNativeDataVisualizer}/>

                <div className={isOpenInDialog ? 'columns-selection-content-container' : 'columns-selection-content-container-notfordialog'}>
                    <div className='vertical-container'>
                       {(isSearchOptionsAvailable || state?.hasMergeCollections) && <div className='button-group-container'>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                {isSearchOptionsAvailable &&
                                    <Button  onClick={() => handleSearchOptionsButtonClick(0)} className={selectedSearchButton === 0 ? 'selected' : ''}>
                                        Search Options
                                    </Button>}
                                {(selectedConnection.schemaSource !== AppEnum.SchemaSource.hasDynamicColumn) && 
                                <Button  onClick={() => handleSearchOptionsButtonClick(1)} className={selectedSearchButton === 1 ? 'selected' : ''}>
                                    Columns
                                </Button>}
                                {state?.hasMergeCollections && 
                                    <Button  onClick={() => handleSearchOptionsButtonClick(2)} className={selectedSearchButton === 2 ? 'selected' : ''}>
                                        Merged Collections
                                    </Button>
                                }
                            </ButtonGroup>
                        </div>}
                      {((selectedSearchButton === 0 && isSearchOptionsAvailable)) && 
                      <div className='search-option-container'> 
                        <div>{parameters()} </div>
                        <div className='search-option-refresh-button'><ASButton primary={true} onClick={() => executedData(false, true)}>REFRESH PREVIEW</ASButton></div>
                      </div> }
                      {(selectedSearchButton === 1 ||(!isSearchOptionsAvailable && selectedSearchButton !== 2 )) && <ColumnSelectionList columnsList={columnsList} handleOnChangeSelectColumn={handleOnChangeSelectColumn} onSearchHandler={onSearchHandler} filterColumnsList={filterColumnsList} searchInputRef={searchInputRef} searchValue={searchValue} cloudDataScriptType={cloudDataScriptType}/>}
                      {(selectedSearchButton === 2 && state?.hasMergeCollections) && <MergeCollectionListController state={state} mergedCollectionsData={mergedCollectionsData}/>}
                      </div>
                    <div className='data-table-div'>
                        <div className='data-script-button-group-container'>
                            <ButtonGroup variant="outlined" sx={{ mb: 1 }}>
                               {(isTransformationScriptApplied || isStylingScriptApplied) && <Button onClick={() => handleAddCloudDataScriptPopup(null)} className={cloudDataScriptType === null? 'selected' : ''}>
                                    Data Response
                                </Button>}
                                {!showNativeDataVisualizer && (
                                    isTransformationScriptApplied ?
                                    <Button onClick={() => handleAddCloudDataScriptPopup(AppEnum.DataScript.dataTransformationScript)} className={cloudDataScriptType === AppEnum.DataScript.dataTransformationScript? 'selected' : ''}>
                                        {cloudDataScriptType === AppEnum.DataScript.dataTransformationScript ? (
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <span>Result After Transformation Script</span>
                                            <Link href="#" underline="hover" className='add-script-edit-remove-link' onClick={(e) => { e.preventDefault(); handleAddCloudDataScriptPopup(AppEnum.DataScript.dataTransformationScript, true, true);}}>
                                            edit
                                            </Link>
                                            <Link href="#" underline="hover" className='add-script-edit-remove-link' onClick={(e) => { e.preventDefault(); confirmRemoveTransformation(AppEnum.DataScript.dataTransformationScript);}}>
                                            remove
                                            </Link>
                                        </Box>
                                        ) : (
                                        "Result After Transformation Script"
                                        )}
                                    </Button> : 
                                    <div className='addscript-div-container'>
                                        <Link to="#" className="add-script-link"  onClick={(e) => { e.preventDefault(); handleAddCloudDataScriptPopup(AppEnum.DataScript.dataTransformationScript, true);}}><span >Add Data Transformation Script</span></Link>
                                    </div>
                                )}
                                
                                
                                {(showNativeDataVisualizer && state?.hasMergeCollections) || !showNativeDataVisualizer ? (
                                    isStylingScriptApplied ? (
                                    <Button onClick={() => handleAddCloudDataScriptPopup(AppEnum.DataScript.dataStylingScript)} className={cloudDataScriptType === AppEnum.DataScript.dataStylingScript? 'selected' : ''}>
                                        {cloudDataScriptType === AppEnum.DataScript.dataStylingScript ? (
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <span>Result After Styling Script</span>
                                            <Link href="#" underline="hover" className='add-script-edit-remove-link' onClick={(e) => { e.preventDefault(); handleAddCloudDataScriptPopup(AppEnum.DataScript.dataStylingScript, true, true);}}>
                                            edit
                                            </Link>
                                            <Link href="#" underline="hover" className='add-script-edit-remove-link' onClick={(e) => { e.preventDefault(); confirmRemoveTransformation(AppEnum.DataScript.dataTransformationScript);}}>
                                            remove
                                            </Link>
                                        </Box>
                                        ) : (
                                        "Result After Styling Script"
                                        )}
                                    </Button>
                                    ) :(
                                        <div className='addscript-div-container'>
                                            <Link to="#" className="add-script-link"  onClick={(e) => { e.preventDefault(); handleAddCloudDataScriptPopup(AppEnum.DataScript.dataStylingScript, true); }}><span >Add Data Styling Script</span></Link>
                                        </div>
                                    )
                                    
                                ) : null}
                            </ButtonGroup>
                        </div>

                        {(((!isTransformationScriptApplied && !isStylingScriptApplied) || cloudDataScriptType == null) ? <>
                            <div className='data-table-main-div'>
                                <AgGridTable rows={rowData} columns={columnsList} isColumnSelection={true} executeData={executeData} setState={setState} setColumnList={setColumnList} setIsColumnOrderChanged={setIsColumnOrderChanged} isColumnMoveable={showNativeDataVisualizer} handleOpenDialog={handleOpenDialog} cloudDataScriptType={cloudDataScriptType}/>
                                {preview && <div className='refresh-button-div'><ASButton primary={true} onClick={() => executeData(true)}>REFRESH PREVIEW</ASButton></div>}
                            </div>
                            <div className='row-number-div'>
                                <span className='row-count-div'>Total {rowData.length} rows</span>
                            </div> 
                        </> : 
                         <>
                            <div className='data-table-main-div'>
                                <AgGridTable rows={transformedDataResult} columns={transformedDataColumnsKeyResult} setIsColumnOrderChanged={setIsColumnOrderChanged} isColumnMoveable={false} isAfterTransformation={true} handleOpenDialog={handleOpenDialog} cloudDataScriptType={cloudDataScriptType}/>
                            </div>
                            <div className='row-number-div'>
                                <span className='row-count-div'>Total {transformedDataResult?.length} rows</span>
                            </div> 
                        </>)}
                    </div>
                </div>
                <ColumnRenameFormatPopup datatypeDisplayFormate={datatypeDisplayFormate} 
                                         onListClick={onListClick}
                                         customColumn={customColumn} 
                                         onSaveClick={onSaveClick} 
                                         onDisplayNameChange={onDisplayNameChange}
                                         isOpenDialog={openPopup} 
                                         handleClosePopup={handleClosePopup} 
                                         prioritizeEquivalentDataTypes={prioritizeEquivalentDataTypes} />
                <CloudDataScriptController                                        
                                        state={state}
                                        setState={setState}
                                        handleClosePopup={handleClosePopup} 
                                        isOpenDialog={openDataScriptPopup}
                                        setCloudDataScriptType={setCloudDataScriptType}
                                        handleTestScript={handleTestScript}
                                        testedData={testedData}
                                        preDefinedScriptsData={preDefinedScriptsData}
                                        isTransformationScriptApplied={isTransformationScriptApplied}
                                        isStylingScriptApplied={isStylingScriptApplied}
                                        cloudDataScriptType={cloudDataScriptType}
                                        dataForStyleScript={dataForStyleScript}
                                        handleOpenDialog={handleOpenDialog}/>
                    <MuiDialog          
                                        isShowPopUp={muiDialog}
                                        secondaryButtonAction={() => setMuiDialog(false)} 
                                        primaryButtonAction={() => handleRemoveTransformation(cloudDataScriptType)}
                                        closeDialog={() => setMuiDialog(false)}
                                        secondaryButtonTitle="No"
                                        primaryButtonTitle="Yes"
                                        titleDescription={`Are you sure you want to remove the data ${cloudDataScriptType ===  AppEnum.DataScript.dataTransformationScript ? "transformation" : "styling"} script?`}
                />
            </div>
            )
        } 

  return isOpenInDialog ? 
    <>
        <Dialog open={true} handleCloseDialog={() => console.log('Closed')} 
            fullWidth={true}
            maxWidth="xl"
            PaperProps={{ sx: { borderRadius: "10px", height:"90vh", overflowY:"unset"  } }}
            >
                {returnPopupView()}

                {!isDialogOpen && 
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                        open={isLoading}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>}
      </Dialog>
      <MergeCollectionPopup
                isVisible={isMergeDataPopupVisible}
                messages={mergeDataPopupMessage}
                onCancel={() => {
                    isMergeCancelledRef.current = true;
                }}
                handleMergePopupClose={handleMergePopupClose}
        />
    </>
         : 
      <>
        {returnPopupView()}

        {!isDialogOpen && 
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>}
      </>
}
