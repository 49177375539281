import React, { useMemo } from 'react'
import ASAutoComplete from '../../../shared/as-inputs/as-auto-complete';
import ASPaper from '../../../shared/misc/as-mui-paper';
import './merge-collections.scss';
import { Typography } from '@mui/material';
import { CollectionTypeRadioButtons } from '../select-object/select-object';
import { useParams } from 'react-router-dom';

const  SelectCollections = (props) => {

    const { prevStepRef, dataCollections, mergeDataCollections, onChangeObjectSelectHandler, selectedObjectCollections, childCollectionUId,
        handleCollectionTypeChange, isDataLoading, collectionType, collectionsCount, onChangeCollectionForTemplate, collectionTypeTemplate } = props;
    const { collectionUId: collectionUId } = useParams();    

    const mergeOptions = useMemo(() => {
        return mergeDataCollections?.map((option) => ({
            key: option?.uId,
            label: option?.collectionName,
            connectionIconImage: option?.connectionIconImage
        }))
    }, [mergeDataCollections])

    const cloudOptions = useMemo(() => {
        return dataCollections?.map((option) => ({
            key: option?.uId,
            sysConnectionType: option?.sysConnectionType,
            label: option?.collectionName,
            connectionIconImage: option?.connectionIconImage
        }))
    }, [dataCollections])

    return (
        <ASPaper
            ref={prevStepRef}
            isDataLoading={isDataLoading}
        >
            <CollectionTypeRadioButtons
                collectionUId={collectionUId}
                collectionType={collectionType}
                handleCollectionTypeChange={handleCollectionTypeChange}
            />            

            <ASAutoComplete
                key={collectionTypeTemplate ? "single" : "multiple"}
                options={collectionTypeTemplate ? mergeOptions : cloudOptions}
                isMultiSelect={collectionTypeTemplate ? false : true}
                size={"medium"}
                onChange={onChangeObjectSelectHandler}
                value={collectionTypeTemplate ? childCollectionUId : selectedObjectCollections}
                label={"Select collections"}
                tableContainerRef={prevStepRef}
                className='auto-complete-input'
            />

            {collectionTypeTemplate && collectionsCount?.length > 0 ? (
                    collectionsCount?.map((item) => (
                        <div className="merge-collection-select" key={item?.name}>
                            <Typography sx={{width:"25%"}} >{item?.name}</Typography>
                            <ASAutoComplete
                                options={cloudOptions?.filter(i=> i.sysConnectionType === item?.connectionId)}
                                item={item}
                                size={"medium"}
                                selectedOptionValue={item?.uId}
                                onChange={onChangeCollectionForTemplate}
                                value={item?.uId}
                                label={"Select collection"}
                                tableContainerRef={prevStepRef}
                                className='auto-complete-input'
                            />                                                     
                        </div>
                    ))
                ) : null
            }
        </ASPaper >
    )
}

export default SelectCollections