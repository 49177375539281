import { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import SelectObject from '../../../../components/data-collections/select-object/select-object';
import { AppEnum } from '../../../../constants/app-enum';

const SelectObjectController = (props) => {

    const { state, setState, prevStepRef, selectedConnection, getCollectionObjectByType,
            setCollectionType, collectionType } = props;
    const { cloudCollectionObjects, childTemplateCollectionObjects } = props.state;
    const [selectedObjectUId, setSelectedObjectUId] = useState(null);
    const { collectionUId: collectionUId } = useParams();

    useEffect(() => {
        if ((selectedConnection && !state?.templateCollectionId) || collectionUId) {
            getCollectionObjectByType();
            getCollectionObjectByType(AppEnum.CollectionType.FromChildTemplate);
        }
    }, [selectedConnection]);

    const collectionTypeTemplate = useMemo(()=>{        
        return collectionType === AppEnum.CollectionType.FromChildTemplate
    },[collectionType])

    useEffect(() => {
        if (state?.templateCollectionId && !collectionTypeTemplate) {
            let collectionTemplateID = state?.templateCollectionId;
            if(state?.childTemplateInheritedCollectionId){
                collectionTemplateID = state?.childTemplateInheritedCollectionId;
                setCollectionType(AppEnum.CollectionType.FromChildTemplate);
            }
            setSelectedObjectUId(collectionTemplateID);
        }
        else if(collectionTypeTemplate){
            let collectionTemplateID = state?.childTemplateInheritedCollectionId || state?.childTemplateCollectionId;
            setSelectedObjectUId(collectionTemplateID);
        }
    }, [])


    const handleObjectSelection = (event) => {
        let value = Number(event.target.value);
        setSelectedObjectUId(value);
        let collectionObjects = collectionTypeTemplate ? childTemplateCollectionObjects : cloudCollectionObjects;
        let object = collectionObjects?.find(i => i.templateCollectionId == event.target.value);
        
        if (object) {
            setState((prevState) => {
                const { dynamicListCollectionModel, dynamicListOptions, ...rest } = prevState;
                return {
                    ...rest,
                    templateCollectionId: object?.templateCollectionId,
                    restDataTransformationScript: object?.restDataTransformationScript,
                    childCollectionUId: object?.templateCollectionUId,
                    childCompanyCollectionUId: collectionTypeTemplate ? object?.templateCollectionUId : null,
                    childTemplateCollectionId: collectionTypeTemplate ? object?.templateCollectionId : null,
                    collectionParameters: prevState.collectionParameters.filter(param =>
                        [AppEnum.ReservedKeywords.filter, AppEnum.ReservedKeywords.sort, AppEnum.ReservedKeywords.limit].includes(param.parameterName)
                    )
                };
            });
        }
    };

    const handleCollectionTypeChange = (event) => {
        setCollectionType(event.target.value);
        if(!collectionUId){
            setState((prevState) => { return { ...prevState, templateCollectionId: null, childTemplateInheritedCollectionId: null } });
            setSelectedObjectUId(null);
        }
        else{
            setState((prevState) => { return { ...prevState, childTemplateInheritedCollectionId: null} });
        }
    };

    return (
        <SelectObject
            state={state}
            prevStepRef={prevStepRef}
            selectedObjectUId={selectedObjectUId}
            handleObjectSelection={handleObjectSelection}
            collectionObjects= {collectionTypeTemplate ? state?.childTemplateCollectionObjects : cloudCollectionObjects}
            collectionType={collectionType}
            handleCollectionTypeChange={handleCollectionTypeChange}
        />
    );
}

export default SelectObjectController;