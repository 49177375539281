import { Autocomplete, Box, Button, CardMedia, Checkbox, Chip, FormControlLabel, InputLabel, MenuItem, Modal, Stack, Typography, styled } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import '../plugin-parameters/plugin-parameters';
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { useMemo, useState } from "react";
import { autocompleteClasses } from '@mui/material/Autocomplete';
import themeColor from "../../../styles/_exports.module.scss";
import _, { isEmpty } from 'lodash';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, Collapse} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { ASButton } from "../../../shared/as-button/as-button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ASTooltip } from "../../../shared/as-tooltip/as-tooltip";


export const StyledParamAutocompletePopper = styled('div')(({ theme }) => ({

    [`& .${autocompleteClasses.listbox}`]: {
        fontSize: '13px !important',
        paddingTop: '0px !important',

        [`& .${autocompleteClasses.option}`]: {
            '&[aria-selected="true"]': {
                backgroundColor: `${themeColor.primaryColor} !important`,
                color: `white !important`
            },

            '&[data-focus="true"]': {
                backgroundColor: `${themeColor.primaryColor} !important`,
                color: `white !important`
            },

            '&:hover': {
                backgroundColor: `${themeColor.avatarBackground}`,
                color: `${themeColor.secondaryColor}`
            },
        },
    },

    [`&.${autocompleteClasses.popper}`]: {
        marginTop: '-0.3em',
    },
}));

const muiCheckboxStyle = {
    color: themeColor.secondaryColor,
    '&.Mui-checked': {
        color: themeColor.checkboxColor,
    },
    '&.Mui-disabled': {
        color: 'grey',
    },
    '&.MuiFormControlLabel-label': {
        fontSize: 16,
    },
    '&.MuiSvgIcon-root':{
        fontSize : 18
    }
}

export const Parameters = (props) => {

    const { parameter, index, dateObject, complexTypeValue, handleOnInputChange, handleMultiSelectedFields, multiSelectedFields, optionValues, state, multiSelectedTextFields, handleMultiSelectTextFields } = props;


    const hasDynamicListOptions = useMemo(() => {
        return state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.length > 0;
    }, [complexTypeValue?.parameterName, state?.dynamicListOptions])

    const memoizedDynamicListValue = useMemo(() => {
        return hasDynamicListOptions
            ? (state.dynamicListOptions[complexTypeValue?.parameterName].filter(option => option.isSelected)[0]?.label || "")
            : "";
    }, [state?.dynamicListOptions, complexTypeValue?.DataCollectionUId]);


    const isUserDefinedVariable = useMemo(() => {
        return parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable;
    }, [parameter.parameterTypeCD])

    const isGoogleDriveFolder = useMemo(() => {
        return parameter.parameterTypeCD === AppEnum.ParameterTypeValue.GoogleDriveFolder;
    }, [parameter.parameterTypeCD])

    const isMultiValuedTextBox = useMemo(() => {
        return parameter.parameterTypeCD === AppEnum.ParameterTypeValue.MultiValuedTextBox;
    }, [parameter.parameterTypeCD])

    const isDataTypeDate = useMemo(() => {
        return parameter?.sysDataTypeId === AppEnum.DataTypeId.Date;
    }, [parameter?.sysDataTypeId])


    const isDataTypeDynamicList = useMemo(() => {
        return parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList;
    }, [parameter?.sysDataTypeId])

    const memoizedMultiSelectDropdownInputLabelProps = useMemo(() => ({
        shrink: isDataTypeDynamicList
            ? (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.filter(option => option.isSelected)?.length > 0 ? true : undefined)
            : (multiSelectedFields?.length > 0 ? true : undefined)
    }), [parameter?.sysDataTypeId, state?.dynamicListOptions, complexTypeValue?.DataCollectionUId, multiSelectedFields]);


    const defaultParameterValue = useMemo(() => {
        if (!parameter?.parameterValue && parameter?.sysDataTypeId === AppEnum.DataTypeId.List) {
            return complexTypeValue?.find((i) => i.Default)?.Value;
        }
        return parameter?.parameterValue;
    }, [parameter?.parameterValue, complexTypeValue]);


    const dynamicListOptions = useMemo(() => {
        const options = state?.dynamicListOptions?.[complexTypeValue?.parameterName] || [];

        return options.map(option => ({
            value: option?.value,
            label: option?.label,
        }));
    }, [state?.dynamicListOptions, complexTypeValue?.parameterName])


    const formattedDateValue = useMemo(() => {
        if (isDataTypeDate) {
            return moment(dateObject).format(AppEnum.DateFormats.Y_M_D);
        }

        // check for null or undefined for other than string 
        return (typeof (parameter?.parameterValue) === 'string' ? isNullOrEmpty(parameter?.parameterValue) : parameter?.parameterValue == null) ? parameter.defaultValue : parameter?.parameterValue;

    }, [isDataTypeDate, dateObject, parameter?.parameterValue, parameter?.defaultValue]);

    const isParameterTypeFixed = useMemo(() => {
        return parameter?.parameterTypeCD === AppEnum.ParameterTypeValue.Fixed;
    }, [parameter?.parameterTypeCD])


    const dynamicListLabel = useMemo(() => {
        const options = state?.dynamicListOptions?.[complexTypeValue?.parameterName] || [];
        const selectedOptions = options.filter(option => option.isSelected);

        return selectedOptions.length > 0
            ? `${parameter?.displayName} (${selectedOptions.length} Selected)`
            : parameter?.displayName;

    }, [state?.dynamicListOptions, complexTypeValue?.parameterName, parameter?.displayName]);


    const selectedValueList = useMemo(() => {

        if (isDataTypeDynamicList && parameter.isMultiSelectList) {
            if (hasDynamicListOptions) {
                return state.dynamicListOptions[complexTypeValue?.parameterName]?.filter(option => option.isSelected) || [];
            }
            return [];
        }
        return multiSelectedFields;

    }, [isDataTypeDynamicList, hasDynamicListOptions, state?.dynamicListOptions, complexTypeValue?.parameterName, multiSelectedFields]);

    const multiSelectOptions = useMemo(() => {

        if (isDataTypeDynamicList) {
            return hasDynamicListOptions
                ? state.dynamicListOptions[complexTypeValue?.parameterName] || []
                : [];
        }

        return optionValues;
    }, [isDataTypeDynamicList, hasDynamicListOptions, state?.dynamicListOptions, complexTypeValue?.parameterName, optionValues]);


    return (
        <div className= "parameter-div">
        <div className= {(parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput && parameter?.sysDataTypeId === AppEnum.DataTypeId.Boolean) ?  "parameter-checkbox-div" : "filter-box"}>
            {isUserDefinedVariable && parameter.isMultiSelectList ?
                <Autocomplete
                    multiple
                    id="tags-filled"
                    className="text-field1 label-text-field"
                    label={parameter?.displayName}
                    options={multiSelectOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedValueList}
                    onChange={((e, newValue) => handleMultiSelectedFields(e, newValue, index, false, false, true))}
                    renderTags={(value, getTagProps) =>
                        <div className='param-multiselect-chip'>
                            {value.map((option, index) => (
                                <Chip
                                    key={option.value}
                                    label={option.label}
                                    {...getTagProps({ index })}
                                    sx={{ margin: '8px 6px 0px 6px !important' }}
                                />
                            ))}
                        </div>
                    }
                    renderOption={(props, option) => (

                        <MenuItem
                            {...props}
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <ASTextField
                            {...params}
                            variant="outlined"
                            label={
                                isDataTypeDynamicList ? dynamicListLabel
                                    :
                                    (multiSelectedFields?.length > 0 || params?.InputProps?.value?.length > 0
                                        ? `${parameter?.displayName} (${multiSelectedFields.length} Selected)` : parameter?.displayName)
                            }
                            InputLabelProps={memoizedMultiSelectDropdownInputLabelProps}
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    padding: "0px !important",
                                    '& .MuiAutocomplete-clearIndicator': {
                                        display: 'none',
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                        display: 'none',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        padding: '7.5px 4px 7.5px 9px !important',
                                    }
                                }
                            }}
                            sx={{
                                '& [data-shrink="false"]': {
                                    top: '-9px !important',
                                }
                            }}
                        />
                    )}
                    sx={{ width: "100%" }}
                />
                :
                <>
                    {(parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput || isParameterTypeFixed) && parameter?.sysDataTypeId != AppEnum.DataTypeId.Boolean ?
                    parameter?.sysDataTypeId === AppEnum.DataTypeId.List ? 
                    < ASTextField
                                size="small"
                                className="text-field1 label-text-field "
                                type={"text"}
                                sx={{ fontSize: "12px" }}
                                fullWidth
                                defaultValue={defaultParameterValue}
                                label={parameter?.displayName}
                                variant="outlined"
                                select={true}
                                SelectProps={{
                                    MenuProps: {
                                        className: "menu-role-div",
                                    },
                                }}
                                onChange={(e) => handleOnInputChange(e, index, true, false)}
                            >
                                {complexTypeValue?.map((list, index) => {
                                    return (
                                        <MenuItem sx={{ fontSize: "12px" }} value={list?.Value} key={`dynamic ${index}`} >
                                            {list.Label}
                                        </MenuItem>
                                    );
                                })}
                            </ASTextField>
                            :
                        < ASTextField
                            key={index}
                            className="text-field1 label-text-field "
                            type={isDataTypeDate ? "date" : parameter?.sysDataTypeId === AppEnum.DataTypeId.Number ? "number" : "text"}
                            fullWidth
                            label={parameter?.displayName}
                            variant="outlined"
                            disabled={isParameterTypeFixed}
                            onChange={(e) => handleOnInputChange(e, index, false)}

                            defaultValue={formattedDateValue}
                            InputLabelProps={{
                                shrink: isDataTypeDate ? true : undefined,
                            }}
                            sx={{
                                '& [data-shrink="false"]': {
                                    top: '-9px !important',
                                }
                            }}
                        /> : null
                    }

                    {isGoogleDriveFolder && parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList &&
                        GoogleDriveComponent(dynamicListOptions,memoizedDynamicListValue,parameter,index,handleOnInputChange,complexTypeValue)
                    }

                    {(parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput && parameter?.sysDataTypeId === AppEnum.DataTypeId.Boolean) &&
                         <FormControlLabel control={
                            <Checkbox
                                checked={typeof (parameter?.parameterValue) === 'string' ? parameter.parameterValue.toLowerCase() == "true" ? true : false : parameter.parameterValue}
                                onChange={(event) => handleOnInputChange({ target: { value: event?.target?.checked } },index, false)}
                                sx={muiCheckboxStyle}
                            />
                        }
                            className='checkbox-column-item checkbox-column-item2'
                            label={parameter?.displayName}
                        />
                    }

                    {isMultiValuedTextBox ?
                        <Autocomplete
                            multiple
                            freeSolo
                            id="tags-filled"
                            className="text-field1 label-text-field"
                            label= {parameter?.displayName}
                            options={[]}
                            getOptionLabel={(option) => option.label || option}
                            value={multiSelectedTextFields}
                            onChange={(e, newValue) => {
                                const updatedValue = newValue.map((item) =>
                                    typeof item === 'string' ?  item  : item
                                );
                                handleMultiSelectTextFields(e, updatedValue);
                            }}
                            renderTags={(value, getTagProps) => (
                                <div className='param-multiselect-chip'>
                                    {value.map((option, index) => (
                                        <Chip
                                            key={option}
                                            label={option}
                                            {...getTagProps({ index })}
                                            sx={{ margin: '8px 6px 0px 6px !important' }}
                                        />
                                    ))}
                                </div>
                            )}
                            renderInput={(params) => (
                                <ASTextField
                                    {...params}
                                    variant="outlined"
                                    label={
                                        multiSelectedTextFields.length > 0 || params?.InputProps?.value?.length > 0
                                            ? `${parameter?.displayName} (${multiSelectedTextFields.length} Selected)`
                                            : parameter?.displayName
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            padding: "0px !important",
                                            '& .MuiAutocomplete-clearIndicator': {
                                                display: 'none',
                                            },
                                            '& .MuiAutocomplete-popupIndicator': {
                                                display: 'none',
                                            },
                                            '& .MuiAutocomplete-input': {
                                                padding: '7.5px 4px 7.5px 9px !important',
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& [data-shrink="false"]': {
                                            top: '-9px !important',
                                        },
                                    }}
                                />
                            )}
                            sx={{ width: "100%" }}
                        />
                        : null
                    }

                    {
                        isUserDefinedVariable ? parameter?.sysDataTypeId === AppEnum.DataTypeId.List ?
                            < ASTextField
                                size="small"
                                className="text-field1 label-text-field "
                                type={"text"}
                                sx={{ fontSize: "12px" }}
                                fullWidth
                                defaultValue={defaultParameterValue}
                                label={parameter?.displayName}
                                variant="outlined"
                                select={true}
                                SelectProps={{
                                    MenuProps: {
                                        className: "menu-role-div",
                                    },
                                }}
                                onChange={(e) => handleOnInputChange(e, index, true, false)}
                            >
                                {complexTypeValue?.map((list, index) => {
                                    return (
                                        <MenuItem sx={{ fontSize: "12px" }} value={list?.Value} key={`dynamic ${index}`} >
                                            {list.Label}
                                        </MenuItem>
                                    );
                                })}
                            </ASTextField>
                            : <>
                                <div className="default-value-dropdown">
                                    <Stack spacing={2}>
                                        <Autocomplete
                                            size="small"
                                            freeSolo
                                            className="text-field1 label-text-field"
                                            options={
                                                dynamicListOptions
                                            }
                                            renderOption={(props, option) => (
                                                <MenuItem
                                                    {...props}
                                                    key={`option-${option?.value}`}
                                                    className={
                                                        memoizedDynamicListValue === option?.label
                                                            ? "selected-dynamic-opt"
                                                            : "menu-role-div"
                                                    }
                                                >
                                                    {option?.label}
                                                </MenuItem>
                                            )}
                                            onChange={(event, value) => {
                                                const newValue = value ? value?.value : "";
                                                handleOnInputChange({ target: { value: newValue } }, index, true, true);
                                            }}
                                            value={memoizedDynamicListValue}
                                            PopperComponent={StyledParamAutocompletePopper}
                                            renderInput={(params) => (
                                                <ASTextField
                                                    sx={{ fontSize: "12px" }}
                                                    {...params}
                                                    label={parameter?.displayName}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: memoizedDynamicListValue && params.InputProps.endAdornment,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </div>
                            </> : null
                    }
                </>}
        </div >
        {(parameter?.description && 
            <ASTooltip className= "param-tooltip" text={parameter.description} />
        )}
        </div>
    )
}


const GoogleDriveComponent = (
    dynamicListOptions,
    memoizedDynamicListValue,
    parameter,
    index,
    handleOnInputChange
  ) => {
    return (
      <BrowseInputComponent dynamicListOptions={dynamicListOptions}
                            selectedValue={dynamicListOptions?.filter(i => i.value == parameter?.parameterValue)[0]?.label}
                            parameter={parameter}
                            index={index}
                            handleOnInputChange={handleOnInputChange}/>
    );
  };
  
    const BrowseInputComponent = ({ dynamicListOptions, selectedValue, parameter, index, handleOnInputChange}) => {
        const [open, setOpen] = useState(false);
        const [expanded, setExpanded] = useState({});
        
        const handleBrowseClick = () => {
            setOpen(true);
        };
        
        const handleSelectValue = (value) => {
            handleOnInputChange({ target: { value: value } }, index, true, true);
            setOpen(false);
        };
        
            const handleToggle = (nodeId) => {
            setExpanded((prevExpanded) => ({
                ...prevExpanded,
                [nodeId]: !prevExpanded[nodeId],
            }));
            };
    
            const renderList = (handleSelectValue,nodes,isDict,level = 0) => {
    
            let nodesList = nodes;

            if (isDict) {
                const keys = Object.keys(nodes);
                nodesList = keys.map((key) => nodes[key]);
            }
    
            return (
                <List component="div" disablePadding>
                    {nodesList?.map((node) => (
                        <div key={node?.value}>
                        <ListItemButton onClick={() => handleSelectValue(node?.value)} sx={{ pl: level * 2 }}>
                            <ListItemIcon>
                            <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={node?.label} />
                            {Array.isArray(node?.children) && Object.keys(node?.children).length > 0 ? (
                                expanded[node.value] ? (
                                <ExpandMoreIcon
                                    onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(node.value);
                                    }}
                                />
                                ) : (
                                <ChevronRightIcon
                                    onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(node.value);
                                    }}
                                />
                                )
                            ) : null}
                        </ListItemButton>
                        {Array.isArray(node?.children) && (
                            <Collapse in={expanded[node?.value]} timeout="auto" unmountOnExit>
                            {renderList(handleSelectValue,node?.children,true,level + 1)}
                            </Collapse>
                        )}
                        </div>
                    ))}
            </List>
            );
            };
        
            const convertToTree = (list) => {
            const map = {};
            const roots = [];
        
            list.forEach((item) => {
                const parts = item.label.split('/');
                let currentLevel = map;
        
                parts.forEach((part, index) => {
                if (!currentLevel[part]) {
                    currentLevel[part] = {
                    value: item.value,
                    label: part,
                    children: [],
                    };
                }
        
                if (index === parts.length - 1) {
                    currentLevel[part].value = item.value;
                }
        
                currentLevel = currentLevel[part].children;
                });
            });
        
            Object.keys(map).forEach((key) => {
                roots.push(map[key]);
            });
        
            return roots;
            };
        
            const treeData = convertToTree(dynamicListOptions);
        
        return (
            <div className="default-value-dropdown">
            <ASTextField
                size="small"
                className="text-field1 label-text-field "
                type={"text"}
                sx={{ fontSize: "12px" }}
                fullWidth
                value={selectedValue}
                label={parameter?.displayName}
                defaultValue={selectedValue}
                InputLabelProps={{
                shrink: selectedValue ? true : false,
                }}
                InputProps={{
                endAdornment: (
                <ASButton sx={{
                        color: themeColor.linkColor,
                        padding: '6px 15px'
                    }}
                    variant="text"
                    size="small"
                    className="browse-button"
                    onClick={handleBrowseClick}
                    >
                    Browse
                </ASButton>
                ),
                }}
            />
            <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <div className="drive-folder-title-div">
                    <div className="icon-image-div">
                        <CardMedia
                                    component="img"
                                    sx={{ width: "auto !important", height: '65px' }}
                                    image={`/assets/google-drive.svg`}
                                    alt={`google-drive.svg`}
                                />
                    </div>
                    <div className="drive-folder-title">Select a folder from Google Drive</div>
                </div>
            </DialogTitle>
            <DialogContent>
            {renderList(handleSelectValue,treeData)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
            </Dialog>
            </div>
        );
};